@charset "UTF-8";
/*Google Fonts*/
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;0,700;1,600&display=swap");
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
:root {
  --thm-font: "Rubik", sans-serif;
  /* Headings */
  --thm-b-font: "Rubik", sans-serif;
  /* Body font */
  --thm-base: #de1e07;
  /* Primary color */
  --thm-base-hover: #a21c0c;
  /* Primary hover */
  --thm-base-rgb: 222, 30, 7;
  /* Primary rgb */
  --thm-base-hue: #ffecea;
  /* Primary low hue */
  --thm-secondary: #232230;
  /* Secondary */
  --thm-secondary-rgb: 35, 34, 48;
  /* Secondary rgb */
  --thm-b-text: #4e5164;
  /* Body text */
  --thm-border: #e1e1e1;
  /* Borders */
}

.social-links {
  cursor: pointer !important;
}

.app-links {
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.app-links img {
  cursor: pointer;
  width: 200px;
  margin-top: 10px;
}

/* animation */
@keyframes pulseInOut {
  0% {
    opacity: 1;
    transform: scale(0.3);
  }
  100% {
    opacity: 0;
    transform: scale(1.7);
  }
}
@keyframes preloader-dual-inner-o {
  0% {
    opacity: 1;
    transform: translate(0 0);
  }
  49.99% {
    opacity: 1;
    transform: translate(80px, 0);
  }
  50% {
    opacity: 0;
    transform: translate(80px, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 0);
  }
}
@keyframes preloader-dual-inner {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(80px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
@-webkit-keyframes cssload-loading-ani1 {
  100% {
    -webkit-transform: translate(39px);
    transform: translate(39px);
    opacity: 0;
  }
}
@keyframes cssload-loading-ani1 {
  100% {
    -webkit-transform: translate(39px);
    transform: translate(39px);
    opacity: 0;
  }
}
@-webkit-keyframes cssload-loading-ani2 {
  100% {
    -webkit-transform: translate(19px);
    transform: translate(19px);
    opacity: 1;
  }
}
@keyframes cssload-loading-ani2 {
  100% {
    -webkit-transform: translate(19px);
    transform: translate(19px);
    opacity: 1;
  }
}
@-webkit-keyframes cssload-loading-ani3 {
  100% {
    -webkit-transform: translate(19px);
    transform: translate(19px);
  }
}
@keyframes cssload-loading-ani3 {
  100% {
    -webkit-transform: translate(19px);
    transform: translate(19px);
  }
}
@keyframes preloader-squares-inner {
  0% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes preloader-pulse-inner-1 {
  0% {
    top: 36px;
    height: 128px;
  }
  50% {
    top: 60px;
    height: 80px;
  }
  100% {
    top: 60px;
    height: 80px;
  }
}
@keyframes preloader-pulse-inner-2 {
  0% {
    top: 42px;
    height: 116px;
  }
  50% {
    top: 60px;
    height: 80px;
  }
  100% {
    top: 60px;
    height: 80px;
  }
}
@keyframes preloader-pulse-inner-3 {
  0% {
    top: 48px;
    height: 104px;
  }
  50% {
    top: 60px;
    height: 80px;
  }
  100% {
    top: 60px;
    height: 80px;
  }
}
@keyframes preloader-gear-inner {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(22.5deg);
  }
  100% {
    transform: rotate(45deg);
  }
}
@keyframes preloader-ripple-inner {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0;
  }
}
@keyframes preloader-eclipse {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes preloader-spinner-inner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* General */
::selection {
  background-color: var(--thm-base);
  color: #fff;
}

body {
  font-family: var(--thm-b-font);
  color: var(--thm-b-text);
  font-size: 16px;
  line-height: 1.8;
}

.texture-1 {
  position: absolute;
  top: 40px;
  right: -250px;
}

.fw-400 {
  font-weight: 400;
}

.sigma_box {
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
}

.gallery-thumb {
  position: relative;
}

.gallery-thumb::before {
  content: "";
  position: absolute;
  right: 20px;
  top: 30px;
  font-family: "Font Awesome 5 Pro";
  color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.gallery-thumb:hover::before {
  top: 20px;
  opacity: 1;
  visibility: visible;
}

.object-cover {
  object-fit: cover;
}

.section {
  position: relative;
  padding: 100px 0;
}

.section.section-padding {
  padding: 100px 0 70px;
}

.section.shape-after::after {
  content: "";
  position: absolute;
  background-color: var(--thm-base);
  opacity: 0.3;
  left: 20px;
  right: 20px;
  bottom: -20px;
  top: 20px;
  z-index: -2;
}

.section-title {
  position: relative;
  max-width: 680px;
  margin: 0 0 50px;
}

.section-title.centered {
  text-align: center;
  margin: 0 auto 50px;
}

.section-title.flex-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 50px;
  max-width: 100%;
}

.section-title .title {
  font-weight: 700;
  font-size: 52px;
  line-height: 1;
  position: relative;
}

.section-title .subtitle {
  display: inline-flex;
  color: var(--thm-base);
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 500;
}

.section-title .btn-link {
  margin-top: 20px;
}

.required {
  font-size: 12px;
  line-height: 1;
  color: #f78686;
  font-style: normal;
}

img {
  max-width: 100%;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

label {
  margin-bottom: 10px;
  color: var(--thm-b-text);
  font-weight: 500;
}

b,
strong {
  font-weight: 500;
}

.sigma_stroke-text {
  font-size: 240px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  color: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #fff;
  z-index: 1;
  opacity: 0.2;
  margin: 0;
}

.center-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.topleft-absolute {
  position: absolute;
  left: -30px;
  top: -30px;
}

.secondary-overlay,
.dark-overlay,
.primary-overlay {
  position: relative;
  z-index: 1;
}

.secondary-overlay::before,
.primary-overlay::before,
.dark-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: -1;
}

.primary-overlay::before {
  background-color: rgba(0, 0, 0, 0.6);
}

.secondary-overlay::before {
  background-color: rgba(var(--thm-secondary-rgb), 0.8);
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.mb-30 {
  margin-bottom: 30px;
}

/* Buttons */
input[type=reset],
input[type=button],
input[type=submit],
.sigma_btn {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  position: relative;
  background-color: var(--thm-base);
  border: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 17px 30px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  text-transform: uppercase;
  border-radius: 0;
  overflow: hidden;
  z-index: 1;
  box-shadow: 0 3px 24px rgba(0, 0, 0, 0.1);
}

button i,
input[type=reset] i,
input[type=button] i,
input[type=submit] i,
.sigma_btn i {
  margin: 0 0 0 10px;
  color: #fff;
  z-index: 1;
}

.btn-rounded button,
.btn-rounded input[type=reset],
.btn-rounded input[type=button],
.btn-rounded input[type=submit],
.btn-rounded .sigma_btn,
.rounded-border input[type=number],
.rounded-border input[type=text],
.rounded-border input[type=date],
.rounded-border input[type=email],
.rounded-border input[type=password],
.rounded-border input[type=tel],
.rounded-border input[type=url],
.rounded-border input[type=search],
.rounded-border select,
.rounded-border textarea,
.rounded-border .form-control {
  border-radius: 6px;
}

.btn-pill button,
.btn-pill input[type=reset],
.btn-pill input[type=button],
.btn-pill input[type=submit],
.btn-pill .sigma_btn,
.pill-border input[type=number],
.pill-border input[type=text],
.pill-border input[type=date],
.pill-border input[type=email],
.pill-border input[type=password],
.pill-border input[type=tel],
.pill-border input[type=url],
.pill-border input[type=search],
.pill-border select,
.pill-border textarea,
.pill-border .form-control {
  border-radius: 25px;
}

.bottom-border input[type=number],
.bottom-border input[type=text],
.bottom-border input[type=email],
.bottom-border input[type=password],
.bottom-border input[type=tel],
.bottom-border input[type=url],
.bottom-border input[type=search],
.bottom-border select,
.bottom-border textarea,
.bottom-border .form-control {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--thm-border);
  padding: 8px 0;
}

/* button:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
input[type="submit"]:hover,
.sigma_btn:hover,
button:focus,
input[type="reset"]:focus,
input[type="button"]:focus,
input[type="submit"]:focus,
.sigma_btn:focus {
  color: #fff;
  box-shadow: 0 3px 24px rgba(0, 0, 0, 0.1);
  outline: none;
} */
button.secondary,
input[type=reset].secondary,
input[type=button].secondary,
input[type=submit].secondary,
.sigma_btn.secondary {
  background-color: var(--thm-secondary);
}

button.secondary:hover,
input[type=reset].secondary:hover,
input[type=button].secondary:hover,
input[type=submit].secondary:hover,
.sigma_btn.secondary:hover,
button.secondary:focus,
input[type=reset].secondary:focus,
input[type=button].secondary:focus,
input[type=submit].secondary:focus,
.sigma_btn.secondary:focus {
  color: #fff;
}

button.tertiary,
input[type=reset].tertiary,
input[type=button].tertiary,
input[type=submit].tertiary,
.sigma_btn.tertiary {
  background-color: #7553f2;
}

button.tertiary:hover,
input[type=reset].tertiary:hover,
input[type=button].tertiary:hover,
input[type=submit].tertiary:hover,
.sigma_btn.tertiary:hover,
button.tertiary:focus,
input[type=reset].tertiary:focus,
input[type=button].tertiary:focus,
input[type=submit].tertiary:focus,
.sigma_btn.tertiary:focus {
  color: #fff;
}

button.light,
input[type=reset].light,
input[type=button].light,
input[type=submit].light,
.sigma_btn.light {
  background-color: #fff;
  color: var(--thm-secondary);
}

button.light i,
input[type=reset].light i,
input[type=button].light i,
input[type=submit].light i,
.sigma_btn.light i {
  color: var(--thm-secondary);
}

button.light:hover,
input[type=reset].light:hover,
input[type=button].light:hover,
input[type=submit].light:hover,
.sigma_btn.light:hover,
button.light:focus,
input[type=reset].light:focus,
input[type=button].light:focus,
input[type=submit].light:focus,
.sigma_btn.light:focus {
  color: var(--thm-secondary);
}

/* btn-hover-styles */
/* Style 1 */
.btn-style-1 button:before,
.btn-style-1 input[type=reset]:before,
.btn-style-1 input[type=button]:before,
.btn-style-1 input[type=submit]:before,
.btn-style-1 .sigma_btn:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: calc(100% - 10px);
  border-radius: 0;
  background-color: var(--thm-base-hover);
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.3s;
}

/* Style 2 */
.btn-style-2 button:before,
.btn-style-2 input[type=reset]:before,
.btn-style-2 input[type=button]:before,
.btn-style-2 input[type=submit]:before,
.btn-style-2 .sigma_btn:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  border-radius: 0;
  background-color: var(--thm-base-hover);
  z-index: -1;
  transition: 0.3s;
}

/* Style 3 */
.btn-style-3 button:before,
.btn-style-3 input[type=reset]:before,
.btn-style-3 input[type=button]:before,
.btn-style-3 input[type=submit]:before,
.btn-style-3 .sigma_btn:before {
  content: "";
  position: absolute;
  height: 100%;
  left: -35%;
  top: 0;
  transform: skew(50deg);
  width: 0;
  border-radius: 0;
  background-color: var(--thm-base-hover);
  z-index: -1;
  transition: 0.3s;
}

/* Style 4 */
.btn-style-4 button:before,
.btn-style-4 input[type=reset]:before,
.btn-style-4 input[type=button]:before,
.btn-style-4 input[type=submit]:before,
.btn-style-4 .sigma_btn:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0;
  border-radius: 0;
  background-color: var(--thm-base-hover);
  z-index: -1;
  transition: 0.3s;
}

/* Style 5 */
.btn-style-5 button:before,
.btn-style-5 input[type=reset]:before,
.btn-style-5 input[type=button]:before,
.btn-style-5 input[type=submit]:before,
.btn-style-5 .sigma_btn:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  height: 0;
  width: 0;
  border-radius: 0;
  background-color: var(--thm-base-hover);
  z-index: -1;
  transition: 0.3s;
}

/* Style 6 */
.btn-style-6 button:before,
.btn-style-6 input[type=reset]:before,
.btn-style-6 input[type=button]:before,
.btn-style-6 input[type=submit]:before,
.btn-style-6 .sigma_btn:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  right: -120px;
  bottom: -50px;
  border-radius: 0;
  background-color: var(--thm-base-hover);
  z-index: -1;
  transition: 0.3s;
}

/* Style 7 */
.btn-style-7 button:before,
.btn-style-7 input[type=reset]:before,
.btn-style-7 input[type=button]:before,
.btn-style-7 input[type=submit]:before,
.btn-style-7 .sigma_btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0;
  background-color: var(--thm-base-hover);
  transform: scaleY(0);
  transform-origin: 50%;
  transition: 0.3s;
}

/* Style 8 */
.btn-style-8 button:before,
.btn-style-8 input[type=reset]:before,
.btn-style-8 input[type=button]:before,
.btn-style-8 input[type=submit]:before,
.btn-style-8 .sigma_btn:before {
  content: "";
  position: absolute;
  left: 110%;
  top: 0px;
  width: 100%;
  height: 100%;
  transform: skewX(-25deg);
  transition: 0.3s;
  z-index: -1;
  border-radius: 0;
  background-color: var(--thm-base-hover);
}

/* Style 9 */
.btn-style-9 button:before,
.btn-style-9 input[type=reset]:before,
.btn-style-9 input[type=button]:before,
.btn-style-9 input[type=submit]:before,
.btn-style-9 .sigma_btn:before {
  content: "";
  position: absolute;
  left: 90%;
  top: 0px;
  width: 100%;
  height: 100%;
  transform: skewX(-25deg);
  transition: 0.3s;
  z-index: -1;
  border-radius: 0;
  background-color: var(--thm-base-hover);
}

/* for secondary btn */
button.secondary:before,
input[type=reset].secondary:before,
input[type=button].secondary:before,
input[type=submit].secondary:before,
.sigma_btn.secondary:before {
  background-color: #070606;
}

/* for tertiary btn */
button.tertiary:before,
input[type=reset].tertiary:before,
input[type=button].tertiary:before,
input[type=submit].tertiary:before,
.sigma_btn.tertiary:before,
button.tertiary:before {
  background-color: #4224b1;
}

/* for light btn */
button.light:before,
input[type=reset].light:before,
input[type=button].light:before,
input[type=submit].light:before,
.sigma_btn.light:before {
  background-color: rgba(0, 0, 0, 0.1);
}

/* for buttons have rounded borders */
.btn-rounded button:before,
.btn-rounded input[type=reset]:before,
.btn-rounded input[type=button]:before,
.btn-rounded input[type=submit]:before,
.btn-rounded .sigma_btn:before {
  border-radius: 6px;
}

/* for pill button */
.btn-pill button:before,
.btn-pill input[type=reset]:before,
.btn-pill input[type=button]:before,
.btn-pill input[type=submit]:before,
.btn-pill .sigma_btn:before {
  border-radius: 25px;
}

.btn-style-1 button:hover:before,
.btn-style-1 input[type=reset]:hover:before,
.btn-style-1 input[type=button]:hover:before,
.btn-style-1 input[type=submit]:hover:before,
.btn-style-1 .sigma_btn:hover:before {
  opacity: 1;
  visibility: visible;
  width: calc(100% - 10px);
}

.btn-style-2 button:hover:before,
.btn-style-2 input[type=reset]:hover:before,
.btn-style-2 input[type=button]:hover:before,
.btn-style-2 input[type=submit]:hover:before,
.btn-style-2 .sigma_btn:hover:before {
  height: 100%;
}

.btn-style-3 button:hover:before,
.btn-style-3 input[type=reset]:hover:before,
.btn-style-3 input[type=button]:hover:before,
.btn-style-3 input[type=submit]:hover:before,
.btn-style-3 .sigma_btn:hover:before {
  width: 160%;
  height: 100%;
}

.btn-style-4 button:hover:before,
.btn-style-4 input[type=reset]:hover:before,
.btn-style-4 input[type=button]:hover:before,
.btn-style-4 input[type=submit]:hover:before,
.btn-style-4 .sigma_btn:hover:before {
  width: 100%;
}

.btn-style-5 button:hover:before,
.btn-style-5 input[type=reset]:hover:before,
.btn-style-5 input[type=button]:hover:before,
.btn-style-5 input[type=submit]:hover:before,
.btn-style-5 .sigma_btn:hover:before {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.btn-style-6 button:hover:before,
.btn-style-6 input[type=reset]:hover:before,
.btn-style-6 input[type=button]:hover:before,
.btn-style-6 input[type=submit]:hover:before,
.btn-style-6 .sigma_btn:hover:before {
  right: 0;
  bottom: 0;
}

.btn-style-7 button:hover:before,
.btn-style-7 input[type=reset]:hover:before,
.btn-style-7 input[type=button]:hover:before,
.btn-style-7 input[type=submit]:hover:before,
.btn-style-7 .sigma_btn:hover:before {
  transform: scaleY(1);
}

.btn-style-8 button:hover:before,
.btn-style-8 input[type=reset]:hover:before,
.btn-style-8 input[type=button]:hover:before,
.btn-style-8 input[type=submit]:hover:before,
.btn-style-8 .sigma_btn:hover:before {
  left: 0%;
  transform: scale(1, 1);
}

.btn-style-9 button:hover:before,
.btn-style-9 input[type=reset]:hover:before,
.btn-style-9 input[type=button]:hover:before,
.btn-style-9 input[type=submit]:hover:before,
.btn-style-9 .sigma_btn:hover:before {
  left: 0%;
  transform: scale(1, 1);
}

button.btn-sm, .btn-group-sm > button.btn,
input[type=reset].btn-sm,
.btn-group-sm > input[type=reset].btn,
input[type=button].btn-sm,
.btn-group-sm > input[type=button].btn,
input[type=submit].btn-sm,
.btn-group-sm > input[type=submit].btn,
.sigma_btn.btn-sm,
.btn-group-sm > .sigma_btn.btn {
  font-size: 14px;
  padding: 12px 20px;
  text-transform: capitalize;
}

/* button.btn-md,
input[type="reset"].btn-md,
input[type="button"].btn-md,
input[type="submit"].btn-md,
.sigma_btn.btn-md {
}

button.btn-lg,
input[type="reset"].btn-lg,
input[type="button"].btn-lg,
input[type="submit"].btn-lg,
.sigma_btn.btn-lg {
} */
.btn-link {
  transition: 0.3s;
  display: inline-flex;
  align-items: center;
  line-height: 1;
}

.btn-link:hover {
  text-decoration: none;
  color: var(--thm-base-hover);
}

.btn-link i {
  transition: 0.3s;
  margin-left: 10px;
  font-size: 13px;
}

.btn-link:hover i {
  margin-left: 13px;
}

/* Inputs */
input[type=number],
input[type=text],
input[type=date],
input[type=month],
input[type=email],
input[type=password],
input[type=tel],
input[type=url],
input[type=search],
select,
textarea,
.form-control {
  position: relative;
  display: block;
  height: 60px;
  width: 100%;
  font-size: 16px;
  color: #686a6f;
  font-weight: 400;
  padding: 14px 30px;
  letter-spacing: 0.1em;
  border: 1px solid #e1e1e1;
  border-radius: 0;
  transition: all 300ms ease;
  font-family: var(--thm-b-font);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 17px;
}

textarea {
  padding: 15px;
}

.form-group {
  position: relative;
  margin-bottom: 20px;
}

.form-group > i {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  z-index: 1;
}

i.show-password {
  cursor: pointer;
}

textarea {
  height: auto;
  resize: none;
  line-height: 1;
}

input[type=number]:focus,
input[type=text]:focus,
input[type=email]:focus,
input[type=password]:focus,
input[type=tel]:focus,
input[type=url]:focus,
input[type=search]:focus,
select:focus,
textarea:focus,
.form-control:focus {
  box-shadow: none;
  outline: none;
  border-color: var(--thm-base);
}

input[type=number]::placeholder,
input[type=text]::placeholder,
input[type=email]::placeholder,
input[type=password]::placeholder,
input[type=tel]::placeholder,
input[type=url]::placeholder,
input[type=search]::placeholder,
select::placeholder,
textarea::placeholder {
  color: #a5a5a5;
}

/*Checkboxes and radios*/
input[type=checkbox],
input[type=radio] {
  opacity: 0;
  position: absolute;
}

input[type=checkbox] + label,
input[type=radio] + label {
  display: block;
  cursor: pointer;
  position: relative;
  padding-left: 25px;
  font-weight: 400;
}

input[type=checkbox] + label:before,
input[type=radio] + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  border: 1px solid var(--thm-border);
  border-radius: 3px;
  display: inline-block;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 16px;
  outline: 0;
  -webkit-transition: background-color 0.2s ease-out;
  -o-transition: background-color 0.2s ease-out;
  transition: background-color 0.2s ease-out;
  width: 16px;
}

input[type=radio] + label:before {
  border-radius: 50%;
}

input[type=checkbox] + label:after,
input[type=radio] + label:after {
  position: absolute;
  top: 50%;
  left: 3px;
  width: 10px;
  height: 5px;
  content: " ";
  transform: translateY(-50%) rotate(-45deg);
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  opacity: 0;
  transition: 0.3s;
}

input[type=radio] + label:after {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 0;
  background-color: var(--thm-base);
}

input[type=checkbox]:checked + label:before,
input[type=checkbox]:checked + label.switch-toggle:before {
  background-color: var(--thm-base);
  border-color: var(--thm-base);
}

input[type=checkbox]:checked + label:after,
input[type=radio]:checked + label:after {
  opacity: 1;
}

.custom-control {
  padding-left: 0;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--thm-base);
}

.custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*Checkbox With Toggle Swich*/
input[type=checkbox] + label.switch-toggle {
  padding-left: 70px;
}

input[type=checkbox] + label.switch-toggle:before {
  border-radius: 16px;
  height: 30px;
  width: 60px;
  background-color: var(--thm-border);
}

input[type=checkbox] + label.switch-toggle:after {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 0;
  background-color: #fff;
  opacity: 1;
}

input[type=checkbox]:checked + label.switch-toggle:after {
  left: 36px;
}

/* Input Groups */
.input-group input[type=number],
.input-group input[type=text],
.input-group input[type=email],
.input-group input[type=password],
.input-group input[type=tel],
.input-group input[type=url],
.input-group input[type=search],
.input-group select,
.input-group textarea,
.input-group .form-control {
  flex: 1 auto;
  width: 1%;
}

.input-group-prepend,
.input-group-append {
  margin: 0;
}

.input-group-append button i {
  margin: 0;
}

.input-group-prepend button,
.input-group-prepend input[type=reset],
.input-group-prepend input[type=button],
.input-group-prepend input[type=submit],
.input-group-prepend .sigma_btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: none;
}

.input-group input[type=number]:last-child,
.input-group input[type=text]:last-child,
.input-group input[type=email]:last-child,
.input-group input[type=password]:last-child,
.input-group input[type=tel]:last-child,
.input-group input[type=url]:last-child,
.input-group input[type=search]:last-child,
.input-group select:last-child,
.input-group textarea:last-child,
.input-group .form-control:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group input[type=number]:not(:last-child),
.input-group input[type=text]:not(:last-child),
.input-group input[type=email]:not(:last-child),
.input-group input[type=password]:not(:last-child),
.input-group input[type=tel]:not(:last-child),
.input-group input[type=url]:not(:last-child),
.input-group input[type=search]:not(:last-child),
.input-group select:not(:last-child),
.input-group textarea:not(:last-child),
.input-group .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-append button,
.input-group-append input[type=reset],
.input-group-append input[type=button],
.input-group-append input[type=submit],
.input-group-append .sigma_btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: none;
}

/* Colors &  background-colors */
.primary-color {
  color: var(--thm-base);
}

.secondary-color {
  color: var(--thm-secondary);
}

.tertiary-color {
  color: #7553f2;
}

.light-bg {
  background-color: #f7f7f7;
}

.bg-white {
  background-color: #fff;
}

.bg-primary-1 {
  background-color: var(--thm-base);
}

.bg-secondary-1 {
  background-color: var(--thm-secondary);
}

.bg-tertiary {
  background-color: #7553f2;
}

.bg-primary-light {
  background-color: var(--thm-base-hue);
}

.bg-secondary-light {
  background-color: #c8c8c8;
}

.bg-tertiary-light {
  background-color: #d4c8ff;
}

.bg-gray {
  background-color: #f2f6fa;
}

.twitter {
  background-color: #2caae1 !important;
  color: #fff;
}

.twitter:before {
  background-color: #2488b3 !important;
}

.facebook {
  background-color: #3b579d !important;
  color: #fff;
}

.facebook:before {
  background-color: #273a6b !important;
}

.google {
  background-color: #dc4a38 !important;
  color: #fff;
}

.google:before {
  background-color: #af1705 !important;
}

.youtube {
  background-color: #e52d27 !important;
  color: #fff;
}

.youtube:before {
  background-color: #b7140f !important;
}

/* Slick */
.slick-slide {
  position: relative;
  outline: none;
}

.slick-prev,
.slick-next {
  position: relative;
  font-size: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  color: var(--thm-secondary);
  border: 0;
  padding: 0;
  outline: none;
  transition: 0.3s;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background-color: var(--thm-base);
  color: #fff;
}

.slick-prev:before,
.slick-next:before {
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
}

[class*=btn-style-] .slick-prev::after,
[class*=btn-style-] .slick-next::after {
  display: none;
}

[class*=btn-style-] .slick-prev::before,
[class*=btn-style-] .slick-next::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  opacity: 1;
  visibility: visible;
  background-color: transparent;
}

[class*=btn-style-] .slick-next::before,
.slick-next:before {
  content: "";
}

.slick-dots li:focus,
.slick-dots li:hover,
.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: none;
}

.slick-dots li + li {
  margin-left: 10px;
}

.slick-dots li button {
  font-size: 0;
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s;
  border: 0;
  padding: 0;
}

[class*=btn-style-] .slick-dots li button::after,
[class*=btn-style-] .slick-dots li button::before {
  display: none;
}

.slick-dots .slick-active button {
  background-color: var(--thm-base);
}

/*--- custom arrows ---- */
.sigma_custom-arrows {
  display: flex;
  align-items: center;
}

.sigma_custom-arrows .slick-prev,
.sigma_custom-arrows .slick-next {
  background-color: var(--thm-base);
  opacity: 1;
  visibility: visible;
  cursor: pointer;
}

.sigma_custom-arrows .slick-prev:hover,
.sigma_custom-arrows .slick-next:hover {
  background-color: var(--thm-base-hover);
}

.sigma_custom-arrows .slick-next {
  margin-left: 12px;
}

/* Typography */
h1 a, .h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a,
h5 a,
.h5 a,
h6 a,
.h6 a,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: var(--thm-font);
  font-weight: 600;
  color: var(--thm-secondary);
}

h1, .h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-bottom: 20px;
}

h1, .h1 {
  font-size: 52px;
}

h2, .h2 {
  font-size: 44px;
}

h3, .h3 {
  font-size: 36px;
  line-height: 1.2;
}

h4, .h4 {
  font-size: 28px;
  line-height: 1.4;
}

h5, .h5 {
  font-size: 20px;
  line-height: 1.3;
}

h6, .h6 {
  font-size: 18px;
  line-height: 1.7;
}

p {
  margin-bottom: 20px;
}

a {
  color: var(--thm-base);
  transition: 0.3s;
  display: inline-block;
}

a:hover,
a:focus {
  outline: none;
  box-shadow: none;
  text-decoration: none;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
a:hover {
  color: var(--thm-base-hover);
  text-decoration: none;
}

/*======================
Tables
========================*/
/* table {
  margin-bottom: 30px;
}
table th,
table td {
  padding: 15px;
  vertical-align: middle;
  background-color: #fff;
}

table th {
  font-weight: 500;
  color: var(--thm-secondary);
  font-size: 16px;
  border: 1px solid var(--thm-border);
}

table {
  width: 100%;
  margin-bottom: 30px;
}

table td {
  border: 1px solid var(--thm-border);
}

table img {
  width: 40px;
  border-radius: 0;
} */
.sigma_close {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
}

.sigma_close:hover span {
  opacity: 1;
}

.sigma_close span {
  position: absolute;
  width: 24px;
  height: 2px;
  opacity: 0.5;
  transition: 0.3s;
  background-color: var(--thm-secondary);
}

.sigma_close.light span {
  background-color: #fff;
}

.sigma_close span:first-child {
  transform: rotate(45deg);
}

.sigma_close:hover span:first-child {
  transform: rotate(0deg);
}

.sigma_close:hover span:last-child {
  transform: rotate(180deg);
}

.sigma_close span:last-child {
  transform: rotate(135deg);
}

/* Header */
.sigma_header {
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
}

.navbar {
  padding: 0;
}

.sigma_header .sigma_logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.sigma_header .sigma_logo-wrapper .sigma_logo {
  max-width: 400px;
}

.sigma_header .navbar-nav {
  flex-direction: row;
}

.sigma_header .navbar-nav li {
  position: relative;
  transition: 0.3s;
}

.sigma_header .navbar-nav li a {
  display: block;
  padding: 30px 20px;
  color: var(--thm-secondary);
  font-weight: 500;
  font-family: var(--thm-font);
  position: relative;
  font-size: 14px;
  text-transform: uppercase;
}

.sigma_header .navbar-nav li:first-child > a {
  padding-left: 0;
}

.sigma_header .navbar-nav li:last-child > a {
  padding-right: 0;
}

.sigma_header .navbar-nav li a:hover,
.sigma_header .navbar-nav li.active > a {
  color: var(--thm-base);
}

.sigma_header .navbar-nav li .sub-menu {
  position: absolute;
  top: 110%;
  min-width: 250px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
  transition: 0.3s;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
}

.sigma_header .navbar-nav li .sub-menu li a {
  border-bottom: 1px solid var(--thm-border);
  padding: 15px;
  display: flex;
  font-weight: 400;
  align-items: center;
  position: relative;
}

.sigma_header .navbar-nav li .sub-menu li:last-child a {
  border-bottom: none;
}

.sigma_header .navbar-nav li.menu-item-has-children:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.sigma_header .navbar-nav li.menu-item-has-children > .sub-menu a,
.sigma_header .navbar-nav li.menu-item-has-children:hover > .sub-menu a {
  text-transform: none;
}

.sigma_header .navbar-nav li.menu-item-has-children .sub-menu li .sub-menu {
  left: 100%;
  top: 0;
}

.sigma_header-absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 55;
}

/* Header top */
.sigma_header-top {
  background-color: #fff;
  border-bottom: 1px solid var(--thm-border);
  padding: 0;
}

.sigma_header-top-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.sigma_header-top-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin: 0;
}

.sigma_header-top .sigma_header-top-nav li {
  margin: 0;
}

.sigma_header-top .sigma_header-top-nav li a {
  color: #fff;
  font-weight: 600;
  font-size: 12px;
}

.sigma_header-top .sigma_header-top-nav li + li {
  margin-left: 10px;
}

.sigma_header-top-contacts .sigma_header-top-nav li a {
  display: block;
  font-size: 14px;
  width: 41px;
  height: 41px;
  background-color: rgba(var(--thm-base-rgb), 0.25);
  display: inline-flex;
  color: var(--thm-base);
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 12px;
  margin-right: 0;
}

.sigma_header-top-contacts .sigma_header-top-nav li a:hover {
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_header-top .sigma_header-top-cta li {
  height: 100%;
}

.sigma_header-top .sigma_header-top-cta li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px;
  background-color: var(--thm-base);
  color: #fff;
  border-radius: 4px;
  font-weight: 500;
}

.sigma_header-top .sigma_header-top-cta li a:hover {
  background-color: var(--thm-base-hover);
}

/* Header bottom */
.sigma_header-bottom {
  background-color: var(--thm-secondary);
}

.sigma_header-bottom-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.sigma_header .sigma_header-bottom .navbar-nav > li > a {
  color: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
}

.sigma_header .sigma_header-bottom .navbar-nav li a:hover {
  color: var(--thm-base);
}

.sigma_header .sigma_header-bottom-inner .aside-toggle.desktop-toggler {
  display: flex;
  height: 62px;
  width: 62px;
  align-items: center;
  justify-content: center;
  background-color: var(--thm-base);
  transition: 0.3s;
  border-radius: 0;
  border: 0;
}

.sigma_header .sigma_header-bottom-inner .aside-toggle.desktop-toggler span {
  background-color: #fff;
}

/* Header Controls starts */
.sigma_header .sigma_header-controls ul {
  display: flex;
  align-items: center;
}

.sigma_header .sigma_header-controls ul li + li {
  margin-left: 15px;
}

.sigma_header .sigma_header-controls ul li.header-controls-item a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  border: 1px solid var(--thm-border);
  color: var(--thm-secondary);
  font-size: 18px;
  border-radius: 10px;
}

.sigma_header .sigma_header-controls ul li.header-controls-item a.sigma_header-control-cart {
  width: auto;
  height: auto;
  padding: 13px 15px;
  line-height: 1;
  display: flex;
  align-items: center;
}

.sigma_header .sigma_header-controls ul li.header-controls-item a.sigma_header-control-cart .sigma_header-control-cart-inner {
  margin-left: 10px;
}

.sigma_header .sigma_header-controls ul li.header-controls-item a.sigma_header-control-cart p,
.sigma_header .sigma_header-controls ul li.header-controls-item a.sigma_header-control-cart span {
  font-size: 13px;
}

.sigma_header .sigma_header-controls ul li.header-controls-item a.sigma_header-control-cart span {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.sigma_header .sigma_header-controls ul li.header-controls-item a.sigma_header-control-cart p {
  margin: 0;
  font-weight: 600;
  color: var(--thm-base);
}

.sigma_header .sigma_header-controls ul li.header-controls-item a:hover {
  background-color: #f2f6fa;
}

.sigma_header .aside-toggle,
.sigma_header .aside-toggle.desktop-toggler {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  width: 58px;
  height: 58px;
  border: 1px solid var(--thm-border);
  border-radius: 10px;
  transition: 0.3s;
}

.sigma_header .aside-toggle:hover {
  background-color: #f2f6fa;
}

.sigma_header .aside-toggle:hover span {
  margin: 0;
}

.sigma_header .aside-toggle:hover span:nth-child(1) {
  width: 10px;
  transform: rotate(45deg) translate(7px, -3px);
}

.sigma_header .aside-toggle:hover span:nth-child(3) {
  width: 10px;
  transform: rotate(-45deg) translate(7px, 3px);
}

.sigma_header .aside-toggle {
  display: none;
}

.sigma_header .aside-toggle span {
  display: block;
  width: 20px;
  height: 2px;
  border-radius: 3px;
  background-color: var(--thm-secondary);
  transition: 0.3s;
  transform-origin: right;
}

.sigma_header .aside-toggle span + span {
  margin-top: 5px;
}

.sigma_header .sigma_header-controls.style-2 ul li.header-controls-item a.sigma_header-control-cart {
  width: 58px;
  position: relative;
  height: 58px;
  padding: 0;
}

.sigma_header .sigma_header-controls.style-2 ul li.header-controls-item a.sigma_header-control-cart span {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 25px;
  height: 25px;
  font-weight: 600;
  font-size: 12px;
  background-color: var(--thm-base);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.sigma_header .sigma_header-controls.style-2 .aside-toggle,
.sigma_header .sigma_header-controls.style-2 ul li.header-controls-item a {
  border-radius: 50%;
}

.sigma_header .container-fluid, .sigma_header .container-xxl, .sigma_header .container-xl, .sigma_header .container-lg, .sigma_header .container-md, .sigma_header .container-sm {
  padding-left: 65px;
  padding-right: 65px;
}

/* Style 2 */
.sigma_header.style-2 .sigma_header-top-contacts .sigma_header-top-nav li a {
  background-color: transparent;
  border: 1px solid var(--thm-border);
  color: var(--thm-secondary);
}

.sigma_header.style-2 .sigma_header-top-contacts .sigma_header-top-nav li a:hover {
  background-color: var(--thm-base);
  border-color: var(--thm-base);
  color: #fff;
}

/* Style 3 */
.sigma_header.style-3 {
  background-color: transparent;
  box-shadow: none;
}

.sigma_header.style-3 .sigma_header-top,
.sigma_header.style-5 .sigma_header-top {
  border: 0;
  padding: 10px 20px;
  background-color: #fff;
  background-color: var(--thm-secondary);
}

.sigma_header.style-3 .sigma_header-middle,
.sigma_header.style-5 .sigma_header-middle {
  padding: 0 20px;
  background-color: #fff;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
}

.sigma_header.style-3 .sigma_header-top-inner,
.sigma_header.style-5 .sigma_header-top-inner {
  padding: 0;
}

.sigma_header.style-3 .sigma_header-controls.style-2 ul li.header-controls-item a.sigma_header-control-cart,
.sigma_header.style-3 .aside-toggle,
.sigma_header.style-3 .aside-toggle.desktop-toggler,
.sigma_header.style-3 .sigma_header-controls ul li.header-controls-item a,
.sigma_header.style-5 .sigma_header-controls.style-2 ul li.header-controls-item a.sigma_header-control-cart,
.sigma_header.style-5 .aside-toggle,
.sigma_header.style-5 .aside-toggle.desktop-toggler,
.sigma_header.style-5 .sigma_header-controls ul li.header-controls-item a {
  width: 50px;
  height: 50px;
}

.sigma_header.style-3 .sigma_header-top .sigma_header-top-cta li a,
.sigma_header.style-3 .sigma_header-top-contacts .sigma_header-top-nav li a {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.sigma_header.style-3 .sigma_header-top .sigma_header-top-cta li a:hover,
.sigma_header.style-3 .sigma_header-top-contacts .sigma_header-top-nav li a:hover,
.sigma_header.style-5 .sigma_header-top .sigma_header-top-cta li a:hover,
.sigma_header.style-5 .sigma_header-top-contacts .sigma_header-top-nav li a:hover {
  background-color: var(--thm-base);
}

/* Style 4 */
.sigma_header.style-4 .sigma_header-bottom-inner {
  justify-content: center;
}

/* Style 5 */
.sigma_header.style-5 .sigma_header-top,
.sigma_header.style-5 .sigma_header-middle,
.sigma_header.style-6 .sigma_header-top,
.sigma_header.style-6 .sigma_header-middle {
  padding-left: 0;
  padding-right: 0;
}

.sigma_header.style-5 .sigma_header-top-contacts .sigma_header-top-nav li a,
.sigma_header.style-6 .sigma_header-top-contacts .sigma_header-top-nav li a {
  background-color: transparent;
  width: auto;
  height: auto;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}

.sigma_header.style-5 .sigma_header-top-contacts .sigma_header-top-nav li a:hover,
.sigma_header.style-6 .sigma_header-top-contacts .sigma_header-top-nav li a:hover {
  background-color: transparent;
  color: var(--thm-base-hover);
}

.sigma_header.style-5 .sigma_header-top .sigma_header-top-nav li + li {
  margin-left: 24px;
}

.sigma_header .sigma_header-top-links li a {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.sigma_header .sigma_header-top-links li a:hover,
.sigma_header .sigma_header-top-links.important-links li a:hover {
  color: var(--thm-base-hover);
}

.sigma_header .sigma_header-top-links li a i {
  margin-right: 10px;
}

.sigma_header .sigma_header-top-links li + li {
  margin-left: 15px;
}

.sigma_header .sigma_header-top-links.important-links li a {
  font-size: 16px;
  padding: 30px 0;
  color: var(--thm-secondary);
}

.sigma_header .sigma_header-top-links.important-links li a i {
  width: 40px;
  height: 40px;
  border: 1px solid var(--thm-border);
  border-radius: 50%;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--thm-base);
}

.sigma_header .sigma_header-top-links.important-links li p {
  margin: 0;
  line-height: 1.3;
}

.sigma_header .sigma_header-top-links.important-links li p:first-child {
  font-size: 13px;
}

.sigma_header .sigma_header-top-links.important-links li + li {
  margin-left: 40px;
}

.sigma_header.style-5 .sigma_header-bottom {
  background-color: #fff;
}

.sigma_header.style-5 .sigma_header-bottom .navbar {
  border-top: 1px solid var(--thm-border);
}

.sigma_header.style-5 .sigma_header-bottom .navbar-nav > li > a {
  color: var(--thm-secondary);
  padding-top: 20px;
  padding-bottom: 20px;
}

.sigma_header.style-5 .sigma_header-bottom .navbar-nav > li > a:hover {
  color: var(--thm-base);
}

.sigma_header.style-5 .sigma_header-controls ul li.header-controls-item a,
.sigma_header.style-5 .aside-toggle,
.sigma_header.style-5 .aside-toggle.desktop-toggler {
  background-color: #f2f6fa;
  border-color: #f2f6fa;
}

.sigma_header.style-5 .sigma_header-controls ul li.header-controls-item a:hover,
.sigma_header.style-5 .aside-toggle:hover,
.sigma_header.style-5 .aside-toggle.desktop-toggler:hover {
  background-color: var(--thm-base);
  border-color: var(--thm-base);
}

/* Style 6 */
.sigma_header.style-6 .sigma_header-top-links.important-links li a {
  padding: 0;
  margin: 30px 0;
}

.sigma_header.style-6 .sigma_header-top-links.important-links li + li {
  margin-left: 30px;
}

.sigma_header.style-6 .sigma_header-top-links.important-links li + li a {
  border-left: 2px solid var(--thm-border);
  padding-left: 30px;
}

.sigma_header .sigma_header-bottom .navbar-nav > li > a {
  padding-top: 12px;
  padding-bottom: 12px;
}

.sigma_header.style-6 .sigma_header-controls ul li.header-controls-item a {
  border: 0;
  color: #fff;
}

.sigma_header.style-6 .aside-toggle.desktop-toggler {
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-radius: 0;
}

.sigma_header .aside-toggle.desktop-toggler span {
  background-color: var(--thm-secondary);
}

.sigma_header.style-6 .aside-toggle.desktop-toggler span {
  background-color: #fff;
}

.sigma_header.style-6 .sigma_header-controls ul li.header-controls-item a:hover,
.sigma_header.style-6 .aside-toggle.desktop-toggler:hover {
  background-color: transparent;
}

.sigma_header.style-6 .sigma_header-controls ul li.header-controls-item,
.sigma_header.style-6 .aside-toggle.desktop-toggler {
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}

.sigma_header.style-6 .sigma_header-controls ul li + li {
  margin-left: 0;
}

.sigma_header.style-6 .sigma_header-bottom .sigma_btn,
.sigma_header.style-6 .sigma_header-bottom .sigma_btn:before {
  border-radius: 0;
}

.sigma_header.style-6 .sigma_header-top-links.important-links li a i {
  border: 0;
  background-color: rgba(var(--thm-base-rgb), 0.1);
  color: var(--thm-base);
}

/* Search Form */
.search-form-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.search-form-wrapper.open {
  opacity: 1;
  visibility: visible;
}

.search-form-wrapper .sigma_close {
  position: absolute;
  top: 60px;
  right: 60px;
  border-color: #fff;
}

.search-form-wrapper .sigma_close span {
  background-color: #fff;
}

.search-form-wrapper form {
  position: relative;
  width: 70%;
  transition-duration: 0.3s;
  transition-delay: 0.3s;
  transform: scale(0.5);
  opacity: 0;
  visibility: hidden;
}

.search-form-wrapper.open form {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.search-form-wrapper form input {
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #fff;
  font-size: 40px;
  height: 80px;
  width: 100%;
  outline: none;
  color: #fff;
  font-weight: 400;
}

.search-form-wrapper form input::placeholder {
  color: #fff;
}

.search-form-wrapper .search-btn {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background-color: transparent;
  border: 0;
  color: #fff;
  font-size: 30px;
  outline: none;
  transition: 0.3s;
  cursor: pointer;
  padding: 0;
}

.search-form-wrapper .search-btn:hover i {
  color: var(--thm-base);
}

/*Aside*/
.sigma_aside .sigma_close {
  position: absolute;
  right: 20px;
  top: 20px;
}

.sigma_aside {
  position: fixed;
  top: 0;
  left: -300px;
  width: 300px;
  height: 100%;
  z-index: 99;
  background-color: #fff;
  transition: 0.3s;
  overflow-y: auto;
}

.sigma_aside-overlay.aside-trigger,
.sigma_aside:not(.sigma_aside-desktop) {
  display: none;
}

.sigma_aside .sigma_logo-wrapper {
  padding: 30px 15px;
}

.sigma_aside .navbar-nav li a {
  display: flex;
  align-items: center;
  padding: 12px 15px;
  color: var(--thm-secondary);
  font-weight: 600;
  border-bottom: 1px solid var(--thm-border);
}

.sigma_aside .navbar-nav li a:hover {
  color: var(--thm-base);
}

.sigma_aside .navbar-nav li.menu-item-has-children > a:after {
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "";
  right: 18px;
  display: flex;
  align-items: center;
  font-size: 18px;
}

.sigma_aside .navbar-nav li .sub-menu {
  display: none;
}

.sigma_aside .navbar-nav li .sub-menu {
  background-color: #f2f6fa;
}

.sigma_aside-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 98;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.4);
}

.aside-open .sigma_aside {
  left: 0;
}

.aside-open .sigma_aside:not(.sigma_aside-desktop) + .sigma_aside-overlay,
.aside-right-open .sigma_aside-desktop + .sigma_aside-overlay {
  opacity: 1;
  visibility: visible;
}

.sigma_aside.sigma_aside-desktop {
  right: -420px;
  width: 420px;
  left: auto;
}

.sigma_aside.sigma_aside-desktop .sidebar {
  padding: 30px;
}

.aside-right-open .sigma_aside.sigma_aside-desktop {
  right: 0;
}

/*-------------- Subheader --------------------------- */
.sigma_subheader {
  padding: 200px 0;
  position: relative;
}

.sigma_subheader .container {
  position: relative;
}

.sigma_subheader .sigma_subheader-inner {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.sigma_subheader .sigma_subheader-inner h1, .sigma_subheader .sigma_subheader-inner .h1 {
  color: #fff;
  font-size: 72px;
  margin-bottom: 0;
}

.sigma_subheader .sigma_subheader-inner p {
  max-width: 550px;
  color: #999b9f;
}

.sigma_subheader .breadcrumb {
  background-color: transparent;
  justify-content: center;
  padding: 0;
  margin-bottom: 0;
  margin-top: 20px;
}

.sigma_subheader .breadcrumb li {
  color: #fff;
  font-size: 16px;
  padding: 0;
}

.sigma_subheader .breadcrumb li a {
  color: var(--thm-base);
  font-weight: 600;
  display: inline-block;
}

.sigma_subheader .breadcrumb li a:hover {
  text-decoration: none;
  color: var(--thm-base-hover);
}

.sigma_subheader .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: #fff;
  padding: 0 12px;
}

.sigma_subheader .breadcrumb li,
.sigma_subheader .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: #fff;
}

/* Style 2 */
.sigma_subheader.style-3 .sigma_subheader-inner,
.sigma_subheader.style-2 .sigma_subheader-inner,
.sigma_subheader.style-11 .sigma_subheader-inner {
  text-align: left;
  margin: 0;
}

.sigma_subheader.style-2 .breadcrumb,
.sigma_subheader.style-11 .breadcrumb {
  justify-content: flex-start;
}

/* Style 3 */
.sigma_subheader.style-3 {
  padding: 0;
}

.sigma_subheader.style-3 .sigma_subheader-inner {
  padding: 140px 0;
}

.sigma_subheader.style-3 .breadcrumb {
  position: absolute;
  bottom: 0;
  right: 15px;
  background-color: #fff;
  border-radius: 0;
  padding: 20px;
}

.sigma_subheader.style-3 .breadcrumb li,
.sigma_subheader.style-3 .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: var(--thm-b-text);
}

/* Style 4 */
.sigma_subheader.style-4 .sigma_subheader-shape {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 185px;
  background-color: transparent;
}

.sigma_subheader.style-4 .sigma_subheader-shape svg {
  width: calc(125% + 1.3px);
  height: 185px;
  display: block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.sigma_subheader.style-4 .sigma_subheader-shape svg path {
  fill: #ffffff;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

/* Style 5 */
.sigma_subheader.style-5::after {
  content: "";
  background-color: #fff;
  display: block;
  width: 120%;
  height: 70px;
  left: 0;
  position: absolute;
  right: 0;
  bottom: -28px;
  transform: rotate(-2deg);
}

/* Style 6 */
.sigma_subheader.style-6 {
  padding: 100px 0;
}

.sigma_subheader.style-6 .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sigma_subheader.style-6 .sigma_subheader-inner {
  margin: 0;
  text-align: left;
}

.sigma_subheader.style-6 .sigma_subheader-inner h1, .sigma_subheader.style-6 .sigma_subheader-inner .h1 {
  font-size: 38px;
}

/* Style 7 */
.sigma_subheader.style-9,
.sigma_subheader.style-8,
.sigma_subheader.style-7 {
  padding: 280px 0 40px;
}

.sigma_subheader.style-9 .sigma_subheader-inner,
.sigma_subheader.style-8 .sigma_subheader-inner,
.sigma_subheader.style-7 .sigma_subheader-inner {
  margin: 0;
  text-align: left;
}

.sigma_subheader.style-9 .breadcrumb,
.sigma_subheader.style-8 .breadcrumb,
.sigma_subheader.style-7 .breadcrumb {
  justify-content: flex-start;
}

.sigma_subheader.style-9::before,
.sigma_subheader.style-8::before,
.sigma_subheader.style-7::before {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.4), transparent);
}

.sigma_subheader.style-8 .sigma_stroke-text {
  text-align: left;
  opacity: 1;
  font-style: italic;
}

.sigma_subheader.style-9 {
  border-bottom: 2px solid var(--thm-border);
}

.sigma_subheader.style-9 .sigma_subheader-inner h1, .sigma_subheader.style-9 .sigma_subheader-inner .h1 {
  color: var(--thm-secondary);
}

/* Style 10 */
.sigma_subheader.style-10 {
  padding: 40px 0;
}

.sigma_subheader.style-10 .sigma_subheader-inner {
  text-align: left;
  margin: 0;
}

.sigma_subheader.style-10 .sigma_subheader-inner h1, .sigma_subheader.style-10 .sigma_subheader-inner .h1 {
  color: var(--thm-secondary);
  display: inline-block;
  position: relative;
}

.sigma_subheader.style-10 .sigma_subheader-inner h1::after, .sigma_subheader.style-10 .sigma_subheader-inner .h1::after,
.sigma_subheader.style-10 .sigma_subheader-inner h1::before,
.sigma_subheader.style-10 .sigma_subheader-inner .h1::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--thm-secondary);
  width: 100vw;
  height: 20px;
}

.sigma_subheader.style-10 .sigma_subheader-inner h1::after, .sigma_subheader.style-10 .sigma_subheader-inner .h1::after {
  margin-left: 20px;
}

.sigma_subheader.style-10 .sigma_subheader-inner h1::before, .sigma_subheader.style-10 .sigma_subheader-inner .h1::before {
  right: 100%;
  margin-right: 20px;
}

/* Style 11 */
.sigma_subheader.style-11 .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "|";
}

/*-------------- Video Icon & Ratings General style --------------------------- */
.sigma_video-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #fff;
  color: var(--thm-base);
  font-size: 20px;
  transition: 0.3s;
}

.sigma_video-btn:hover {
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_video-btn:before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  border: 2px solid #fff;
  animation-name: pulseInOut;
  opacity: 0;
  border-radius: 50%;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

.sigma_video-btn:after {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  border: 2px solid #fff;
  animation-name: pulseInOut;
  opacity: 0;
  border-radius: 50%;
  animation-duration: 3.5s;
  animation-iteration-count: infinite;
}

.sigma_rating-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sigma_rating-wrapper span {
  margin-left: 10px;
  font-size: 13px;
}

.sigma_rating {
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-size: 14px;
}

.sigma_rating i {
  display: inline-block;
  color: #fcb71b;
}

.sigma_rating i + i {
  padding-left: 4px;
}

/*-------------- Info Box --------------------------- */
/* Info Box style 1 */
.sigma_info {
  position: relative;
  border-radius: 6px;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
  padding: 40px;
  margin-bottom: 30px;
}

.sigma_info .sigma_info-title {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  position: relative;
}

.sigma_info .sigma_info-title .sigma_info-icon {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 50%;
  margin-right: 20px;
}

.sigma_info .sigma_info-title .sigma_info-icon i {
  font-size: 32px;
}

.sigma_info .sigma_info-title h5, .sigma_info .sigma_info-title .h5 {
  margin: 0;
  flex: 1;
}

.sigma_info .sigma_info-description p {
  margin-bottom: 0;
}

/* Info Box style 2, 3 , 4 & 7 */
.sigma_info.style-2 {
  padding: 30px;
  background-color: #fff;
}

.sigma_info.style-2 .sigma_info-title,
.sigma_info.style-3 .sigma_info-title,
.sigma_info.style-4 .sigma_info-title,
.sigma_info.style-6 .sigma_info-title,
.sigma_info.style-7 .sigma_info-title,
.sigma_info.style-8 .sigma_info-title,
.sigma_info.style-11 .sigma_info-title,
.sigma_info.style-19 .sigma_info-title,
.sigma_info.style-12 .sigma_info-title,
.sigma_info.style-13 .sigma_info-title,
.sigma_info.style-16 .sigma_info-title {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 0;
  max-width: 100%;
}

.sigma_info.style-2 .sigma_info-title .sigma_info-icon {
  border-radius: 8px;
}

.sigma_info.style-2 .sigma_info-title .sigma_info-icon,
.sigma_info.style-3 .sigma_info-title .sigma_info-icon,
.sigma_info.style-4 .sigma_info-title .sigma_info-icon,
.sigma_info.style-7 .sigma_info-title .sigma_info-icon,
.sigma_info.style-11 .sigma_info-title .sigma_info-icon,
.sigma_info.style-19 .sigma_info-title .sigma_info-icon,
.sigma_info.style-12 .sigma_info-title .sigma_info-icon,
.sigma_info.style-13 .sigma_info-title .sigma_info-icon,
.sigma_info.style-16 .sigma_info-title .sigma_info-icon {
  margin-right: 0;
}

.sigma_info.style-2 .sigma_info-title h5, .sigma_info.style-2 .sigma_info-title .h5,
.sigma_info.style-7 .sigma_info-title h5,
.sigma_info.style-7 .sigma_info-title .h5,
.sigma_info.style-11 .sigma_info-title h5,
.sigma_info.style-11 .sigma_info-title .h5,
.sigma_info.style-19 .sigma_info-title h5,
.sigma_info.style-19 .sigma_info-title .h5,
.sigma_info.style-12 .sigma_info-title h5,
.sigma_info.style-12 .sigma_info-title .h5,
.sigma_info.style-13 .sigma_info-title h5,
.sigma_info.style-13 .sigma_info-title .h5 {
  margin: 25px 0 14px;
}

.sigma_info.style-2 .sigma_btn,
.sigma_info.style-4 .sigma_btn,
.sigma_info.style-11 .sigma_btn,
.sigma_info.style-13 .sigma_btn,
.sigma_info.style-2 .btn-link,
.sigma_info.style-4 .btn-link,
.sigma_info.style-11 .btn-link,
.sigma_info.style-13 .btn-link {
  margin-top: 15px;
}

/* Info Box style 3 & 6 */
.sigma_info.style-3,
.sigma_info.style-5,
.sigma_info.style-6,
.sigma_info.style-8,
.sigma_info.style-16 {
  box-shadow: none;
  padding: 0;
  text-align: center;
}

.sigma_info.style-3 .sigma_info-title,
.sigma_info.style-6 .sigma_info-title,
.sigma_info.style-8 .sigma_info-title,
.sigma_info.style-16 .sigma_info-title {
  align-items: center;
}

.sigma_info.style-3 .sigma_info-title .sigma_info-icon,
.sigma_info.style-6 .sigma_info-title .sigma_info-icon,
.sigma_info.style-8 .sigma_info-title .sigma_info-icon,
.sigma_info.style-16 .sigma_info-title .sigma_info-icon {
  border-radius: 6px;
  background-color: var(--thm-base);
  margin-bottom: 30px;
  margin-right: 0;
  color: #fff;
}

.sigma_info.style-3 .sigma_info-title h5, .sigma_info.style-3 .sigma_info-title .h5,
.sigma_info.style-16 .sigma_info-title h5,
.sigma_info.style-16 .sigma_info-title .h5 {
  margin: 0 0 12px;
}

.sigma_info.style-3 a {
  width: 50px;
  height: 50px;
  background-color: #f2f6fa;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--thm-text);
  border-radius: 50%;
  margin: 30px auto 0;
}

.sigma_info.style-3 a:hover {
  background-color: var(--thm-base);
  color: #fff;
}

/* Info Box style 4 */
.sigma_info.style-4 {
  text-align: center;
  margin-top: 40px;
  background-color: #fff;
  padding: 10px 10px 0;
}

.sigma_info.style-4 .sigma_info-title {
  height: 200px;
  border-radius: 6px;
  margin: -50px auto 0;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

.sigma_info.style-4 .sigma_info-title .shape-1 {
  position: absolute;
  left: -10px;
  top: 20px;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  transform: rotate(45deg);
  transition: 1s;
}

.sigma_info.style-4 .sigma_info-title .shape-2 {
  position: absolute;
  right: -16px;
  bottom: 36px;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  transform: rotate(135deg);
  transition: 1s;
}

.sigma_info.style-4 .sigma_info-title:hover .shape-1 {
  top: 40px;
}

.sigma_info.style-4 .sigma_info-title:hover .shape-2 {
  bottom: 46px;
}

.sigma_info.style-4 .sigma_info-title .shape-1.round,
.sigma_info.style-4 .sigma_info-title .shape-2.round {
  border-radius: 50%;
}

.sigma_info.style-4 .sigma_info-title .sigma_info-icon {
  font-size: 38px;
  margin-right: 0;
}

.sigma_info.style-4 .sigma_info-description {
  padding: 40px 20px;
}

.sigma_info.style-4 .sigma_info-description h5, .sigma_info.style-4 .sigma_info-description .h5,
.sigma_info.style-5 .sigma_info-description h5,
.sigma_info.style-5 .sigma_info-description .h5 {
  margin-bottom: 15px;
}

.sigma_info.style-4 .sigma_info-description p {
  margin-bottom: 0;
}

.sigma_info.style-4 .btn-link {
  justify-content: center;
  margin-top: 15px;
  font-weight: 400;
}

/* Info Box style 5 */
.sigma_info.style-5 {
  background-color: #f2f6fa;
  border-radius: 0;
}

.sigma_info.style-5 .sigma_info-thumb {
  overflow: hidden;
  position: relative;
  display: block;
}

.sigma_info.style-5 .sigma_info-thumb img {
  transition: 0.3s;
  width: 100%;
}

.sigma_info.style-5 .sigma_info-thumb:hover img {
  transform: scale(1.1);
}

.sigma_info.style-5 .sigma_info-description {
  padding: 30px;
}

/* Info Box style 6 */
.sigma_info.style-6 {
  background-color: transparent;
  padding: 20px 20px 0;
}

.sigma_info.style-6 .sigma_info-title .sigma_info-icon,
.sigma_info.style-8 .sigma_info-title .sigma_info-icon {
  width: 120px;
  height: 120px;
  background-color: #fff;
  border-radius: 50%;
  color: var(--thm-base);
  border: 3px solid var(--thm-base);
  font-size: 30px;
  z-index: 1;
}

.sigma_info.style-6 .sigma_info-title span.text,
.sigma_info.style-8 .sigma_info-title span.text {
  position: absolute;
  display: block;
  top: -52px;
  right: -12px;
  font-size: 120px;
  color: rgba(51, 51, 51, 0.19);
}

.sigma_info.style-6 .sigma_info-title h5, .sigma_info.style-6 .sigma_info-title .h5 {
  margin-bottom: 22px;
}

/* Info Box style 7 */
.sigma_info.style-7 {
  padding: 40px 30px;
  overflow: hidden;
}

.sigma_info.style-7 .sigma_info-title .sigma_info-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.sigma_info.style-7 .shape-1,
.sigma_info.style-7 .shape-2 {
  position: absolute;
  right: -70px;
  top: -70px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  transform: none;
  background-color: rgba(255, 255, 255, 0.13);
  transition: 0.3s;
}

.sigma_info.style-7 .shape-2,
.sigma_info.style-7.bg-white .shape-2 {
  right: -22px;
  top: auto;
  bottom: 10px;
}

.sigma_info.style-7.bg-white .shape-1,
.sigma_info.style-7.bg-white .shape-2 {
  background-color: rgba(0, 0, 0, 0.13);
}

.sigma_info.style-7:hover .shape-1 {
  top: -40px;
}

.sigma_info.style-7:hover .shape-2 {
  bottom: 20px;
}

/* Info Box style 8 */
.sigma_info.style-8 {
  background-color: #fff;
  padding: 50px;
}

.sigma_info.style-8 .sigma_info-title .sigma_info-icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  color: var(--thm-secondary);
  border: 2px dashed var(--thm-border);
  font-size: 20px;
  position: relative;
  margin-bottom: 40px;
}

.sigma_info.style-8 .sigma_info-title h5, .sigma_info.style-8 .sigma_info-title .h5 {
  line-height: 1.6;
}

.sigma_info.style-8 .sigma_info-title .sigma_info-icon span.text {
  position: absolute;
  top: -6px;
  left: -15px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  background-color: var(--thm-base);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 8px rgba(var(--thm-base-rgb), 0.5);
}

/*-------------- Features --------------------------- */
/* Info Box style 9 */
.sigma_info.style-9 {
  background-color: #fff;
  box-shadow: none;
  border: 1px solid var(--thm-border);
  border-radius: 25px;
  transition: 0.3s;
  padding: 30px;
}

.sigma_info.style-9 * {
  transition: 0.3s;
}

.sigma_info.style-9 .sigma_info-title {
  display: flex;
  align-items: center;
}

.sigma_info.style-9 .sigma_info-title .sigma_info-icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: var(--thm-base);
  box-shadow: 0px 0 40px 5px rgba(0, 0, 0, 0.03);
  border-radius: 50%;
  margin-right: 20px;
}

.sigma_info.style-9 .sigma_info-title .sigma_info-icon i {
  font-size: 16px;
}

.sigma_info.style-9 .sigma_info-title p {
  margin: 0;
}

.sigma_info.style-9 .sigma_info-description {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sigma_info.style-9 .sigma_info-description i {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--thm-secondary);
  color: #fff;
  border-radius: 50%;
  margin: 0;
}

.sigma_info.style-9:hover {
  background-color: var(--thm-secondary);
}

.sigma_info.style-9:hover * {
  color: #fff;
}

.sigma_info.style-9:hover .sigma_info-title .sigma_info-icon {
  background-color: var(--thm-base);
}

.sigma_info.style-9:hover .sigma_info-description i {
  background-color: var(--thm-base);
}

/* info box style 10 */
.sigma_info.style-10,
.sigma_info.style-15,
.sigma_info.style-17,
.sigma_info.style-18,
.sigma_info.style-20 {
  box-shadow: none;
  padding: 0;
  display: flex;
}

.sigma_info.style-10 .sigma_info-title,
.sigma_info.style-15 .sigma_info-title,
.sigma_info.style-17 .sigma_info-title,
.sigma_info.style-18 .sigma_info-title,
.sigma_info.style-20 .sigma_info-title {
  align-items: flex-start;
  margin-bottom: 0;
}

.sigma_info.style-10 .sigma_info-description,
.sigma_info.style-15 .sigma_info-description,
.sigma_info.style-17 .sigma_info-description,
.sigma_info.style-18 .sigma_info-description,
.sigma_info.style-20 .sigma_info-description {
  flex: 1;
}

.sigma_info.style-10 .sigma_info-description h5, .sigma_info.style-10 .sigma_info-description .h5,
.sigma_info.style-15 .sigma_info-description h5,
.sigma_info.style-15 .sigma_info-description .h5,
.sigma_info.style-20 .sigma_info-description h5,
.sigma_info.style-20 .sigma_info-description .h5 {
  margin: 0 0 14px;
}

.sigma_info.style-10,
.sigma_info.style-11,
.sigma_info.style-15,
.sigma_info.style-19,
.sigma_info.style-20 {
  border: 1px solid var(--thm-border);
  padding: 30px;
  transition: 0.3s;
}

.sigma_info.style-10:hover,
.sigma_info.style-11:hover {
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
  border-color: transparent;
}

.sigma_info.style-10 .sigma_info-title .sigma_info-icon,
.sigma_features.style-4 .sigma_info-title .sigma_info-icon,
.sigma_features.style-17 .sigma_info-title .sigma_info-icon,
.sigma_features.style-18 .sigma_info-title .sigma_info-icon {
  border: 0;
  border-radius: 6px;
}

/* info box style 11 */
.sigma_info.style-11,
.sigma_info.style-12,
.sigma_info.style-13 {
  box-shadow: none;
  text-align: center;
}

.sigma_info.style-11 .sigma_info-title,
.sigma_info.style-13 .sigma_info-title {
  align-items: center;
}

.sigma_info.style-11 .sigma_info-title .sigma_info-icon,
.sigma_info.style-12 .sigma_info-title .sigma_info-icon,
.sigma_info.style-13 .sigma_info-title .sigma_info-icon,
.sigma_info.style-19 .sigma_info-title .sigma_info-icon {
  border-radius: 6px;
}

/* info box style 12 */
.sigma_info.style-12 {
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 0;
  border-radius: 0;
  text-align: left;
}

/* info box style 13 */
.sigma_info.style-13 {
  padding: 0;
  margin: 0;
}

.sigma_info.style-13 .sigma_info-description p {
  max-width: 700px;
  margin: 0 auto;
}

.sigma_info.style-13 .sigma_info-description {
  margin-bottom: 15px;
}

.sigma_info-slider .slick-dots,
.sigma_info-image-slider-2 .slick-dots {
  margin-top: 20px;
}

.sigma_info-slider .slick-dots li button,
.sigma_info-image-slider-2 .slick-dots li button {
  background-color: var(--thm-border);
}

.sigma_info-slider .slick-dots .slick-active button,
.sigma_info-image-slider-2 .slick-dots .slick-active button {
  background-color: var(--thm-base);
}

.sigma_info-image-slider .slick-prev,
.sigma_info-image-slider .slick-next,
.sigma_info-image-slider-2 .slick-prev,
.sigma_info-image-slider-2 .slick-next {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_info-image-slider .slick-prev:hover,
.sigma_info-image-slider .slick-next:hover,
.sigma_info-image-slider-2 .slick-prev:hover,
.sigma_info-image-slider-2 .slick-next:hover {
  background-color: var(--thm-base-hover);
}

.sigma_info-image-slider .slick-next,
.sigma_info-image-slider-2 .slick-next {
  right: 10px;
  left: auto;
}

.sigma_info-image-slider:hover .slick-prev,
.sigma_info-image-slider-2:hover .slick-prev {
  left: 20px;
  opacity: 1;
  visibility: visible;
}

.sigma_info-image-slider:hover .slick-next,
.sigma_info-image-slider-2:hover .slick-next {
  right: 20px;
  opacity: 1;
  visibility: visible;
}

.sigma_info-image.has-width {
  width: calc(1140px - 20%);
}

hr {
  border-top: 1px solid var(--thm-border);
  margin: 30px 0;
}

/* info box style 14 */
.sigma_info.style-14 {
  background-color: var(--thm-secondary);
  text-align: center;
  transition: 0.3s;
}

.sigma_info.style-14 .sigma_info-icon {
  width: 80px;
  height: 80px;
  background-color: #fff;
  border-radius: 50%;
  color: #313131;
  margin: 0 auto 30px;
  display: flex;
  align-items: center;
  font-size: 40px;
  line-height: 1;
  justify-content: center;
}

.sigma_info.style-14 h5, .sigma_info.style-14 .h5 {
  color: #fff;
}

.sigma_info.style-14 p {
  color: #999b9f;
}

.sigma_info.style-14 .sigma_icon-block-content {
  margin-top: 20px;
}

.sigma_info.style-14 .sigma_icon-block-content i {
  width: 50px;
  height: 50px;
  color: #999b9f;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin: 0;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #999b9f;
  transition: 0.3s;
}

.sigma_info.style-14::before {
  content: "";
  position: absolute;
  top: -40px;
  right: -40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  width: 120px;
  height: 120px;
  transition: 0.3s;
}

.sigma_info.style-14:hover {
  transform: translateY(-5px);
}

.sigma_info.style-14:hover::before {
  width: 160px;
  height: 160px;
}

.sigma_info.style-14:hover .sigma_icon-block-content i {
  color: var(--thm-base);
  border-color: #fff;
  transform: rotate(45deg);
}

/* info box style 15 */
.sigma_info.style-15 {
  border: 0;
}

.sigma_info.style-15 .sigma_info-title .sigma_info-icon {
  width: 40px;
  height: 40px;
}

/* info box style 16 */
.sigma_info.style-16 .sigma_info-title .sigma_info-icon {
  width: 120px;
  height: 120px;
  background-color: transparent;
  border: 4px solid rgba(225, 225, 225, 0.24);
  color: var(--thm-secondary);
  position: relative;
}

.sigma_info.style-16 .sigma_info-title .sigma_info-icon i {
  font-size: 36px;
}

.sigma_info.style-16 .sigma_info-title .sigma_info-icon span {
  width: 35px;
  height: 35px;
  background-color: var(--thm-base);
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -20px;
  right: -20px;
}

.sigma_info-image.style-16 {
  position: relative;
  border-radius: 8px;
  margin: -190px 0 100px;
}

.sigma_info-image.style-16 img {
  border-radius: 8px;
}

.info-space {
  /* Temporary Code for space */
  padding-bottom: 200px;
}

/* info box style 17 */
.sigma_info.style-17,
.sigma_info.style-18 {
  background-color: var(--thm-base);
  margin-bottom: 0;
  border-radius: 0;
  padding: 35px 30px;
}

.sigma_info.style-17.with-hue {
  background-color: rgba(var(--thm-base-rgb), 0.8);
}

.sigma_info.style-17 .sigma_info-title .sigma_info-icon {
  width: auto;
  height: auto;
  color: #fff;
}

.sigma_info.style-17 .sigma_info-title .sigma_info-icon i,
.sigma_info.style-18 .sigma_info-title .sigma_info-icon i {
  font-size: 40px;
}

.sigma_info.style-17 .sigma_info-description h5, .sigma_info.style-17 .sigma_info-description .h5 {
  margin: 0 0 10px;
}

.sigma_info.style-17 .sigma_info-description h5, .sigma_info.style-17 .sigma_info-description .h5,
.sigma_info.style-17 .sigma_info-description p,
.sigma_info.style-18 .sigma_info-description p {
  color: #fff;
}

/* info box style 18 */
.sigma_info-wrapper {
  display: flex;
  align-items: center;
  background-color: #252525;
  padding: 35px 30px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sigma_info.style-18 {
  flex: 0 0 33.3%;
  padding: 0;
  background-color: transparent;
}

.sigma_info.style-18 + .sigma_info.style-18 {
  padding-left: 25px;
  border-left: 2px solid rgba(255, 255, 255, 0.3);
}

.sigma_info.style-18 .sigma_info-title .sigma_info-icon {
  color: var(--thm-base);
  width: 50px;
  height: 50px;
}

.sigma_info.style-18 .sigma_info-title {
  align-items: center;
}

/* info box style 19 */
.sigma_info.style-19 {
  text-align: left;
  border: 0;
  margin-bottom: 30px;
}

.sigma_info.style-19 .sigma_info-title .sigma_info-icon {
  margin: -65px 0 0;
}

/* info box style 20 */
.sigma_info.style-20 {
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.sigma_info.style-20 .sigma_info-description h5 a, .sigma_info.style-20 .sigma_info-description .h5 a,
.sigma_info.style-20 .sigma_info-description p {
  color: #fff;
}

.sigma_info.style-20 .sigma_info-description h5 a:hover, .sigma_info.style-20 .sigma_info-description .h5 a:hover {
  color: var(--thm-base-hover);
}

.sigma_info.style-20 .sigma_info-title {
  align-items: center;
}

.sigma_info.style-20 .sigma_info-title .sigma_info-icon {
  color: var(--thm-base);
}

.sigma_info.style-20 .sigma_info-title .sigma_info-icon i {
  font-size: 46px;
}

/*-------------- Category --------------------------- */
/* Category style 1 */
.sigma_category {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.sigma_category a,
.sigma_category img {
  width: 100%;
  transition: 0.3s;
}

.sigma_category img {
  height: 100%;
  object-fit: cover;
}

.sigma_category:hover img {
  transform: scale(1.1);
}

.sigma_category .sigma_category-content {
  width: calc(100% - 20px);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
}

.sigma_category .sigma_category-content span {
  background-color: #fff;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--thm-secondary);
  font-size: 15px;
  font-weight: 700;
  border-radius: 0;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
}

/* Category style 2 */
.sigma_category.style-2 .sigma_category-content,
.sigma_category.style-3 .sigma_category-content {
  width: auto;
  left: 30px;
  top: 30px;
  bottom: auto;
  transform: none;
}

.sigma_category.style-2 .sigma_category-content span {
  background-color: #fff;
  padding: 8px 15px;
  border-radius: 0;
}

/* Category style 3 */
.sigma_category.style-3 {
  margin-bottom: 0;
}

.sigma_category.style-3 a,
.sigma_category.style-3 img {
  width: 100%;
}

.sigma_category.style-3 img {
  height: 100%;
  object-fit: cover;
}

.sigma_category.style-3 .sigma_category-content span {
  line-height: 1.3;
  font-size: 24px;
  background-color: transparent;
  padding: 0;
  margin-bottom: 10px;
}

.sigma_category.style-3 .sigma_category-content a {
  color: var(--thm-secondary);
  font-size: 12px;
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
}

.sigma_category.style-3 .sigma_category-content a:hover {
  color: var(--thm-base-hover);
}

/* Category style 4 */
.sigma_category.style-4 {
  transition: 0.3s;
}

.sigma_category.style-4 .sigma_category-content {
  text-align: center;
  color: var(--thm-secondary);
  margin-bottom: 10px;
}

.sigma_category.style-4 .sigma_category-content h6, .sigma_category.style-4 .sigma_category-content .h6 {
  margin: 0;
  font-size: 24px;
  transition-duration: 0.3s;
  transition-delay: 0.1s;
  transform: translateY(20px);
}

.sigma_category.style-4 .sigma_category-content span {
  background-color: transparent;
  padding: 0;
  color: var(--thm-b-text);
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  transition-duration: 0.3s;
  transition-delay: 0.1s;
  font-weight: 400;
  box-shadow: none;
}

.sigma_category.style-4:hover {
  transform: scale(1.3);
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.1);
}

.sigma_category.style-4:hover .sigma_category-content h6, .sigma_category.style-4:hover .sigma_category-content .h6,
.sigma_category.style-4:hover .sigma_category-content span {
  transform: translateY(0);
}

.sigma_category.style-4:hover .sigma_category-content h6, .sigma_category.style-4:hover .sigma_category-content .h6 {
  font-size: 20px;
}

.sigma_category.style-4:hover .sigma_category-content span {
  opacity: 1;
  visibility: visible;
  font-size: 12px;
}

/*-------------- General Section --------------------------- */
/* Style 1 */
.sigma_general-list {
  position: relative;
  margin-bottom: 30px;
}

.sigma_general-list ul li {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 30px;
  padding: 10px;
}

.sigma_general-list ul li + li {
  margin-top: 20px;
}

.sigma_general-list ul li i {
  width: 40px;
  height: 40px;
  background-color: var(--thm-base);
  color: #fff;
  border-radius: 50%;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 8px rgba(var(--thm-base-rgb), 0.5);
}

.sigma_general-list ul li span {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: var(--thm-secondary);
  padding-left: 20px;
  flex: 1;
}

/* Style 2 */
.sigma_general-list.style-2 ul li,
.sigma_general-list.style-3 ul li {
  background-color: transparent;
  padding: 0;
  border-radius: 0;
}

/* Style 3 */
.sigma_general-list.style-4 ul li i,
.sigma_general-list.style-3 ul li i {
  background-color: transparent;
  color: var(--thm-base);
  box-shadow: none;
  width: auto;
  height: auto;
}

/* Style 4 */
.sigma_general-list.style-4 ul li {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sigma_general-list.style-4 ul li + li {
  margin-top: 15px;
}

/* Style 5 */
.sigma_general-list.style-5 ul li {
  display: block;
  padding: 0;
  margin: 0;
  border-radius: 0;
  background-color: transparent;
}

.sigma_general-list.style-5,
.sigma_general-list.style-5 ul li p {
  margin: 0;
}

.sigma_general-list.style-5 ul li span {
  display: block;
  padding: 0;
}

.sigma_general-list.style-5 ul li + li {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid var(--thm-border);
}

/*-------------- Blog Sections --------------------------- */
/* Pagination */
.pagination {
  margin-top: 60px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 0;
}

.pagination .page-item {
  margin: 0;
}

.pagination .page-item:last-child .page-link,
.pagination .page-item:first-child .page-link,
.pagination .page-item .page-link {
  border-radius: 0;
}

.pagination .page-link {
  color: var(--thm-secondary);
  border: 0;
  background-color: #fff;
  box-shadow: 0 3px 24px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  padding: 15px 20px;
  margin-left: 15px;
}

.pagination .page-item.active .page-link {
  background-color: var(--thm-base);
}

.pagination .page-item:not(.active) .page-link:hover,
.pagination .page-item:not(.active) .page-link:focus {
  color: var(--thm-base);
  outline: none;
}

/* Style 1 */
.sigma_post {
  position: relative;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
  background-color: #fff;
  margin-bottom: 30px;
}

.sigma_post .sigma_post-thumb {
  position: relative;
  overflow: hidden;
}

.sigma_post .sigma_post-thumb a {
  display: block;
}

.sigma_post .sigma_post-thumb img {
  transition: 0.3s;
  width: 100%;
}

.sigma_post .sigma_post-thumb:hover img {
  transform: scale(1.1);
}

.sigma_post .sigma_post-categories {
  position: absolute;
  left: 20px;
  top: 20px;
  display: flex;
  align-items: center;
}

.sigma_post .sigma_post-categories a,
.widget.widget-sigma-recent-posts.style-3 .sigma_recent-post .sigma_post-categories a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 15px;
  background-color: var(--thm-base);
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  border-radius: 26px;
  transition: 0.3s;
}

.sigma_post .sigma_post-categories a:hover,
.widget.widget-sigma-recent-posts.style-3 .sigma_recent-post .sigma_post-categories a:hover {
  background-color: var(--thm-base-hover);
}

.sigma_post .sigma_post-categories a + a {
  margin-left: 10px;
}

.sigma_post .sigma_post-body {
  padding: 30px;
  position: relative;
}

.sigma_post .sigma_post-body:before {
  content: "";
  position: absolute;
  bottom: -3px;
  right: -3px;
  width: 10px;
  height: 10px;
  background-color: var(--thm-base);
}

.sigma_post .sigma_post-meta {
  border-bottom: 1px solid var(--thm-border);
  padding-bottom: 15px;
}

.sigma_post .sigma_post-meta ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 15px;
}

.sigma_post .sigma_post-meta ul li {
  font-family: var(--thm-font);
  color: var(--thm-b-text);
  font-size: 13px;
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 400;
  text-transform: uppercase;
}

.sigma_post .sigma_post-meta ul li i {
  margin-top: -4px;
}

.sigma_post .sigma_post-meta ul li a {
  color: var(--thm-b-text);
}

.sigma_post .sigma_post-meta ul li a:hover {
  color: var(--thm-base-hover);
}

.sigma_post .sigma_post-meta ul li + li {
  padding-left: 10px;
  margin-left: 10px;
}

.sigma_post .sigma_post-meta ul li + li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 12px;
  background-color: var(--thm-border);
}

.sigma_post .sigma_post-meta .sigma_post-date {
  position: absolute;
  right: 20px;
  top: -28px;
  text-align: center;
  padding: 12px 15px;
  font-weight: 600;
  background-color: var(--thm-base);
  color: #fff;
  line-height: 1;
  font-size: 15px;
  transition: 0.3s;
}

.sigma_post .sigma_post-meta .sigma_post-date:hover {
  background-color: var(--thm-base-hover);
}

.sigma_post .sigma_post-body .sigma_post-content {
  padding: 15px 0 0;
}

.sigma_post .sigma_post-body .sigma_post-content h5, .sigma_post .sigma_post-body .sigma_post-content .h5 {
  margin-bottom: 15px;
}

.sigma_post .sigma_post-body .btn-link {
  color: var(--thm-secondary);
  font-weight: 500;
  margin-top: 15px;
  font-size: 14px;
}

.sigma_post .sigma_post-body .btn-link i {
  color: var(--thm-base);
}

.sigma_post .sigma_post-body .btn-link:hover {
  color: var(--thm-base-hover);
}

.sigma_post.style-1 .sigma_post-meta ul {
  margin-top: 0;
}

/* Style 2 */
.sigma_post.style-2 .sigma_post-body,
.sigma_post.style-10 .sigma_post-body,
.sigma_post.style-11 .sigma_post-body {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 25px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
}

.sigma_post.style-2 img,
.sigma_post.style-10 img,
.sigma_post.style-11 img {
  width: 100%;
}

.sigma_post.style-2 .sigma_post-body:before,
.sigma_post.style-11 .sigma_post-body:before {
  content: none;
}

.sigma_post.style-2 .sigma_post-body .sigma_post-content,
.sigma_post.style-11 .sigma_post-body .sigma_post-content {
  padding: 0;
}

.sigma_post.style-2 .sigma_post-body .sigma_post-content h5 a, .sigma_post.style-2 .sigma_post-body .sigma_post-content .h5 a,
.sigma_post.style-2 .sigma_post-body .sigma_post-content p,
.sigma_post.style-11 .sigma_post-body .sigma_post-content h5 a,
.sigma_post.style-11 .sigma_post-body .sigma_post-content .h5 a,
.sigma_post.style-11 .sigma_post-body .sigma_post-content p {
  color: #fff;
}

.sigma_post.style-2 .sigma_post-body .sigma_post-content h5 a:hover, .sigma_post.style-2 .sigma_post-body .sigma_post-content .h5 a:hover,
.sigma_post.style-11 .sigma_post-body .sigma_post-content h5 a:hover,
.sigma_post.style-11 .sigma_post-body .sigma_post-content .h5 a:hover {
  color: var(--thm-base-hover);
}

.sigma_post.style-2 .sigma_post-body .sigma_post-content p {
  font-size: 15px;
}

.sigma_post.style-2 .sigma_post-body .btn-link {
  color: var(--thm-base);
}

/* Style 3 */
.sigma_post.style-3,
.sigma_post.style-4,
.sigma_post.style-12 {
  background-color: transparent;
  box-shadow: none;
}

.sigma_post.style-3 .sigma_post-body,
.sigma_post.style-4 .sigma_post-body,
.sigma_post.style-12 .sigma_post-body {
  border-radius: 0;
  background-color: #fff;
  width: calc(100% - 30px);
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
  margin: -25px auto 0;
}

.sigma_post.style-3 .sigma_post-body:before,
.sigma_post.style-4 .sigma_post-body:before,
.sigma_post.style-12 .sigma_post-body:before {
  content: none;
}

.sigma_post.style-3 .sigma_post-thumb {
  border-radius: 0;
}

.sigma_post.style-3 .sigma_post-meta,
.sigma_post.style-4 .sigma_post-meta,
.sigma_post.style-11 .sigma_post-meta,
.sigma_post.style-12 .sigma_post-meta {
  border-bottom: none;
  padding: 0;
}

.sigma_post.style-3 .sigma_post-meta ul,
.sigma_post.style-5 .sigma_post-meta ul {
  margin: 0;
}

.sigma_post.style-3 .sigma_post-meta ul li.author img {
  border-radius: 50%;
  margin-right: 10px;
}

.sigma_post.style-3 .sigma_post-meta ul li + li,
.sigma_post.style-4 .sigma_post-meta ul li + li,
.sigma_post.style-11 .sigma_post-meta ul li + li,
.sigma_post.style-12 .sigma_post-meta ul li + li {
  padding-left: 0;
}

.sigma_post.style-3 .sigma_post-meta ul li + li:before,
.sigma_post.style-4 .sigma_post-meta ul li + li:before,
.sigma_post.style-11 .sigma_post-meta ul li + li:before,
.sigma_post.style-12 .sigma_post-meta ul li + li:before {
  content: none;
}

.sigma_post.style-3 .sigma_post-body .sigma_post-content {
  padding: 15px 0;
}

.sigma_post.style-3 .sigma_post-body .sigma_post-content h5, .sigma_post.style-3 .sigma_post-body .sigma_post-content .h5,
.sigma_post.style-4 .sigma_post-body .sigma_post-content h5,
.sigma_post.style-4 .sigma_post-body .sigma_post-content .h5 {
  margin-bottom: 0;
}

/* Style 4 */
.sigma_post.style-4 .sigma_post-body,
.sigma_post.style-12 .sigma_post-body {
  border-radius: 0;
}

.sigma_post.style-4 .sigma_post-meta ul li.bold-text a {
  color: var(--thm-secondary);
  font-weight: 700;
}

.sigma_post.style-4 .sigma_post-body .sigma_post-content,
.sigma_post.style-12 .sigma_post-body .sigma_post-content {
  padding: 0;
}

.sigma_post.style-4 .sigma_post-meta {
  margin: 12px 0 10px;
}

.sigma_post.style-4 .sigma_post-body .sigma_post-content p,
.sigma_post.style-12 .sigma_post-body .sigma_post-content p {
  font-size: 14px;
}

/* Style 5 */
.sigma_post.style-5 {
  border-radius: 0;
  overflow: hidden;
}

.sigma_post.style-5 .sigma_post-body:before {
  content: none;
}

.sigma_post.style-5 .sigma_post-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sigma_post.style-5 .sigma_post-meta a,
.sigma_post.style-11 .sigma_post-meta .sigma_post-date,
.sigma_post.style-12 .sigma_post-meta .sigma_post-date {
  color: var(--thm-b-text);
  font-family: var(--thm-font);
  font-size: 13px;
  display: flex;
  text-transform: uppercase;
  align-items: center;
  font-weight: 400;
}

.sigma_post.style-5 .sigma_post-meta a:hover {
  color: var(--thm-base-hover);
}

.sigma_post.style-5 .sigma_post-meta a i,
.sigma_post.style-12 .sigma_post-meta a i,
.sigma_post.style-11 .sigma_post-meta i {
  color: var(--thm-base);
  font-weight: 500;
  margin-right: 8px;
  font-size: 16px;
}

.sigma_post.style-5 .sigma_post-meta ul li + li:before {
  content: none;
}

.sigma_post.style-5 .sigma_post-meta ul li + li {
  padding-left: 0;
}

.sigma_post.style-5 .sigma_post-meta ul li a {
  color: var(--thm-base);
  font-weight: 500;
  font-size: 16px;
}

/* Style 6 */
.sigma_post.style-6,
.sigma_post.style-7,
.sigma_post.style-9,
.sigma_post.style-10 {
  box-shadow: none;
  background-color: transparent;
}

.sigma_post.style-6 .sigma_post-body,
.sigma_post.style-7 .sigma_post-body,
.sigma_post.style-9 .sigma_post-body {
  padding: 15px 0 0 0;
}

.sigma_post.style-6 .sigma_post-body .sigma_post-content,
.sigma_post.style-9 .sigma_post-body .sigma_post-content,
.sigma_post.style-10 .sigma_post-body .sigma_post-content {
  padding: 15px 0 0;
}

.sigma_post.style-6 .sigma_post-meta,
.sigma_post.style-7 .sigma_post-meta,
.sigma_post.style-9 .sigma_post-meta,
.sigma_post.style-10 .sigma_post-meta {
  padding: 0;
  border: 0;
}

.sigma_post.style-6 .sigma_post-body:before,
.sigma_post.style-7 .sigma_post-body:before,
.sigma_post.style-9 .sigma_post-body:before,
.sigma_post.style-10 .sigma_post-body:before {
  content: none;
}

.sigma_post.style-6 .sigma_post-meta ul {
  margin: 0;
}

/* Style 7 */
.sigma_post.style-7 {
  margin-bottom: 60px;
}

.sigma_post.style-7 .sigma_post-body .sigma_post-content h5 + p, .sigma_post.style-7 .sigma_post-body .sigma_post-content .h5 + p {
  margin: 15px 0;
}

.sigma_post.style-7 .sigma_post-body .sigma_post-content h5, .sigma_post.style-7 .sigma_post-body .sigma_post-content .h5,
.sigma_post.style-8 .sigma_post-body .sigma_post-content h5,
.sigma_post.style-8 .sigma_post-body .sigma_post-content .h5 {
  margin-bottom: 0;
}

.sigma_post.style-7 .sigma_post-body .sigma_post-content,
.sigma_post.style-8 .sigma_post-body .sigma_post-content {
  padding: 0;
}

.sigma_post.style-7 .sigma_post-meta ul li i {
  margin-right: 10px;
  font-size: 16px;
}

.sigma_post.style-7 .sigma_post-meta ul li + li:before,
.sigma_post.style-8 .sigma_post-meta ul li + li:before {
  content: none;
}

.sigma_post.style-7 .sigma_post-meta ul li + li,
.sigma_post.style-8 .sigma_post-meta ul li + li {
  padding-left: 0;
  margin-left: 20px;
}

/* Style 8 */
.sigma_post.style-8 {
  border-radius: 0;
  overflow: hidden;
}

.sigma_post .sigma_post-body:before {
  content: none;
}

.sigma_post.style-8 .sigma_post-body .sigma_post-content p {
  margin: 20px 0;
}

.sigma_post.style-8 .sigma_post-body .sigma_post-meta {
  border: 0;
  padding: 0;
}

.sigma_post.style-8 .sigma_post-body .sigma_post-meta ul {
  justify-content: space-between;
}

.sigma_post.style-8 .sigma_post-body .sigma_post-meta ul li span,
.widget.widget-sigma-recent-posts.style-3 .sigma_recent-post .author-info span {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--thm-b-text);
  color: #fff;
  font-size: 15px;
  border-radius: 50%;
  margin-right: 10px;
}

.sigma_post.style-8 .sigma_post-body .sigma_post-meta ul .author-name,
.widget.widget-sigma-recent-posts.style-3 .sigma_recent-post .author-info .author-name {
  color: var(--thm-secondary);
  display: block;
  line-height: 1;
  font-size: 14px;
  font-weight: 500;
}

.sigma_post.style-8 .sigma_post-body .sigma_post-meta ul .author-name:hover,
.widget.widget-sigma-recent-posts.style-3 .sigma_recent-post .author-info .author-name:hover {
  color: var(--thm-base-hover);
}

.sigma_post.style-8 .sigma_post-body .sigma_post-meta ul li:last-child {
  flex-direction: column;
}

/* Style 9 */
.sigma_post.style-9 .sigma_post-body .sigma_post-content,
.sigma_post.style-10 .sigma_post-body .sigma_post-content {
  border-bottom: 1px solid var(--thm-base);
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.sigma_post.style-9 .sigma_post-body .btn-link,
.sigma_post.style-10 .sigma_post-body .btn-link {
  margin-top: 0;
}

/* Style 10 */
.sigma_post.style-10 .sigma_post-meta ul li,
.sigma_post.style-10 .sigma_post-meta ul li a,
.sigma_post.style-10 .sigma_post-body .sigma_post-content h5 a,
.sigma_post.style-10 .sigma_post-body .sigma_post-content .h5 a,
.sigma_post.style-10 .sigma_post-body .sigma_post-content p,
.sigma_post.style-10 .sigma_post-body .btn-link {
  color: #fff;
}

.sigma_post.style-10 .sigma_post-meta ul li + li:before {
  background-color: #fff;
}

.sigma_post.style-10 .sigma_post-meta ul li a:hover,
.sigma_post.style-10 .sigma_post-body .sigma_post-content h5 a:hover,
.sigma_post.style-10 .sigma_post-body .sigma_post-content .h5 a:hover,
.sigma_post.style-10 .sigma_post-body .btn-link:hover {
  color: var(--thm-base-hover);
}

/* Style 11 */
.sigma_post.style-11 .sigma_post-meta .sigma_post-date,
.sigma_post.style-12 .sigma_post-meta .sigma_post-date {
  border: 0;
  padding: 5px 25px;
  background-color: var(--thm-base);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-bottom: 22px;
  position: relative;
  top: 0;
  left: 0;
  line-height: 1.8;
}

.sigma_post.style-11 .sigma_post-meta i {
  color: #fff;
  margin-top: 0;
}

.sigma_post.style-11 .sigma_post-meta ul li + li,
.sigma_post.style-12 .sigma_post-meta ul li + li {
  margin-left: 20px;
}

.sigma_post.style-11 .sigma_post-meta .sigma_post-date:hover,
.sigma_post.style-12 .sigma_post-meta .sigma_post-date:hover {
  background-color: var(--thm-base-hover);
}

.sigma_post.style-11 .sigma_post-meta li a {
  color: #fff;
}

.sigma_post.style-11 .sigma_post-body .sigma_post-content h5, .sigma_post.style-11 .sigma_post-body .sigma_post-content .h5 {
  margin-bottom: 10px;
}

/* Style 12 */
.sigma_post.style-12 .sigma_post-thumb a {
  width: 100%;
}

.sigma_post.style-12 .sigma_post-body {
  margin: -35px 0 0;
  padding: 30px 25px;
  border-bottom: 5px solid var(--thm-base);
}

.sigma_post.style-12 .sigma_post-meta a i {
  color: var(--thm-b-text);
  margin-top: 0;
}

.sigma_post.style-12 .sigma_post-meta .sigma_post-date i {
  color: #fff;
}

.sigma_post.style-12 .sigma_post-meta.is-absolute {
  margin: -52px 0 0;
  position: relative;
}

.sigma_post.style-12 .sigma_post-meta.is-absolute .sigma_post-date {
  margin-bottom: 0;
}

.sigma_post.style-12 .sigma_post-body .sigma_post-content h5, .sigma_post.style-12 .sigma_post-body .sigma_post-content .h5 {
  margin-bottom: 8px;
}

.sigma_post.style-12 .sigma_post-meta ul {
  margin: 20px 0 8px;
}

/* Post-list */
.sigma_post.sigma_post-list {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.sigma_post-list + .sigma_post-list {
  padding-top: 40px;
  margin-top: 40px;
  border-top: 1px solid var(--thm-border);
}

.sigma_post.sigma_post-list .sigma_post-thumb {
  width: 270px;
  margin-right: 30px;
}

.sigma_post.sigma_post-list .sigma_post-thumb img {
  width: auto;
}

.sigma_post.sigma_post-list .sigma_post-body {
  flex: 1;
  padding-top: 0;
}

.sigma_post.sigma_post-list .sigma_post-body .sigma_post-categories {
  /* position: static; */
  margin-bottom: 15px;
}

.sigma_post.sigma_post-list.post-format-audio .embed-responsive-16by9::before {
  padding-top: 100%;
}

/*Blog: Misc*/
.sigma_post h5, .sigma_post .h5 {
  font-size: 34px;
}

.col-lg-6 .sigma_post h5, .col-lg-6 .sigma_post .h5 {
  font-size: 28px;
}

.col-lg-6 .sigma_post.style-7,
.col-lg-4 .sigma_post.style-7,
.col-lg-3 .sigma_post.style-7 {
  margin-bottom: 30px;
}

.sigma_related-posts .sigma_post h5, .sigma_related-posts .sigma_post .h5,
.col-lg-4 .sigma_post h5,
.col-lg-4 .sigma_post .h5,
.col-lg-5 .sigma_post h5,
.col-lg-5 .sigma_post .h5 {
  font-size: 20px;
}

.col-lg-4 .sigma_post.style-6 .sigma_post-body,
.col-lg-4 .sigma_post.style-7 .sigma_post-body,
.col-lg-4 .sigma_post.style-9 .sigma_post-body {
  padding: 20px 0 0;
}

.col-lg-5 .sigma_post.sigma_post-list .sigma_post-thumb {
  width: auto;
}

.col-lg-3 .sigma_post h5, .col-lg-3 .sigma_post .h5 {
  font-size: 22px;
}

.col-lg-3 .sigma_post.post-format-quote blockquote cite span {
  width: 50px;
  height: 50px;
}

.col-lg-4 .sigma_post.post-format-link .sigma_post-body,
.col-lg-3 .sigma_post.post-format-link .sigma_post-body {
  padding: 30px;
}

.col-lg-4 .sigma_post.post-format-quote blockquote,
.col-lg-3 .sigma_post.post-format-quote blockquote {
  padding: 30px;
}

.col-lg-4 .sigma_post.post-format-audio .embed-responsive-16by9::before {
  padding-top: 46.25%;
}

.col-lg-3 .sigma_post.post-format-audio .embed-responsive-16by9::before {
  padding-top: 66.25%;
}

/*------- Formats------------- */
/*  post format gallery */
.sigma_post.post-format-gallery .sigma_post-thumb .slick-prev,
.sigma_post.post-format-gallery .sigma_post-thumb .slick-next {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.sigma_post.post-format-gallery .sigma_post-thumb:hover img {
  transform: scale(1);
}

.sigma_post.post-format-gallery .sigma_post-thumb .slick-next {
  right: 10px;
  left: auto;
}

.sigma_post.post-format-gallery .sigma_post-thumb:hover .slick-prev {
  left: 20px;
  opacity: 1;
  visibility: visible;
}

.sigma_post.post-format-gallery .sigma_post-thumb:hover .slick-next {
  right: 20px;
  opacity: 1;
  visibility: visible;
}

.sigma_post.post-format-gallery .sigma_post-thumb .slick-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

/*  post format Quote */
.sigma_post.post-format-quote blockquote {
  position: relative;
  padding: 60px 50px;
  background-color: var(--thm-secondary);
  border-radius: 6px;
  overflow: hidden;
  margin: 0;
  z-index: 1;
}

.sigma_post.post-format-quote blockquote h5, .sigma_post.post-format-quote blockquote .h5 {
  color: #fff;
}

.sigma_post.post-format-quote blockquote:before {
  content: "";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  font-weight: 600;
  left: 40px;
  top: -10px;
  color: rgba(255, 255, 255, 0.4);
  font-size: 80px;
  z-index: -1;
}

.sigma_post.post-format-quote blockquote p {
  color: #fff;
  font-style: italic;
  margin: 0;
  font-size: 20px;
}

.sigma_post.post-format-quote blockquote cite {
  display: flex;
  align-items: center;
  margin-top: 20px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.sigma_post.post-format-quote blockquote cite span {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: var(--thm-secondary);
  font-size: 15px;
  border-radius: 50%;
  margin-right: 15px;
}

/*  post format Video */
.sigma_post.post-format-video .sigma_video-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/*  post format Audio */
.sigma_post.post-format-audio .embed-responsive-16by9::before {
  padding-top: 16.25%;
}

/*  post format Link */
.sigma_post.post-format-link {
  box-shadow: none;
}

.sigma_post.post-format-link .sigma_post-body {
  background-color: var(--thm-secondary);
  padding: 50px;
  position: relative;
  border-radius: 6px;
}

.sigma_post.post-format-link .sigma_post-content {
  padding: 0;
}

.sigma_post.post-format-link .sigma_post-content p {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.sigma_post.post-format-link .sigma_post-meta {
  margin-bottom: 0;
  padding-bottom: 0;
  border: 0;
}

.sigma_post.post-format-link .sigma_post-meta a {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  margin-top: 20px;
}

.sigma_post.post-format-link .sigma_post-meta a i {
  margin-right: 10px;
  font-size: 20px;
}

.sigma_post.post-format-link .sigma_post-meta a:hover {
  color: var(--thm-base-hover);
}

.sigma_post.post-format-link h5, .sigma_post.post-format-link .h5 {
  color: #fff;
}

/*-------------- Blog Widgets --------------------------- */
.sidebar .widget {
  padding: 30px;
  border-radius: 6px;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.04);
}

.sidebar .widget + .widget {
  margin-top: 50px;
}

.sidebar .widget .widget-title {
  position: relative;
  margin-bottom: 30px;
  font-size: 22px;
}

.sidebar .widget .widget-title::before {
  content: "";
  background-color: var(--thm-base);
  width: 3px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: -30px;
}

.sidebar .widget .btn-link {
  margin-top: 10px;
}

/*== search Widget & banner widget start ==*/
.widget.widget-banner,
.widget.widget-search {
  padding: 0;
  box-shadow: none;
  background-color: transparent;
}

.widget.widget-banner .widget-title::before,
.widget.widget-search .widget-title::before {
  display: none;
}

.widget.widget-blockquote {
  padding: 0;
  border: 0;
  box-shadow: none;
}

.widget.widget-blockquote .blockquote {
  border-radius: 0;
  text-align: left;
}

.widget.widget-blockquote .blockquote p {
  font-size: 14px;
  font-weight: 400;
}

.widget.widget-blockquote .blockquote a {
  margin-top: 20px;
}

/*== category Widget start ==*/
.widget.widget-categories ul li + li,
.widget.widget-sigma-recent-posts.style-3 .sigma_recent-post + .sigma_recent-post,
.widget.widget-sigma-recent-posts.style-4 .sigma_recent-post + .sigma_recent-post {
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid var(--thm-border);
}

.widget.widget-categories ul li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--thm-b-text);
  font-weight: 500;
  position: relative;
  padding-left: 18px;
}

.widget.widget-categories ul li a:hover {
  padding-left: 24px;
}

.widget.widget-categories ul li a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Pro";
}

.widget.widget-categories ul li a:hover {
  color: var(--thm-base);
}

.widget.widget-categories ul li a span {
  color: #fff;
  background-color: var(--thm-base);
  font-size: 14px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  font-weight: 400;
  transition: 0.3s;
}

.widget.widget-categories ul li a span:hover {
  background-color: var(--thm-base-hover);
}

/*== Recent Post Widget start ==*/
.widget.widget-sigma-recent-posts .sigma_recent-post {
  display: flex;
  align-items: center;
}

.widget.widget-sigma-recent-posts .sigma_recent-post + .sigma_recent-post {
  margin-top: 30px;
}

.widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-image {
  position: relative;
  margin-right: 15px;
  display: block;
  border-radius: 14px;
  overflow: hidden;
  transition: 0.3s;
}

.widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-image span {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  border-bottom-left-radius: 14px;
  color: #fff;
  font-size: 14px;
  background-color: var(--thm-base);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  transition: 0.3s;
}

.widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-image:hover span {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr {
  flex: 1;
}

.widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr h6, .widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr .h6 {
  font-size: 14px;
  margin-bottom: 0;
  line-height: 1.4;
}

.widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr h6 a, .widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr .h6 a {
  color: var(--thm-secondary);
}

.widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr h6 a:hover, .widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr .h6 a:hover,
.widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr .date:hover,
.widget.widget-sigma-recent-posts.style-4 .recent-post-image:hover,
.widget.widget-sigma-recent-posts.style-4 .recent-post-descr h6 a:hover span,
.widget.widget-sigma-recent-posts.style-4 .recent-post-descr .h6 a:hover span {
  color: var(--thm-base);
}

.widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr .date {
  color: var(--thm-b-text);
  font-size: 14px;
  display: inline-block;
}

/* Recent Post Style 2 */
.widget.widget-sigma-recent-posts.style-2 .sigma_recent-post {
  flex-direction: column;
  align-items: start;
}

.widget.widget-sigma-recent-posts.style-2 .sigma_recent-post .recent-post-image {
  margin-right: 0;
  margin-bottom: 15px;
}

/* Recent Post Style 3 */
.widget.widget-sigma-recent-posts.style-3 .sigma_recent-post .recent-post-descr h6, .widget.widget-sigma-recent-posts.style-3 .sigma_recent-post .recent-post-descr .h6 {
  margin: 8px 0 16px;
}

.widget.widget-sigma-recent-posts.style-3 .sigma_recent-post {
  display: block;
}

.widget.widget-sigma-recent-posts.style-3 .sigma_recent-post .sigma_post-categories {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.widget.widget-sigma-recent-posts.style-3 .sigma_recent-post .sigma_post-categories a {
  margin-bottom: 8px;
  margin-right: 8px;
}

/* Recent Post Style 4 */
.widget.widget-sigma-recent-posts.style-4 .recent-post-image {
  font-size: 18px;
  color: var(--thm-b-text);
}

.widget.widget-sigma-recent-posts.style-4 .recent-post-descr span {
  color: var(--thm-b-text);
  transition: 0.3s;
  font-weight: 400;
}

/*== Tags Widget start ==*/
.tagcloud a {
  display: inline-block;
  font-size: 14px;
  padding: 6px 15px;
  color: #fff;
  font-weight: 500;
  transition: 0.3s;
  background-color: var(--thm-secondary);
  margin: 6px 6px 0px 0px;
  border-radius: 25px;
}

.tagcloud a:hover {
  background-color: var(--thm-base);
}

/*== Banner Widget start ==*/
.widget.widget-banner .banner-area {
  border-radius: 6px;
  overflow: hidden;
}

/*== Follow Widget start ==*/
.widget.widget-follow .sigma_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  text-transform: capitalize;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
}

.widget.widget-follow .sigma_btn + .sigma_btn {
  margin-top: 15px;
}

.widget.widget-follow .sigma_btn .follow-icon i {
  display: inline-block;
  font-size: 30px;
  margin-right: 0;
  width: 40px;
  font-weight: 400;
}

.widget.widget-follow .sigma_btn .follow-icon p span {
  font-weight: 400;
  font-size: 14px;
  display: block;
  line-height: 1;
}

.widget.widget-follow .sigma_btn i:last-child {
  font-size: 16px;
  margin: 0;
  position: relative;
  transition: 0.3s;
  left: 0;
}

.widget.widget-follow .sigma_btn:hover i:last-child {
  left: 3px;
}

/*== Insta Widget start ==*/
.widget.widget-insta .insta-images {
  margin: 4px 2px 0 2px;
  display: flex;
  flex-wrap: wrap;
}

.widget.widget-insta .insta-images a {
  position: relative;
  margin: 0 2px 4px;
  display: block;
  flex: 25%;
  overflow: hidden;
  border-radius: 0;
}

.widget.widget-insta .insta-images a::before {
  content: "";
  font-family: "Font Awesome 5 Brands";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  background-color: #fff;
  color: var(--thm-secondary);
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
  transition: 0.3s;
  opacity: 0;
  z-index: 2;
  visibility: hidden;
  border-radius: 50%;
}

.widget.widget-insta .insta-images a:hover::before {
  top: 50%;
  opacity: 1;
  visibility: visible;
}

.widget.widget-insta .insta-images a img {
  transition: 0.3s;
}

.widget.widget-insta .insta-images a:hover img {
  transform: scale(1.1);
}

.instagram_gallery {
  display: flex;
  flex-wrap: wrap;
}

/* Sidebar style 2 */
.sidebar-style-2 .sidebar .widget {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}

.sidebar-style-2 .sidebar .widget .widget-title::before {
  display: none;
}

/* Sidebar style 3 */
.sidebar-style-3 .sidebar .widget {
  background-color: #f2f6fa;
  box-shadow: none;
}

.sidebar-style-3 .widget.widget-search {
  padding: 30px;
}

.sidebar-style-3 input[type=number],
.sidebar-style-3 input[type=text],
.sidebar-style-3 input[type=email],
.sidebar-style-3 input[type=password],
.sidebar-style-3 input[type=tel],
.sidebar-style-3 input[type=url],
.sidebar-style-3 input[type=search],
.sidebar-style-3 select,
.sidebar-style-3 textarea,
.sidebar-style-3 .form-control {
  background-color: #fff;
}

/* Sidebar style 4 */
.sidebar-style-4 .sidebar .widget .widget-title {
  padding-left: 50px;
}

.sidebar-style-4 .sidebar .widget .widget-title::before,
.sidebar-style-4 .sidebar .widget .widget-title::after {
  position: absolute;
  top: 50%;
  left: 0;
  height: 3px;
  width: 20px;
  transform: translateY(-50%);
}

.sidebar-style-4 .sidebar .widget .widget-title::after {
  content: "";
  background-color: var(--thm-base);
  width: 10px;
  margin-right: 10px;
  left: 25px;
}

.sidebar-style-4 .sidebar .widget.widget-search .widget-title {
  padding-left: 0;
}

.sidebar-style-4 .sidebar .widget.widget-search .widget-title::after {
  display: none;
}

/* Sidebar style 5 */
.sidebar-style-5 .sidebar .widget .widget-title::before {
  left: 0;
  width: 100px;
  z-index: -1;
  opacity: 0.3;
}

.sidebar-style-5 .sidebar .widget .widget-title {
  padding-left: 25px;
}

/* Sidebar style 6 */
.sidebar-style-6 .sidebar .widget .widget-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-style-6 .sidebar .widget .widget-title::after {
  content: "";
  background-color: var(--thm-base);
  margin-left: 10px;
}

.sidebar-style-6 .sidebar .widget .widget-title::before {
  margin-right: 10px;
}

.sidebar-style-6 .sidebar .widget .widget-title::after,
.sidebar-style-6 .sidebar .widget .widget-title::before {
  position: relative;
  top: auto;
  left: auto;
  height: 3px;
  width: 30px;
}

.sidebar-style-6 .sidebar .widget.widget-search .widget-title::after {
  display: none;
}

/*-------------- Blog details Sections --------------------------- */
.sigma_post-details-categories {
  display: flex;
  align-items: center;
  margin: 0 0 15px;
}

.sigma_post-details-categories a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 15px;
  background-color: var(--thm-base);
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  border-radius: 26px;
  transition: 0.3s;
  margin: 0 5px 5px 0;
}

.sigma_post-details-categories a:hover {
  background-color: var(--thm-base-hover);
}

.sigma_post-details-meta {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.sigma_post-details-meta span {
  display: inline-block;
  margin: 0 20px 5px 0;
  font-family: var(--thm-font);
  font-size: 14px;
  text-transform: uppercase;
}

.sigma_post-details-meta span i {
  margin-right: 5px;
  color: var(--thm-base);
}

.sigma_post-details-meta-item {
  flex: 1;
}

.sigma_post-details-meta-item .sigma_sm {
  display: flex;
  align-items: center;
}

.sigma_post-details-meta-item .sigma_sm li + li {
  margin-left: 15px;
}

.sigma_post-details-meta-item .sigma_sm li a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f2f6fa;
  color: var(--thm-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.sigma_post-details-meta-item .sigma_sm li a:hover {
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_author-about {
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
  padding: 30px;
}

.sigma_author-about img {
  max-width: 150px;
}

.sigma_author-about-content {
  flex: 1;
  margin-left: 20px;
}

.sigma_author-about-content span {
  text-transform: uppercase;
  color: var(--thm-base);
  font-family: var(--thm-font);
  font-size: 18px;
}

.sigma_author-about-content h3, .sigma_author-about-content .h3 {
  font-size: 24px;
}

.sigma_author-about-content p {
  margin: 0;
}

.comments-list ul {
  margin: 0;
}

.comments-list .comment-item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 30px;
  margin-top: 0;
}

.comments-list .comment-item + .comment-item {
  border-top: 1px solid var(--thm-border);
  padding-top: 30px;
}

.comments-list .comment-item:last-child {
  margin-bottom: 0;
}

.comments-list .comment-item img {
  width: 100px;
  margin-right: 20px;
  border-radius: 50%;
}

.comments-list .comment-item .comment-body {
  position: relative;
  flex: 1;
}

.comments-list .comment-item h5, .comments-list .comment-item .h5 {
  margin-bottom: 10px;
}

.comments-list .comment-item span {
  display: inline-block;
  font-size: 13px;
  margin-bottom: 15px;
  color: var(--thm-base);
  font-weight: 600;
}

.comments-list .comment-item p {
  margin-bottom: 0;
}

.comments-list .comment-item .btn-link {
  padding: 10px 25px;
  border: 2px solid var(--thm-border);
  border-radius: 35px;
  font-size: 14px;
  position: absolute;
  top: 0;
  color: #777;
  font-weight: 700;
  font-style: normal;
  cursor: pointer;
  right: 0;
}

.comments-list .comment-item .btn-link:hover {
  background-color: var(--thm-base);
  border-color: var(--thm-base);
  color: #fff;
}

.sigma_single-pagination-item + .sigma_single-pagination-item {
  margin-top: 10px;
}

.sigma_single-pagination-thumb {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--thm-border);
  background-color: #fff;
  border-radius: 0;
  margin-right: 10px;
}

.sigma_single-pagination a {
  display: flex;
  align-items: center;
}

.sigma_single-pagination a span {
  font-size: 14px;
  color: var(--thm-b-text);
  display: block;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.sigma_single-pagination a h6, .sigma_single-pagination a .h6 {
  margin: 0;
  line-height: 1;
}

.sigma_single-pagination-content {
  line-height: 1;
}

.sigma_single-pagination a img {
  max-width: 40px;
}

.sigma_single-pagination-prev,
.sigma_single-pagination-next {
  padding: 10px;
  border-radius: 0;
  background-color: #fff;
  border: 1px solid var(--thm-border);
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
  transition: 0.3s;
}

.sigma_single-pagination-prev:hover,
.sigma_single-pagination-next:hover {
  background-color: #f2f6fa;
}

.sigma_single-pagination i {
  color: #838383;
  margin-left: auto;
  margin-right: 20px;
  transition: 0.3s;
}

.sigma_single-pagination-prev:hover i {
  transform: translateX(-3px);
}

.sigma_single-pagination-next:hover i {
  transform: translateX(3px);
}

/* Style 2 */
.sigma_post-details.style-2 .sigma_post-details-inner {
  border: 2px solid var(--thm-border);
  padding: 30px;
}

.sigma_post-details.style-2 .sigma_post-details-inner .sigma_author-about {
  box-shadow: none;
  border: 2px solid var(--thm-border);
}

.sigma_post-details.style-2 .comments-list .comment-item img {
  border-radius: 0;
}

/* Style 3 */
.sigma_post-details.style-3 {
  text-align: center;
}

.sigma_post-details.style-3 .sigma_post-details-meta-item .sigma_sm,
.sigma_post-details.style-3 .sigma_post-details-categories,
.sigma_post-details.style-3 .sigma_post-details-meta {
  justify-content: center;
}

.sigma_post-details.style-3 .sigma_author-about {
  text-align: left;
}

.sigma_post-details.style-3 .sigma_general-list ul li i {
  display: none;
}

.sigma_post-details.style-3 .comments-list .comment-item .btn-link {
  position: relative;
  top: auto;
  margin-top: 15px;
}

.sigma_post-details.style-3 .comments-list .comment-item {
  display: block;
}

.sigma_post-details.style-3 .comments-list .comment-item img {
  margin: 0 0 15px;
}

.entry-content img {
  margin-bottom: 20px;
}

.blockquote,
.entry-content blockquote {
  position: relative;
  margin: 20px 0 30px;
  color: #fff;
  padding: 10px 20px;
  border-left: 0;
  text-align: center;
  padding: 40px;
  background-position: center;
  background-size: cover;
  background-color: #313131;
  border-radius: 0;
}

.blockquote::before,
.entry-content blockquote::before {
  position: absolute;
  bottom: 0;
  right: 10px;
  font-size: 160px;
  content: "";
  color: #fff;
  opacity: 0.1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.blockquote cite,
.entry-content blockquote cite {
  position: relative;
  font-weight: 600;
  display: block;
  margin: 0 0 10px;
  color: var(--thm-base);
}

.blockquote p,
.entry-content blockquote p {
  position: relative;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}

/*-------------- Services Sections --------------------------- */
/* Services */
.sigma_service {
  display: block;
  background-color: #fff;
  border: 1px solid var(--thm-border);
  padding: 40px;
  text-align: center;
  transition: 0.3s;
  margin-bottom: 30px;
  color: var(--thm-b-text);
  position: relative;
}

.sigma_service.border .sigma_service-thumb {
  position: relative;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: 0 auto 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--thm-border);
}

.sigma_service.border .sigma_service-thumb::before {
  content: "";
  position: absolute;
  top: 10px;
  right: 17px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--thm-base);
  transition: 0.3s;
}

.sigma_service.border .sigma_service-thumb::after {
  content: "";
  position: absolute;
  bottom: 10px;
  left: 17px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--thm-base);
  transition: 0.3s;
}

.sigma_service .sigma_service-thumb img {
  width: 100%;
}

.sigma_service.primary-bg:hover,
.sigma_service.primary-bg {
  background-color: var(--thm-base);
  border: 0;
}

.sigma_service.secondary-bg:hover,
.sigma_service.secondary-bg {
  background-color: var(--thm-secondary);
  border: 0;
}

.sigma_service.border.primary-bg .sigma_service-thumb::after,
.sigma_service.border.primary-bg .sigma_service-thumb::before,
.sigma_service.border.secondary-bg .sigma_service-thumb::after,
.sigma_service.border.secondary-bg .sigma_service-thumb::before {
  background-color: #fff;
}

.sigma_service.border:hover .sigma_service-thumb::after {
  bottom: -4px;
  left: 47px;
}

.sigma_service.border:hover .sigma_service-thumb::before {
  top: -4px;
  right: 47px;
}

.sigma_service.bg-white {
  border: 0;
}

.sigma_service.style-1.bg-white:hover .btn-link,
.sigma_service.style-1.bg-white:hover p,
.sigma_service.style-1.bg-white:hover h5,
.sigma_service.style-1.bg-white:hover .h5 {
  color: var(--thm-secondary);
}

.sigma_service.style-1.bg-white:hover i {
  color: var(--thm-base);
}

.sigma_service i {
  font-size: 60px;
  color: var(--thm-base);
  line-height: 1;
  transition: 0.3s;
}

.sigma_service .btn-link {
  margin-top: 20px;
  display: block;
}

.sigma_service .btn-link i {
  color: var(--thm-secondary);
  margin-left: 5px;
}

.sigma_service-thumb {
  margin-bottom: 40px;
}

.sigma_service h5, .sigma_service .h5 {
  margin-bottom: 10px;
  transition: 0.3s;
}

.sigma_service p {
  margin: 0;
  transition: 0.3s;
}

.sigma_service:hover {
  background-color: var(--thm-base);
  transform: translateY(-3px);
}

.sigma_service.style-1:hover .btn-link,
.sigma_service.style-1:hover i,
.sigma_service.style-1:hover p,
.sigma_service.style-1:hover h5,
.sigma_service.style-1:hover .h5 {
  color: #fff;
}

/* Style 2 & 3 */
.sigma_service.style-2 h5, .sigma_service.style-2 .h5,
.sigma_service.style-6 h5,
.sigma_service.style-6 .h5,
.sigma_service.style-7 h5,
.sigma_service.style-7 .h5,
.sigma_service.style-8 h5,
.sigma_service.style-8 .h5 {
  margin-bottom: 10px;
}

.sigma_service.style-2 i,
.sigma_service.style-6 i.icon-box,
.sigma_service.style-7 .icon-box,
.sigma_service.style-8 .icon-box {
  position: absolute;
  bottom: -30px;
  right: 30px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--thm-b-text);
  background-color: #fff;
  font-size: 24px;
  border-radius: 50%;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
}

.sigma_service.style-2:hover i {
  background-color: #fdb900;
  color: #fff;
}

.sigma_service.style-3,
.sigma_service.style-2,
.sigma_service.style-6,
.sigma_service.style-7,
.sigma_service.style-8 {
  background-color: transparent;
  box-shadow: none;
  text-align: left;
  padding: 0;
}

.sigma_service.style-3 .sigma_service-thumb,
.sigma_service.style-2 .sigma_service-thumb,
.sigma_service.style-6 .sigma_service-thumb,
.sigma_service.style-7 .sigma_service-thumb,
.sigma_service.style-8 .sigma_service-thumb {
  position: relative;
  margin: 0;
  z-index: 1;
}

.sigma_service.style-3 i {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, -50%);
}

.sigma_service.style-3 .sigma_service-thumb::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}

.sigma_service.style-3:hover i,
.sigma_service.style-3:hover .sigma_service-thumb::before,
.sigma_service.style-2:hover i,
.sigma_service.style-2:hover .sigma_service-thumb::before {
  opacity: 1;
  visibility: visible;
}

.sigma_service.style-3 h5, .sigma_service.style-3 .h5 {
  margin-bottom: 20px;
}

.sigma_service.style-3 p,
.sigma_service.style-2 p,
.sigma_service.style-6 p,
.sigma_service.style-7 p,
.sigma_service.style-8 p {
  margin: 0;
}

.sigma_service.style-3 .sigma_service-body,
.sigma_service.style-2 .sigma_service-body {
  padding: 30px;
}

.sigma_service.style-3,
.sigma_service.style-6,
.sigma_service.style-7,
.sigma_service.style-8 {
  border: 0;
}

.sigma_service.style-3 .sigma_service-body {
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
  border: 0;
}

.sigma_service.style-3 .sigma_service-body::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: var(--thm-base);
  transition: 0.3s;
}

.sigma_service.style-3:hover .sigma_service-body::before {
  width: 100%;
}

/* Style 4 */
.sigma_service.style-4,
.sigma_service.style-9 {
  text-align: left;
}

.sigma_service.style-4 .sigma_service-footer {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid var(--thm-border);
}

.sigma_service.style-4 i,
.sigma_service.style-9 i {
  margin-bottom: 20px;
  display: block;
}

.sigma_service.style-4 .sigma_service-footer ul {
  margin: 0;
}

.sigma_service.style-4 .sigma_service-footer ul li {
  position: relative;
  color: var(--thm-b-text);
  display: flex;
  font-size: 14px;
  align-items: center;
  margin: 0;
}

.sigma_service.style-4 .sigma_service-footer ul li + li {
  margin-top: 10px;
}

.sigma_service.style-4 .sigma_service-footer ul li::before {
  content: "-";
  margin-right: 10px;
  transition: 0.3s;
  color: var(--thm-base);
  display: block;
}

.sigma_service.style-4:hover * {
  color: #fff;
}

.sigma_service.style-4:hover .sigma_service-footer ul li {
  color: #fff;
}

.sigma_service.style-4:hover .sigma_service-footer ul li::before {
  color: #fff;
}

.sigma_service.style-4.dark,
.sigma_service.style-9.dark {
  background-color: #1bb56a;
  border-color: rgba(27, 181, 106, 0.1);
}

.sigma_service.style-4.dark .sigma_service-footer {
  border-top-color: rgba(255, 255, 255, 0.1);
}

.sigma_service.style-4.dark i,
.sigma_service.style-9.dark i {
  color: var(--thm-base);
  font-size: 5rem;
}

.sigma_service.style-4.dark h5, .sigma_service.style-4.dark .h5,
.sigma_service.style-9.dark h5,
.sigma_service.style-9.dark .h5 {
  color: #fff;
}

.sigma_service.style-4.dark *,
.sigma_service.style-9.dark * {
  color: #fff;
}

.sigma_service.style-9.dark .sigma_service-body {
  text-shadow: 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.2);
}
.sigma_service.style-9.dark .sigma_service-body * {
  font-size: 1.2rem;
}
.sigma_service.style-9.dark .sigma_service-body .btn-link {
  font-size: 1rem;
  font-weight: 500;
}

.sigma_service.style-4.dark .sigma_service-footer ul li {
  color: #999b9f;
}

/* Style 5 */
.sigma_service.style-5 {
  text-align: left;
  padding: 30px;
}

.sigma_service.style-5:hover {
  background-color: transparent;
}

.sigma_service.style-5 > i {
  display: block;
  margin-bottom: 20px;
}

.sigma_service.style-5 span {
  color: #777;
}

.sigma_service.style-5 h5, .sigma_service.style-5 .h5 {
  font-size: 22px;
}

.sigma_service.style-5 .sigma_service-link i {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #313131;
  box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
  margin-top: 50px;
  transition: 0.3s;
}

.sigma_service.style-5:hover .sigma_service-link i {
  background-color: #fdb900;
  color: #fff;
}

/* Style 6 */
.sigma_service.style-6,
.sigma_service.style-7 {
  background-color: #fff;
  padding: 25px;
}

.sigma_service.style-6:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 126px;
  width: 4px;
  height: 68px;
  background-color: var(--thm-base);
  transition: 0.3s;
}

.sigma_service.style-6 .sigma_service-body {
  padding-top: 25px;
}

.sigma_service.style-6 .sigma_service-body h5 a, .sigma_service.style-6 .sigma_service-body .h5 a,
.sigma_service.style-7 .sigma_service-body h5 a,
.sigma_service.style-7 .sigma_service-body .h5 a,
.sigma_service.style-8 .sigma_service-body h5 a,
.sigma_service.style-8 .sigma_service-body .h5 a {
  color: var(--thm-secondary);
}

.sigma_service.style-6 .sigma_service-body h5 a:hover, .sigma_service.style-6 .sigma_service-body .h5 a:hover,
.sigma_service.style-7 .sigma_service-body h5 a:hover,
.sigma_service.style-7 .sigma_service-body .h5 a:hover,
.sigma_service.style-8 .sigma_service-body h5 a:hover,
.sigma_service.style-8 .sigma_service-body .h5 a:hover,
.sigma_service.style-9 .sigma_service-body h5 a:hover,
.sigma_service.style-9 .sigma_service-body .h5 a:hover {
  color: var(--thm-base-hover);
}

.sigma_service.style-6 .sigma_service-body .btn-link,
.sigma_service.style-8 .sigma_service-body .btn-link,
.sigma_service.style-9 .sigma_service-body .btn-link {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.sigma_service.style-6 i.icon-box,
.sigma_service.style-7 .icon-box,
.sigma_service.style-8 .icon-box {
  border-radius: 0;
  background-color: var(--thm-base);
  color: #fff;
  width: 70px;
  height: 70px;
  bottom: auto;
  top: 30px;
  right: 0;
  font-size: 36px;
}

.sigma_service.style-6 .sigma_service-body .btn-link i,
.sigma_service.style-8 .sigma_service-body .btn-link i,
.sigma_service.style-9 .sigma_service-body .btn-link i {
  font-size: 13px;
  margin-left: 10px;
}

.sigma_service.style-6 .sigma_service-body .btn-link:hover i,
.sigma_service.style-8 .sigma_service-body .btn-link:hover i,
.sigma_service.style-9 .sigma_service-body .btn-link:hover i {
  margin-left: 13px;
}

/* Style 7 */
.sigma_service.style-7 .sigma_service-body {
  padding-bottom: 25px;
}

.sigma_service.style-7 .icon-box {
  background-color: transparent;
  border: 1px solid var(--thm-border);
  margin-bottom: 22px;
  position: relative;
  top: 0;
  right: auto;
  left: 0;
  box-shadow: 0 0 16px 0px rgba(0, 0, 0, 0.03);
  color: var(--thm-secondary);
}

.sigma_service.style-7 .icon-box span {
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  background-color: var(--thm-base);
  width: 25px;
  height: 25px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Style 8 */
.sigma_service.style-8 {
  background-color: #fff;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.08);
}

.sigma_service.style-8 .sigma_service-body .btn-link i {
  color: var(--thm-base);
}

.sigma_service.style-8 .sigma_service-body {
  padding: 60px 30px 30px;
}

.sigma_service.style-8 .icon-box {
  bottom: -30px;
  top: auto;
  right: auto;
  left: 30px;
  font-weight: 600;
}

.sigma_service-wrapper {
  position: relative;
  margin-bottom: -250px;
}

/* Style 9 */
.sigma_service.style-9 {
  border: 0;
}

.sigma_service.style-9:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 0;
  background-color: var(--thm-base);
  transition: 0.3s;
}

.sigma_service.style-9:hover:before {
  height: 100%;
}

.sigma_service.style-9.dark span {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 75px;
  display: block;
  line-height: 1;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.2);
}

.sigma_service.style-9 .sigma_service-body h5 a, .sigma_service.style-9 .sigma_service-body .h5 a {
  color: #fff;
}

.sigma_service.style-9 .sigma_service-body .btn-link,
.sigma_service.style-9 .sigma_service-body .btn-link i {
  color: #fff;
  margin-bottom: 0;
}

.sigma_service.style-9 .sigma_service-body .btn-link:hover {
  color: var(--thm-base);
}

.sigma_counter-wrapper.with-service {
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 8px;
}

.sigma_counter-wrapper.with-service .sigma_counter.style-3 {
  margin-top: 12px;
  padding: 12px 0 0;
}

/*-------------- Portfolio Sections --------------------------- */
/* Style 1 */
.sigma_portfolio {
  position: relative;
  margin-bottom: 30px;
  cursor: pointer;
}

.sigma_portfolio .sigma_portfolio-thumb {
  position: relative;
  overflow: hidden;
  transition: 0.3s;
}

.sigma_portfolio .sigma_portfolio-thumb img {
  width: 100%;
  transition: 0.3s;
}

.sigma_portfolio .sigma_portfolio-thumb:hover img {
  transform: scale(1.1);
}

.sigma_portfolio .sigma_portfolio-content {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 20px;
  transition: 0.3s;
}

.sigma_portfolio a.sigma_portfolio-category {
  color: var(--thm-base);
  font-weight: 500;
}

.sigma_portfolio .sigma_portfolio-categories a + a {
  margin-left: 10px;
}

.sigma_portfolio .sigma_portfolio-content h5, .sigma_portfolio .sigma_portfolio-content .h5 {
  margin-top: 8px;
  margin-bottom: 0;
}

.sigma_portfolio .sigma_portfolio-content h5 a, .sigma_portfolio .sigma_portfolio-content .h5 a {
  color: #fff;
}

.sigma_portfolio a.sigma_portfolio-category:hover {
  color: var(--thm-base-hover);
}

.sigma_portfolio .sigma_portfolio-link {
  position: absolute;
  bottom: 10px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: var(--thm-base);
  font-size: 16px;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}

.sigma_portfolio .sigma_portfolio-link:hover {
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_portfolio .sigma_portfolio-thumb:hover .sigma_portfolio-link {
  opacity: 1;
  visibility: visible;
  bottom: 20px;
}

/* Style 2 */
.sigma_portfolio.style-2 .sigma_portfolio-content {
  position: relative;
  align-items: center;
  background-color: transparent;
  padding: 20px 0 0 0;
}

.sigma_portfolio.style-2 .sigma_portfolio-content h5, .sigma_portfolio.style-2 .sigma_portfolio-content .h5 {
  margin-top: 0;
  margin-bottom: 8px;
}

.sigma_portfolio.style-2 .sigma_portfolio-content h5 a, .sigma_portfolio.style-2 .sigma_portfolio-content .h5 a {
  color: var(--thm-secondary);
}

.sigma_portfolio.style-2 .sigma_portfolio-content h5 a:hover, .sigma_portfolio.style-2 .sigma_portfolio-content .h5 a:hover {
  color: var(--thm-base-hover);
}

.sigma_portfolio.style-2 .sigma_portfolio-thumb.has-max-width {
  max-width: 400px;
  margin: 0 auto;
}

/* Style 3 (slider version) */
.sigma_portfolio.style-3 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}

.sigma_portfolio.style-3 .sigma_portfolio-content {
  flex: 1;
  position: relative;
  padding: 0;
  max-width: 500px;
  margin-left: 60px;
  background-color: transparent;
}

.sigma_portfolio.style-3 .sigma_portfolio-content p {
  margin: 0;
}

.sigma_portfolio.style-3 .sigma_portfolio-content h5 a, .sigma_portfolio.style-3 .sigma_portfolio-content .h5 a {
  color: var(--thm-secondary);
}

.sigma_portfolio.style-3 .sigma_portfolio-content h5 a:hover, .sigma_portfolio.style-3 .sigma_portfolio-content .h5 a:hover {
  color: var(--thm-base-hover);
}

.sigma_portfolio.style-3 .sigma_portfolio-content .btn-link {
  margin-top: 20px;
}

.sigma_portfolio-slider .slick-prev,
.sigma_portfolio-slider .slick-next {
  background-color: var(--thm-base);
  color: #fff;
  position: absolute;
  right: 0;
  top: calc(50% - 30px);
  transform: translateY(-50%);
  opacity: 1;
  visibility: visible;
}

.sigma_portfolio-slider .slick-next {
  top: calc(50% + 30px);
}

.sigma_portfolio-slider .slick-prev:hover,
.sigma_portfolio-slider .slick-next:hover,
.sigma_portfolio-slider-2 .slick-prev:hover,
.sigma_portfolio-slider-2 .slick-next:hover {
  background-color: var(--thm-base-hover);
}

.sigma_portfolio-slider .slick-prev:before {
  content: "";
}

.sigma_portfolio-slider .slick-next:before {
  content: "";
}

.sigma_portfolio-slider .slick-dots {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  flex-direction: column;
}

.sigma_portfolio-slider .slick-dots li button,
.sigma_portfolio-slider-2 .slick-dots li button {
  background-color: var(--thm-border);
}

.sigma_portfolio-slider .slick-dots .slick-active button,
.sigma_portfolio-slider-2 .slick-dots .slick-active button {
  background-color: var(--thm-base);
}

.sigma_portfolio-slider .slick-dots li + li {
  margin-left: 0;
}

/* Style 4 (slider version) */
.sigma_portfolio.style-4 .sigma_portfolio-content {
  height: auto;
}

.sigma_portfolio-slider-2.slick-slider .slick-list {
  overflow: visible;
}

.sigma_portfolio-slider-2 .sigma_portfolio.style-4 {
  opacity: 0.7;
}

.sigma_portfolio-slider-2 .sigma_portfolio.style-4.slick-current {
  opacity: 1;
}

.sigma_portfolio.style-4 .sigma_portfolio-content {
  background-color: var(--thm-base);
}

.sigma_portfolio.style-4 a.sigma_portfolio-category {
  color: #fff;
}

.sigma_portfolio.style-4 .sigma_portfolio-content h5 a:hover, .sigma_portfolio.style-4 .sigma_portfolio-content .h5 a:hover,
.sigma_portfolio.style-4 a.sigma_portfolio-category:hover {
  color: var(--thm-secondary);
}

.sigma_portfolio.style-4 .sigma_portfolio-link:hover {
  background-color: var(--thm-base-hover);
}

/* Style 5 */
.sigma_portfolio.style-5 {
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
  background-color: #fff;
  border-radius: 0;
  overflow: hidden;
}

.sigma_portfolio.style-5 .sigma_portfolio-content {
  position: relative;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: #fff;
  padding: 15px 20px;
}

.sigma_portfolio.style-5 .sigma_portfolio-content h5 a, .sigma_portfolio.style-5 .sigma_portfolio-content .h5 a {
  color: var(--thm-secondary);
}

.sigma_portfolio.style-5 a.sigma_portfolio-category {
  color: var(--thm-b-text);
}

.sigma_portfolio.style-5 .sigma_portfolio-content h5 a:hover, .sigma_portfolio.style-5 .sigma_portfolio-content .h5 a:hover,
.sigma_portfolio.style-5 a.sigma_portfolio-category:hover {
  color: var(--thm-base-hover);
}

.sigma_portfolio.style-5 .sigma_portfolio-link {
  position: relative;
  bottom: 0;
  right: 0;
  background-color: var(--thm-base);
  color: #fff;
  opacity: 1;
  visibility: visible;
}

.sigma_portfolio.style-5 .sigma_portfolio-link:hover {
  background-color: var(--thm-base-hover);
}

/* Style 6 */
.sigma_portfolio.style-6 {
  margin-bottom: 0;
}

.sigma_portfolio.style-6 .sigma_portfolio-content {
  opacity: 0;
  visibility: hidden;
}

.sigma_portfolio.style-6:hover .sigma_portfolio-content {
  opacity: 1;
  visibility: visible;
}

/* Style 7 */
.sigma_portfolio.style-7 .sigma_portfolio-content {
  height: auto;
  padding: 12px 20px;
}

/* Isotope filter */
/* bordered filter items */
.sigma_isotope-filter-items {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 0 50px 0;
  padding: 0;
}

.sigma_isotope-filter-items .sigma_isotope-trigger {
  padding-bottom: 15px;
  min-width: 120px;
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  border-bottom: 1px solid var(--thm-border);
  cursor: pointer;
  transition: 0.3s;
}

.sigma_isotope-filter-items .sigma_isotope-trigger.active,
.sigma_isotope-filter-items .sigma_isotope-trigger:hover {
  color: var(--thm-base);
  border-color: var(--thm-base);
}

.sigma_isotope-filter-items .sigma_isotope-trigger + .sigma_isotope-trigger {
  margin-left: 40px;
}

/* background filter items */
.sigma_isotope-filter-items.has-background .sigma_isotope-trigger,
.sigma_isotope-filter-items.has-round-border .sigma_isotope-trigger {
  padding: 10px 20px;
  color: #fff;
  border: none;
  background-color: var(--thm-base);
}

.sigma_isotope-filter-items.has-background .sigma_isotope-trigger.active,
.sigma_isotope-filter-items.has-background .sigma_isotope-trigger:hover,
.sigma_isotope-filter-items.has-round-border .sigma_isotope-trigger.active,
.sigma_isotope-filter-items.has-round-border .sigma_isotope-trigger:hover {
  background-color: var(--thm-base-hover);
}

/* round border filter items */
.sigma_isotope-filter-items.has-round-border .sigma_isotope-trigger {
  border-radius: 35px;
}

/* Style 8 */
.sigma_portfolio.style-8 .sigma_portfolio-content,
.sigma_portfolio.style-9 .sigma_portfolio-content,
.sigma_portfolio.style-10 .sigma_portfolio-content {
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 20px);
  height: auto;
  background-color: var(--thm-base);
  padding: 30px;
  opacity: 0;
  visibility: hidden;
}

.sigma_portfolio.style-8:hover .sigma_portfolio-content {
  opacity: 1;
  visibility: visible;
  bottom: 10px;
}

.sigma_portfolio.style-8 .sigma_portfolio-content h5, .sigma_portfolio.style-8 .sigma_portfolio-content .h5,
.sigma_portfolio.style-9 .sigma_portfolio-content h5,
.sigma_portfolio.style-9 .sigma_portfolio-content .h5,
.sigma_portfolio.style-10 .sigma_portfolio-content h5,
.sigma_portfolio.style-10 .sigma_portfolio-content .h5 {
  margin-bottom: 8px;
  margin-top: 0;
}

.sigma_portfolio.style-8 .sigma_portfolio-content p {
  margin-bottom: 0;
  color: #fff;
}

/* Style 9 */
.sigma_portfolio-wrapper {
  position: relative;
  margin-bottom: -250px;
}

.sigma_portfolio-slider-3 .slick-slide,
.sigma_portfolio-slider-4 .slick-slide {
  margin: 0 10px;
}

.sigma_portfolio-slider-3 .slick-list,
.sigma_portfolio-slider-4 .slick-list {
  margin: 0 -10px;
  overflow: visible;
}

.sigma_portfolio-wrapper .sigma_custom-arrows i {
  background-color: #fff;
  border-radius: 6px;
}

.sigma_portfolio-wrapper .sigma_custom-arrows a {
  color: #fff;
  text-decoration: underline;
  margin-right: 20px;
}

.sigma_portfolio-wrapper .sigma_custom-arrows a:hover {
  color: var(--thm-base);
}

.sigma_portfolio.style-9 .sigma_portfolio-thumb,
.sigma_portfolio.style-10 .sigma_portfolio-thumb {
  overflow: visible;
}

.sigma_portfolio.style-9 .sigma_portfolio-thumb:hover img,
.sigma_portfolio.style-10 .sigma_portfolio-thumb:hover img {
  transform: none;
}

.sigma_portfolio.style-9 .sigma_portfolio-content,
.sigma_portfolio.style-10 .sigma_portfolio-content {
  background-color: #fff;
  width: calc(100% - 60px);
  border-bottom: 4px solid var(--thm-base);
  bottom: -60px;
}

.sigma_portfolio.style-9.slick-current .sigma_portfolio-content {
  opacity: 1;
  visibility: visible;
  bottom: -50px;
}

.sigma_portfolio.style-9 .sigma_portfolio-content h5 a, .sigma_portfolio.style-9 .sigma_portfolio-content .h5 a,
.sigma_portfolio.style-10 .sigma_portfolio-content h5 a,
.sigma_portfolio.style-10 .sigma_portfolio-content .h5 a {
  color: var(--thm-secondary);
}

.sigma_portfolio.style-9 .sigma_portfolio-content h5 a:hover, .sigma_portfolio.style-9 .sigma_portfolio-content .h5 a:hover,
.sigma_portfolio.style-10 .sigma_portfolio-content h5 a:hover,
.sigma_portfolio.style-10 .sigma_portfolio-content .h5 a:hover {
  color: var(--thm-base-hover);
}

.sigma_portfolio.style-9 .sigma_portfolio-content p,
.sigma_portfolio.style-10 .sigma_portfolio-content p {
  color: var(--thm-text);
  margin-bottom: 12px;
}

.sigma_portfolio.style-9 .sigma_portfolio-content .icon-box {
  font-size: 32px;
  color: var(--thm-secondary);
  display: block;
  margin-bottom: 20px;
}

.sigma_portfolio.style-9 .sigma_portfolio-content .btn-link {
  color: var(--thm-secondary);
}

.sigma_portfolio.style-9 .sigma_portfolio-content .btn-link:hover {
  color: var(--thm-base-hover);
}

.sigma_portfolio-slider-4 .slick-list {
  overflow: hidden;
}

/* Style 10 */
.sigma_portfolio.style-10 {
  margin-bottom: 90px;
}

.sigma_portfolio.style-10 .sigma_portfolio-content p {
  margin-bottom: 0;
}

.sigma_portfolio.style-10 .sigma_portfolio-content {
  opacity: 1;
  visibility: visible;
}

/*-------------- Team Sections --------------------------- */
/* style 1 */
.sigma_team {
  position: relative;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
  margin-bottom: 30px;
  text-align: center;
  padding: 30px;
  border-radius: 0;
  overflow: hidden;
}

.sigma_team .sigma_team-thumb {
  position: relative;
  overflow: hidden;
}

.sigma_team .sigma_team-thumb.has-width {
  width: 120px;
  height: 120px;
  margin: 0 auto;
}

.sigma_team .sigma_team-thumb img {
  transition: 0.3s;
  width: 100%;
}

.sigma_team .sigma_team-thumb:hover img {
  transform: scale(1.1);
}

.sigma_team .sigma_team-thumb.has-width:hover img {
  transform: none;
}

.sigma_team .sigma_team-body {
  margin-top: 20px;
}

.sigma_team .sigma_team-body h5, .sigma_team .sigma_team-body .h5 {
  margin-bottom: 0;
}

.sigma_team .sigma_team-categories,
.sigma_team .sigma_team-body p {
  margin-top: 10px;
  margin-bottom: 0;
}

.sigma_team .sigma_team-categories a + a {
  margin-left: 10px;
}

.sigma_team .sigma_team-categories a {
  color: var(--thm-b-text);
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
}

.sigma_team .sigma_team-categories a:hover {
  color: var(--thm-base-hover);
}

.sigma_social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.sigma_social-icons li + li {
  margin-left: 10px;
}

.sigma_social-icons li a {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--thm-base);
  background-color: #f4f5f8;
  font-size: 15px;
  border-radius: 50%;
  transition: 0.3s;
}

.sigma_social-icons li a:hover {
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_team .sigma_social-icons {
  margin-top: 18px;
}

/* style 2 */
.sigma_team.style-2,
.sigma_team.style-4,
.sigma_team.style-5,
.sigma_team.style-6,
.sigma_team.style-8,
.sigma_team.style-9,
.sigma_team.style-10 {
  box-shadow: none;
  text-align: left;
  padding: 0;
}

.sigma_team.style-2 .sigma_social-icons,
.sigma_team.style-8 .sigma_social-icons {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  right: 0;
  top: 0;
  margin-top: 0;
  opacity: 0;
  visibility: hidden;
}

.sigma_team.style-2 .sigma_social-icons li a,
.sigma_team.style-8 .sigma_social-icons li a {
  color: #fff;
  background-color: var(--thm-base);
  position: relative;
  bottom: -10px;
  opacity: 0;
  visibility: hidden;
}

.sigma_team.style-2 .sigma_social-icons li a:hover,
.sigma_team.style-4 .sigma_social-icons li a:hover,
.sigma_team.style-6 .sigma_social-icons li a:hover,
.sigma_team.style-8 .sigma_social-icons li a:hover,
.sigma_team.style-9 .sigma_social-icons li a:hover {
  color: var(--thm-secondary);
  background-color: #fff;
}

.sigma_team.style-2 .sigma_team-thumb:hover .sigma_social-icons,
.sigma_team.style-8 .sigma_team-thumb:hover .sigma_social-icons {
  opacity: 1;
  visibility: visible;
}

.sigma_team.style-2 .sigma_team-thumb:hover .sigma_social-icons li a,
.sigma_team.style-8 .sigma_team-thumb:hover .sigma_social-icons li a {
  opacity: 1;
  visibility: visible;
  bottom: 10px;
}

.sigma_team.style-2 .sigma_team-thumb:hover .sigma_social-icons li:nth-child(2) a,
.sigma_team.style-8 .sigma_team-thumb:hover .sigma_social-icons li:nth-child(2) a {
  transition: bottom 0.8s, background-color 0.3s, color 0.3s;
}

.sigma_team.style-2 .sigma_team-thumb:hover .sigma_social-icons li:nth-child(3) a,
.sigma_team.style-8 .sigma_team-thumb:hover .sigma_social-icons li:nth-child(3) a {
  transition: bottom 1.2s, background-color 0.3s, color 0.3s;
}

/* style 3 */
.sigma_team.style-3,
.sigma_team.style-5 {
  text-align: left;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
}

.sigma_team.style-3 .sigma_team-body,
.sigma_team.style-5 .sigma_team-body {
  flex: 1;
  margin: 0;
  padding: 0 20px;
}

.sigma_team.style-3 .sigma_social-icons,
.sigma_team.style-4 .sigma_social-icons,
.sigma_team.style-5 .sigma_social-icons,
.sigma_team.style-6 .sigma_social-icons,
.sigma_team.style-9 .sigma_social-icons,
.sigma_team.style-10 .sigma_social-icons {
  justify-content: flex-start;
}

/* style 4 */
.sigma_team.style-4 .sigma_team-body,
.sigma_team.style-6 .sigma_team-body,
.sigma_team.style-9 .sigma_team-body {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.4);
  margin-top: 0;
  padding: 30px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.sigma_team.style-4 .sigma_team-thumb:hover .sigma_team-body,
.sigma_team.style-6 .sigma_team-thumb:hover .sigma_team-body {
  opacity: 1;
  visibility: visible;
}

.sigma_team.style-4 .sigma_team-body .sigma_team-title,
.sigma_team.style-6 .sigma_team-body .sigma_team-title,
.sigma_team.style-4 .sigma_social-icons,
.sigma_team.style-6 .sigma_social-icons,
.sigma_team.style-6 .sigma_team-body p {
  position: relative;
  transition: 0.3s;
}

.sigma_team.style-4 .sigma_team-body .sigma_team-title,
.sigma_team.style-6 .sigma_team-body .sigma_team-title {
  top: -5px;
}

.sigma_team.style-4 .sigma_team-thumb:hover .sigma_team-title,
.sigma_team.style-6 .sigma_team-thumb:hover .sigma_team-title {
  top: 5px;
}

.sigma_team.style-4 .sigma_team-body h5 a, .sigma_team.style-4 .sigma_team-body .h5 a,
.sigma_team.style-9 .sigma_team-body h5 a,
.sigma_team.style-9 .sigma_team-body .h5 a,
.sigma_team.style-6 .sigma_team-body h5 a,
.sigma_team.style-6 .sigma_team-body .h5 a,
.sigma_team.style-4 .sigma_team-categories a,
.sigma_team.style-9 .sigma_team-categories a,
.sigma_team.style-6 .sigma_team-categories a {
  color: #fff;
}

.sigma_team.style-4 .sigma_team-body h5 a:hover, .sigma_team.style-4 .sigma_team-body .h5 a:hover,
.sigma_team.style-4 .sigma_team-categories a:hover,
.sigma_team.style-9 .sigma_team-body h5 a:hover,
.sigma_team.style-9 .sigma_team-body .h5 a:hover,
.sigma_team.style-9 .sigma_team-categories a:hover,
.sigma_team.style-6 .sigma_team-body h5 a:hover,
.sigma_team.style-6 .sigma_team-body .h5 a:hover,
.sigma_team.style-6 .sigma_team-categories a:hover {
  color: var(--thm-base-hover);
}

.sigma_team.style-4 .sigma_social-icons,
.sigma_team.style-6 .sigma_social-icons {
  bottom: -5px;
}

.sigma_team.style-4 .sigma_team-thumb:hover .sigma_social-icons,
.sigma_team.style-6 .sigma_team-thumb:hover .sigma_social-icons {
  bottom: 5px;
}

.sigma_team.style-4 .sigma_social-icons li a,
.sigma_team.style-9 .sigma_social-icons li a,
.sigma_team.style-6 .sigma_social-icons li a {
  color: #fff;
  background-color: var(--thm-base);
}

/* style 5 */
.sigma_team.style-5 .sigma_team-thumb img {
  width: auto;
}

.sigma_team.style-5 .sigma_team-thumb:hover img {
  transform: none;
}

/* style 6 */
.sigma_team.style-6 .sigma_team-body {
  background-color: rgba(var(--thm-base-rgb), 0.4);
  justify-content: center;
}

.sigma_team.style-6 .sigma_team-body p {
  color: #fff;
}

/* style 7 */
.sigma_team.style-7,
.sigma_team.style-11 {
  box-shadow: none;
  padding: 0;
  border: 1px solid var(--thm-border);
}

.sigma_team.style-7 .sigma_team-body,
.sigma_team.style-11 .sigma_team-body {
  margin-top: 0;
  padding: 30px;
}

/* style 8 */
.sigma_team.style-8 .sigma_team-body {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 20px;
  background-color: var(--thm-base);
}

.sigma_team.style-8 .sigma_team-body h5 a, .sigma_team.style-8 .sigma_team-body .h5 a {
  color: #fff;
}

.sigma_team.style-8 .sigma_team-categories {
  margin-top: 0;
}

.sigma_team.style-8 .sigma_team-categories a {
  color: #fff;
  position: relative;
  padding-left: 22px;
  margin-left: 10px;
}

.sigma_team.style-8 .sigma_team-body h5 a:hover, .sigma_team.style-8 .sigma_team-body .h5 a:hover,
.sigma_team.style-8 .sigma_team-categories a:hover {
  color: var(--thm-secondary);
}

.sigma_team.style-8 .sigma_team-categories a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 2px;
  background-color: #fff;
}

/* style 9 */
.sigma_team.style-9 .sigma_team-body {
  opacity: 1;
  visibility: visible;
}

/* style 10 */
.sigma_team.style-10 {
  padding: 0 30px;
  margin-bottom: 0;
}

.sigma_team.style-10 .sigma_team-body {
  margin-top: 0;
}

.nav-tabs-nulled.nav-tabs {
  border: 0;
}

.nav-tabs-nulled .nav-link.active {
  border: 0;
}

.nav-tabs-nulled .nav-link .sigma_team {
  border: 1px solid transparent;
}

.nav-tabs-nulled .nav-link.active .sigma_team {
  border: 1px solid var(--thm-base);
}

.nav-tabs-nulled .nav-link {
  border: 0;
  border-radius: 0;
  padding: 0;
}

.nav-tabs-nulled .nav-item {
  margin: 0;
}

/* style 11 */
.sigma_team.style-11 {
  border: 0;
  overflow: visible;
}

.sigma_team.style-11 .sigma_team-thumb:hover img {
  transform: none;
  overflow: visible;
}

.sigma_team.style-11 .sigma_team-thumb {
  overflow: visible;
}

.sigma_team.style-11 .sigma_team-body {
  z-index: 1;
  position: relative;
  display: block;
  width: calc(100% - 20px);
  background-color: #fff;
  border: 1px solid var(--thm-border);
  text-align: left;
  padding: 20px;
  margin-top: -50px;
}

.sigma_team-slider .slick-slide {
  margin: 0 15px;
}

.sigma_team-slider .slick-list {
  margin: 0 -15px;
  min-height: 320px;
}

.sigma_team-slider .slick-slide img {
  display: inline-block;
}

/*-------------- Testimonial Sections --------------------------- */
/* style 1 */
.sigma_testimonial {
  position: relative;
  border: 1px solid var(--thm-border);
  padding: 30px;
  text-align: center;
  border-radius: 0;
  margin-bottom: 30px;
}

.sigma_testimonial .sigma_testimonial-thumb {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sigma_testimonial .sigma_testimonial-thumb img {
  transition: 0.3s;
  border-radius: 50%;
}

.sigma_testimonial .sigma_testimonial-body .sigma_testimonial-icon {
  display: inline-block;
  color: var(--thm-secondary);
  opacity: 0.1;
  font-size: 30px;
  margin-top: 30px;
}

.sigma_testimonial .sigma_testimonial-category {
  color: var(--thm-base);
  font-weight: 400;
  text-transform: uppercase;
}

.sigma_testimonial .sigma_author-block h5, .sigma_testimonial .sigma_author-block .h5 {
  margin-bottom: 0;
}

.sigma_testimonial .sigma_testimonial-body p {
  margin: 18px 0 15px;
}

.sigma_testimonial .sigma_testimonial-body .sigma_rating {
  margin: 18px 0 0;
  justify-content: center;
}

/* style 2 */
.sigma_testimonial.style-2,
.sigma_testimonial.style-3,
.sigma_testimonial.style-10,
.sigma_testimonial.style-11 {
  border: 0;
  text-align: left;
  padding: 0;
}

.sigma_testimonial.style-2 .sigma_testimonial-body .sigma_testimonial-icon,
.sigma_testimonial.style-3 .sigma_testimonial-body .sigma_testimonial-icon,
.sigma_testimonial.style-10 .sigma_testimonial-body .sigma_testimonial-icon,
.sigma_testimonial.style-11 .sigma_testimonial-body .sigma_testimonial-icon {
  margin-top: 0;
  font-size: 40px;
}

.sigma_testimonial.style-2 .sigma_testimonial-body p,
.sigma_testimonial.style-10 .sigma_testimonial-body p,
.sigma_testimonial.style-11 .sigma_testimonial-body p {
  margin: 18px 0 20px;
}

.sigma_testimonial.style-2 .sigma_testimonial-thumb,
.sigma_testimonial.style-4 .sigma_testimonial-thumb,
.sigma_testimonial.style-9 .sigma_testimonial-thumb {
  margin-right: 10px;
}

/* style 3 */
.sigma_testimonial.style-3 .sigma_testimonial-body {
  padding: 30px;
  border: 1px solid var(--thm-border);
  margin-bottom: 30px;
  position: relative;
}

.sigma_testimonial.style-3 .sigma_testimonial-body:after {
  content: "";
  position: absolute;
  width: 20px;
  background-color: #fff;
  height: 20px;
  border-left: 1px solid var(--thm-border);
  border-top: 1px solid var(--thm-border);
  transform: rotate(225deg) skew(10deg, 10deg);
  right: 24px;
  bottom: -11px;
}

.sigma_testimonial.style-3 .sigma_testimonial-body p,
.sigma_testimonial.style-4 .sigma_testimonial-body p {
  margin: 18px 0 0;
}

.sigma_testimonial.style-3 .sigma_testimonial-body .sigma_testimonial-icon {
  font-size: 30px;
}

.sigma_testimonial.style-3 .sigma_testimonial-thumb {
  margin-left: 10px;
}

.sigma_testimonial.style-3 .sigma_author-block {
  text-align: right;
}

/* style 4 */
.sigma_testimonial.style-4 {
  text-align: left;
}

.sigma_testimonial.style-4 .sigma_testimonial-inner {
  display: flex;
  justify-content: space-between;
}

.sigma_testimonial.style-4 .sigma_testimonial-body .sigma_testimonial-icon,
.sigma_testimonial.style-7 .sigma_testimonial-body .sigma_testimonial-icon {
  margin-top: 0;
}

/* style 5 */
.sigma_testimonial.style-5,
.sigma_testimonial.style-8 {
  border: 0;
  padding: 0 30px;
}

.sigma_testimonial.style-5 .sigma_author-block,
.sigma_testimonial.style-8 .sigma_author-block {
  margin-top: 28px;
}

.sigma_testimonial.style-5 .sigma_testimonial-body p,
.sigma_testimonial.style-8 .sigma_testimonial-body p {
  margin-bottom: 0;
}

.sigma_testimonial.style-5 .sigma_testimonial-body .sigma_rating {
  margin-top: 25px;
}

.sigma_testimonial-slider .slick-prev,
.sigma_testimonial-slider .slick-next,
.sigma_testimonial-slider-2 .slick-prev,
.sigma_testimonial-slider-2 .slick-next,
.sigma_testimonial-slider-3 .slick-prev,
.sigma_testimonial-slider-3 .slick-next {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_testimonial-slider .slick-prev:hover,
.sigma_testimonial-slider .slick-next:hover,
.sigma_testimonial-slider-2 .slick-prev:hover,
.sigma_testimonial-slider-2 .slick-next:hover,
.sigma_testimonial-slider-3 .slick-prev:hover,
.sigma_testimonial-slider-3 .slick-next:hover {
  background-color: var(--thm-base-hover);
}

.sigma_testimonial-slider .slick-next,
.sigma_testimonial-slider-2 .slick-next,
.sigma_testimonial-slider-3 .slick-next {
  right: 10px;
  left: auto;
}

.sigma_testimonial-slider:hover .slick-prev,
.sigma_testimonial-slider-2:hover .slick-prev,
.sigma_testimonial-slider-3:hover .slick-prev {
  left: 20px;
  opacity: 1;
  visibility: visible;
}

.sigma_testimonial-slider:hover .slick-next,
.sigma_testimonial-slider-2:hover .slick-next,
.sigma_testimonial-slider-3:hover .slick-next {
  right: 20px;
  opacity: 1;
  visibility: visible;
}

.sigma_testimonial-slider .slick-dots,
.sigma_testimonial-slider-3 .slick-dots {
  margin-top: 20px;
}

.sigma_testimonial-slider .slick-dots li button,
.sigma_testimonial-slider-3 .slick-dots li button {
  background-color: var(--thm-border);
}

.sigma_testimonial-slider .slick-dots .slick-active button,
.sigma_testimonial-slider-3 .slick-dots .slick-active button {
  background-color: var(--thm-base);
}

/* style 6 */
.sigma_testimonial.style-6 {
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;
  text-align: left;
}

.sigma_testimonial.style-6 .sigma_testimonial-body {
  flex: 1;
  max-width: 500px;
  margin-left: 80px;
  position: relative;
}

.sigma_testimonial.style-6 .sigma_testimonial-body:before,
.sigma_testimonial.style-9 .sigma_testimonial-body:before {
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  position: absolute;
  right: 20px;
  bottom: 25px;
  font-size: 80px;
  color: var(--thm-secondary);
  opacity: 0.1;
  z-index: -1;
}

.sigma_testimonial.style-6 .sigma_testimonial-thumb {
  justify-content: flex-start;
}

.sigma_testimonial.style-6 .sigma_testimonial-body .sigma_rating,
.sigma_testimonial.style-7 .sigma_testimonial-body .sigma_rating,
.sigma_testimonial.style-9 .sigma_testimonial-body .sigma_rating {
  margin: 0;
  justify-content: flex-start;
}

.sigma_testimonial.style-6 .sigma_testimonial-body p,
.sigma_testimonial.style-7 .sigma_testimonial-body p {
  margin: 18px 0 12px;
}

/* style 7 */
.sigma_testimonial.style-7 {
  border: 0;
  padding: 0;
  text-align: left;
  margin-bottom: 0;
}

.sigma_testimonial.style-7 .sigma_testimonial-thumb img {
  border-radius: 0;
  width: 100%;
  height: 100%;
}

.sigma_testimonial.style-7 .sigma_testimonial-body {
  padding: 30px;
}

/* style 8 */
.slider-nav-thumbnails {
  max-width: 370px;
  margin: 20px auto 0;
}

.slider-nav-thumbnails .slick-slide {
  cursor: pointer;
  opacity: 0.4;
  transition: 0.3s;
}

.slider-nav-thumbnails .slick-slide:hover {
  opacity: 0.8;
}

.slider-nav-thumbnails .slick-slide.slick-current {
  opacity: 1;
}

.slider-nav-thumbnails .sigma_testimonial-thumb {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-nav-thumbnails .sigma_testimonial-thumb img {
  border-radius: 50%;
}

/* style 9 */
.sigma_testimonial.style-9 {
  border: 0;
  padding: 0;
  text-align: left;
}

.sigma_testimonial.style-9 .sigma_testimonial-body p {
  margin: 0 0 18px;
}

.sigma_testimonial.style-9 .sigma_testimonial-category {
  font-size: 16px;
  color: var(--thm-b-text);
}

.sigma_testimonial.style-9 .sigma_testimonial-body .sigma_rating {
  margin-top: 8px;
}

/* style 10 */
.sigma_testimonial.style-10 {
  text-align: center;
}

/* style 11 */
.sigma_testimonial.style-11 {
  padding: 40px 50px;
  background-color: #fff;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.03);
}

.sigma_testimonial.style-11 .sigma_testimonial-thumb {
  margin-right: 30px;
  overflow: visible;
}

.sigma_testimonial.style-11 .sigma_testimonial-thumb img {
  border: 5px solid #fff;
  max-width: 70px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.03);
}

.sigma_testimonial.style-11 .sigma_testimonial-body .sigma_testimonial-icon {
  margin-right: 20px;
  margin-top: 0;
  color: var(--thm-base);
  opacity: 1;
  font-size: 40px;
}

/*-------------- FAQ & Accordions Sections --------------------------- */
/* Accordion style 1 */
.sigma_accordion .card {
  border-left: 0;
  border-right: 0;
  border-color: var(--thm-border);
  overflow: visible;
}

.sigma_accordion .accordion > .card:first-of-type {
  border-top: 0;
}

.sigma_accordion .card .card-header {
  margin: 0;
  background-color: #fff;
  border-bottom: 0;
  padding: 0;
}

.sigma_accordion .card .card-header h5, .sigma_accordion .card .card-header .h5 {
  font-weight: 400;
  text-transform: uppercase;
}

.sigma_accordion .card .card-header .accordion-link {
  position: relative;
  padding: 0;
  color: var(--thm-secondary);
  text-decoration: none;
  border: 0;
  background-color: transparent;
  outline: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  line-height: 1.4;
  transition: 0.3s;
  padding: 25px 0;
  box-shadow: none;
  text-transform: capitalize;
  font-size: inherit;
  font-weight: 400;
  overflow: visible;
}

[class*=btn-style-] .sigma_accordion .card .card-header .accordion-link::before {
  display: none;
}

[class*=btn-style-] .sigma_accordion .card .card-header .accordion-link i {
  color: var(--thm-secondary);
}

.sigma_accordion .accordion > .card:first-of-type .card-header .accordion-link {
  padding-top: 0;
}

.sigma_accordion .card .card-header .accordion-link i {
  font-size: 16px;
  transition: 0.3s;
  margin-left: 0;
}

.sigma_accordion .card .card-header .accordion-link i.icon-left {
  margin-right: 30px;
}

.sigma_accordion .card .card-header .accordion-link:hover {
  color: var(--thm-base-hover);
}

.sigma_accordion .card-body {
  padding: 0 0 25px;
}

.sigma_accordion .card .accordion-link[aria-expanded=true] i {
  transform: rotate(180deg);
}

/* Accordion style 2 */
.sigma_accordion.style-2 .card {
  border: 0;
  padding-left: 20px;
}

.sigma_accordion.style-2 .card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: var(--thm-border);
}

.sigma_accordion.style-2 .card .accordion-link {
  padding: 0;
  border: 0;
}

.sigma_accordion.style-2 .card .card-body {
  padding: 10px 0 0;
}

.sigma_accordion.style-2 .card + .card {
  margin-top: 20px;
}

.sigma_accordion.style-2 .card .accordion-link[aria-expanded=true] {
  color: var(--thm-base);
}

.sigma_accordion.style-2 .card .card-header .accordion-link {
  position: relative;
}

.sigma_accordion.style-2 .card .card-header .accordion-link::after {
  content: "";
  position: absolute;
  top: 0;
  left: -20px;
  width: 3px;
  height: 0;
  transition: 0.3s;
  background-color: var(--thm-base);
}

.sigma_accordion.style-2 .card .accordion-link[aria-expanded=true]::after {
  height: 100%;
}

/* Accordion style 3 */
.sigma_accordion.style-3 .card,
.sigma_accordion.style-3 .accordion > .card:not(:first-of-type):not(:last-of-type),
.sigma_accordion.style-3 .accordion > .card:first-of-type,
.sigma_accordion.style-4 .card,
.sigma_accordion.style-4 .accordion > .card:not(:first-of-type):not(:last-of-type),
.sigma_accordion.style-4 .accordion > .card:first-of-type,
.sigma_accordion.style-6 .card,
.sigma_accordion.style-6 .accordion > .card:not(:first-of-type):not(:last-of-type),
.sigma_accordion.style-6 .accordion > .card:first-of-type {
  border: 0;
  box-shadow: 0 0 40px 3px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  overflow: hidden;
}

.sigma_accordion.style-3 .accordion .card + .card,
.sigma_accordion.style-5 .accordion .card + .card,
.sigma_accordion.style-6 .accordion .card + .card {
  margin-top: 30px;
}

.sigma_accordion.style-3 .card .card-header .accordion-link,
.sigma_accordion.style-3 .accordion > .card:first-of-type .card-header .accordion-link,
.sigma_accordion.style-4 .card .card-header .accordion-link,
.sigma_accordion.style-4 .accordion > .card:first-of-type .card-header .accordion-link {
  padding: 25px;
}

.sigma_accordion.style-3 .card .card-header .accordion-link {
  justify-content: flex-start;
}

.sigma_accordion.style-3 .card .accordion-link[aria-expanded=true] i,
.sigma_accordion.style-4 .card .accordion-link[aria-expanded=true] i,
.sigma_accordion.style-6 .card .accordion-link[aria-expanded=true] i {
  transform: rotate(90deg);
}

.sigma_accordion.style-3 .card .accordion-link[aria-expanded=true],
.sigma_accordion.style-4 .card .accordion-link[aria-expanded=true] {
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_accordion.style-3 .card .accordion-link[aria-expanded=true] i,
.sigma_accordion.style-4 .card .accordion-link[aria-expanded=true] i {
  color: #fff;
}

.sigma_accordion.style-4 a.btn-link {
  display: block;
  margin-top: 15px;
}

/* Accordion style 4 */
.sigma_accordion.style-4 .card-body {
  padding: 25px;
}

.sigma_accordion.style-4 .accordion .card {
  margin-bottom: 30px;
}

/* Accordion style 5 */
.sigma_accordion.style-5 .card {
  border: 0;
}

.sigma_accordion.style-5.has-border {
  border-top: 1px solid var(--thm-border);
  padding-top: 30px;
  margin-top: 30px;
}

.sigma_accordion.style-5 .card .card-header .accordion-link:before,
.sigma_accordion.style-6 .card .card-header .accordion-link:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: var(--thm-b-text);
  font-size: 20px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
}

.sigma_accordion.style-5 .card-body {
  padding: 30px 0 0;
}

.sigma_accordion.style-5 .card .card-header .accordion-link {
  padding: 0;
}

.sigma_accordion.style-5 .card .accordion-link[aria-expanded=true] i {
  transform: rotate(45deg);
}

/* Accordion style 6 */
.sigma_accordion.style-6 + .sigma_accordion.style-6 {
  margin-top: 40px;
}

.sigma_accordion.style-6 .accordion {
  margin-top: 25px;
}

.sigma_accordion.style-6 .card .card-header .accordion-link,
.sigma_accordion.style-6 .accordion > .card:first-of-type .card-header .accordion-link {
  padding: 25px;
}

.sigma_accordion.style-6 .card .card-header .accordion-link:before {
  left: 25px;
}

.sigma_accordion.style-6 .card-body {
  padding: 0 25px 25px;
}

/* Faq style 7 */
.sigma_faq {
  border-bottom: 1px solid var(--thm-border);
  padding: 30px 0;
}

.sigma_faq:first-of-type {
  padding-top: 0;
}

.sigma_faq p {
  margin-bottom: 0;
}

/* Faq style 8 */
.sigma_faq.style-8,
.sigma_faq.style-9,
.sigma_faq.style-10 {
  border: 0;
  padding: 0;
  display: flex;
  margin-bottom: 30px;
}

.sigma_faq.style-8 i,
.sigma_faq.style-9 i {
  line-height: 1.8;
  width: 40px;
  height: 40px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--thm-base);
  border-radius: 50%;
  margin-right: 20px;
  color: #fff;
}

.sigma_faq.style-8 .sigma_faq-body,
.sigma_faq.style-9 .sigma_faq-body {
  flex: 1;
}

.sigma_faq.style-8 h5, .sigma_faq.style-8 .h5,
.sigma_faq.style-9 h5,
.sigma_faq.style-9 .h5,
.sigma_faq.style-10 h5,
.sigma_faq.style-10 .h5 {
  margin-bottom: 15px;
  position: relative;
}

/* Faq style 9 */
.sigma_faq.style-9 {
  padding: 25px;
  border: 1px solid var(--thm-border);
  border-radius: 6px;
  transition: 0.3s;
}

.sigma_faq.style-9:hover {
  border-color: transparent;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
}

/* Faq style 10 */
.sigma_faq.style-10 .sigma_faq-flex-box {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.sigma_faq.style-10 .sigma_faq-flex-box h5, .sigma_faq.style-10 .sigma_faq-flex-box .h5,
.sigma_faq.style-10 .sigma_faq-flex-box p {
  flex: 1;
}

.sigma_faq.style-10 .icon-box {
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--thm-secondary);
  color: #fff;
  border-radius: 6px;
  font-size: 28px;
  font-weight: 600;
  margin-right: 25px;
}

.sigma_faq.style-10 .icon-box.has-shadow {
  background-color: #fff;
  color: var(--thm-b-text);
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.04);
}

/* Faq style 11 */
.sigma_faq.style-11 {
  border: 1px solid var(--thm-border);
  padding: 30px;
  margin: 70px 0 0;
}

.sigma_faq.style-11 .sigma_faq-body p {
  margin: 0;
}

.sigma_faq.style-11 .sigma_faq-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border: 1px solid var(--thm-border);
  border-radius: 50%;
  text-align: center;
  color: var(--thm-base);
  font-size: 14px;
  font-weight: 700;
  margin: -60px 0 20px;
  background: #fff;
  transition: 0.3s;
}

.sigma_faq.style-11:hover .sigma_faq-number {
  background-color: var(--thm-base);
  border-color: var(--thm-base);
  color: #fff;
}

/*-------------- Tab Sections --------------------------- */
/* Tab items style 1 */
.sigma_tab-item .nav-tabs {
  border: none;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
}

.sigma_tab-item .nav-tabs .nav-item {
  margin-bottom: 30px;
  position: relative;
}

.sigma_tab-item .nav-tabs li + li {
  margin-left: 30px;
}

.sigma_tab-item .nav-tabs .nav-link {
  color: var(--thm-b-text);
  font-weight: 500;
  border-radius: 6px;
  padding: 15px;
  display: flex;
  align-items: center;
  transition: 0.3s;
  position: relative;
  font-size: 14px;
}

.sigma_tab-item .nav-tabs .nav-link i {
  font-size: 22px;
  margin-right: 12px;
  transition: 0.3s;
}

.sigma_tab-item .nav-tabs .nav-link.active,
.sigma_tab-item .nav-tabs .nav-link:hover,
.sigma_tab-item.style-10 .nav-tabs .nav-link.active,
.sigma_tab-item.style-10 .nav-tabs .nav-link:hover,
.sigma_tab-item.style-12 .nav-tabs .nav-link.active,
.sigma_tab-item.style-12 .nav-tabs .nav-link:hover {
  color: #fff;
  background-color: var(--thm-base);
  border-color: var(--thm-base);
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.04);
}

/* Tab items style 2 */
.sigma_tab-item.style-2 .nav-tabs .nav-link,
.sigma_tab-item.style-8 .nav-tabs .nav-link {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  border-color: transparent;
}

.sigma_tab-item.style-2 .nav-tabs .nav-link.active,
.sigma_tab-item.style-2 .nav-tabs .nav-link:hover,
.sigma_tab-item.style-8 .nav-tabs .nav-link.active,
.sigma_tab-item.style-8 .nav-tabs .nav-link:hover {
  color: var(--thm-base);
  background-color: transparent;
  box-shadow: none;
  border-color: var(--thm-base);
  opacity: 1;
}

/* Tab items style 3 */
.sigma_tab-item.style-3 .nav-tabs .nav-link,
.sigma_tab-item.style-4 .nav-tabs .nav-link,
.sigma_tab-item.style-6 .nav-tabs .nav-link {
  background-color: #f2f6fa;
}

.sigma_tab-item.style-3 .nav-tabs .nav-item:first-of-type .nav-link {
  border-radius: 6px 0 0 6px;
}

.sigma_tab-item.style-3 .nav-tabs .nav-item + .nav-item .nav-link,
.sigma_tab-item.style-6 .nav-tabs .nav-item + .nav-item .nav-link {
  border-radius: 0;
}

.sigma_tab-item.style-3 .nav-tabs .nav-item:last-of-type .nav-link {
  border-radius: 0 6px 6px 0;
}

.sigma_tab-item.style-3 .nav-tabs li + li,
.sigma_tab-item.style-6 .nav-tabs li + li {
  margin-left: 2px;
}

.sigma_tab-item.style-3 .nav-tabs .nav-link.active,
.sigma_tab-item.style-3 .nav-tabs .nav-link:hover,
.sigma_tab-item.style-4 .nav-tabs .nav-link.active,
.sigma_tab-item.style-4 .nav-tabs .nav-link:hover,
.sigma_tab-item.style-13 .nav-tabs .nav-link.active,
.sigma_tab-item.style-13 .nav-tabs .nav-link:hover {
  color: #fff;
  background-color: var(--thm-base);
  box-shadow: none;
}

/* Tab items style 4 */
.sigma_tab-item.style-4 .nav-tabs {
  flex-direction: column;
}

.sigma_tab-item.style-4 .nav-tabs li + li {
  margin-left: 0;
}

.sigma_tab-item.style-4 .nav-tabs .nav-link.active,
.sigma_tab-item.style-4 .nav-tabs .nav-link:hover {
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.04);
}

/* Tab items style 5 */
.sigma_tab-item.style-5 .nav-tabs,
.sigma_tab-item.style-6 .nav-tabs {
  justify-content: flex-start;
}

.sigma_tab-item.style-5 .nav-tabs .nav-link {
  border-color: transparent;
}

/* Tab items style 6 */
.sigma_tab-item.style-6 .nav-tabs .nav-link {
  border-width: 5px 0 0 0;
}

.sigma_tab-item.style-6 .nav-tabs .nav-item:first-of-type .nav-link {
  border-radius: 6px 0 0 0;
}

.sigma_tab-item.style-6 .nav-tabs .nav-item:last-of-type .nav-link {
  border-radius: 0;
}

.sigma_tab-item.style-6 .nav-tabs .nav-link.active,
.sigma_tab-item.style-6 .nav-tabs .nav-item:first-of-type .nav-link.active,
.sigma_tab-item.style-6 .nav-tabs .nav-item:last-of-type .nav-link.active,
.sigma_tab-item.style-6 .nav-tabs .nav-link:hover,
.sigma_tab-item.style-6 .nav-tabs .nav-item:first-of-type .nav-link:hover,
.sigma_tab-item.style-6 .nav-tabs .nav-item:last-of-type .nav-link:hover {
  box-shadow: none;
  background-color: #fff;
  color: var(--thm-base);
  border-radius: 0 0 0 0;
}

/* Tab items style 7 */
.sigma_tab-item.style-7 .nav-tabs .nav-link,
.sigma_tab-item.style-13 .nav-tabs .nav-link {
  width: 180px;
  flex-direction: column;
  padding: 35px;
}

.sigma_tab-item.style-7 .nav-tabs .nav-link:before,
.sigma_tab-item.style-13 .nav-tabs .nav-link:before {
  content: "";
  position: absolute;
  width: 10px;
  background-color: transparent;
  height: 10px;
  border-top: solid 15px transparent;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
  left: 50%;
  transform: translateX(-50%);
  bottom: -16px;
  transition: 0.3s;
}

.sigma_tab-item.style-7 .nav-tabs .nav-link i,
.sigma_tab-item.style-13 .nav-tabs .nav-link i {
  font-size: 26px;
  margin-bottom: 0px;
  margin-right: 0;
}

.sigma_tab-item.style-7 .nav-tabs .nav-link i {
  margin-bottom: 15px;
}

.sigma_tab-item.style-7 .nav-tabs .nav-link.active:before,
.sigma_tab-item.style-7 .nav-tabs .nav-link:hover:before,
.sigma_tab-item.style-13 .nav-tabs .nav-link.active:before,
.sigma_tab-item.style-13 .nav-tabs .nav-link:hover:before {
  border-top-color: var(--thm-base);
}

/* Tab items style 8 */
.sigma_tab-item.style-8 .nav-tabs,
.sigma_tab-item.style-9 .nav-tabs {
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.04);
  margin-bottom: 50px;
}

.sigma_tab-item.style-8 .nav-tabs .nav-item {
  margin-bottom: 0;
}

.sigma_tab-item.style-8 .nav-tabs .nav-link {
  padding: 28px 35px;
  border-bottom-width: 2px;
}

/* Tab items style 9 */
.sigma_tab-item.style-9 .nav-tabs {
  padding: 30px 30px 0;
}

.sigma_tab-item.style-9 .nav-tabs .nav-link {
  border: 0;
  min-width: 120px;
  justify-content: center;
}

.sigma_tab-item.style-9 .nav-tabs .nav-link i {
  color: var(--thm-b-text);
  margin-right: 15px;
}

.sigma_tab-item.style-9 .nav-tabs .nav-link.active i,
.sigma_tab-item.style-9 .nav-tabs .nav-link:hover i {
  color: #fff;
}

/* Tab items style 10 */
.sigma_tab-item.style-10 .nav-tabs {
  margin-bottom: 50px;
  justify-content: space-evenly;
}

.sigma_tab-item.style-10 .nav-tabs:before {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: var(--thm-border);
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: -1;
}

.sigma_tab-item.style-10 .nav-tabs .nav-item {
  margin-bottom: 0;
}

.sigma_tab-item.style-10 .nav-tabs .nav-link {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  padding: 0;
  margin: 0 auto;
  outline: none;
  background-color: #f2f6fa;
  justify-content: center;
}

/* Tab items style 11 */
.sigma_tab-item.style-11 .nav-tabs {
  justify-content: start;
}

.sigma_tab-item.style-11 .nav-tabs .nav-link {
  flex-direction: column;
  border: 0;
  padding: 0;
  box-shadow: none;
}

.sigma_tab-item.style-11 .nav-tabs .nav-link i {
  margin-right: 0;
  margin-bottom: 10px;
  font-size: 45px;
}

.sigma_tab-item.style-11 .nav-tabs .nav-link.active,
.sigma_tab-item.style-11 .nav-tabs .nav-link:hover {
  color: var(--thm-secondary);
  background-color: transparent;
}

.sigma_tab-item.style-11 .nav-tabs .nav-link:hover i,
.sigma_tab-item.style-11 .nav-tabs .nav-link.active i {
  color: var(--thm-base);
}

.sigma_tab-item.style-11 .nav-tabs li + li {
  margin-left: 60px;
}

/* Tab items style 12 */
.sigma_tab-item.style-12 .nav-tabs {
  flex-direction: column;
}

.sigma_tab-item.style-12 .nav-tabs li {
  margin-bottom: 0;
}

.sigma_tab-item.style-12 .nav-tabs li + li {
  margin-left: 0;
  margin-top: 30px;
}

.sigma_tab-item.style-12 .nav-tabs .nav-link {
  flex-direction: column;
  padding: 0;
  border-radius: 0;
  width: 100%;
  height: 146px;
  border: 0;
  line-height: 1;
  justify-content: center;
  text-transform: uppercase;
  background-color: #f2f6fa;
}

.sigma_tab-item.style-12 .nav-tabs .nav-link i {
  margin-right: 0;
  margin-bottom: 20px;
  font-size: 34px;
}

.sigma_tab-item.style-12 .nav-tabs .nav-link.active,
.sigma_tab-item.style-12 .nav-tabs .nav-link:hover {
  box-shadow: none;
}

/* Tab items style 13 */
.sigma_tab-item.style-13 .nav-tabs .nav-item {
  line-height: 1.4;
}

.sigma_tab-item.style-13 .nav-tabs .nav-link {
  width: 160px;
  padding: 34px;
  text-align: center;
  border-radius: 0;
  border: 0;
  background-color: var(--thm-base-hue);
}

.sigma_tab-item.style-13 .nav-tabs .nav-link i {
  color: var(--thm-base);
  font-size: 36px;
  margin-bottom: 15px;
}

.sigma_tab-item.style-13 .nav-tabs .nav-link.active i,
.sigma_tab-item.style-13 .nav-tabs .nav-link:hover i {
  color: #fff;
}

.sigma_tab-item.style-13 .nav-tabs .nav-link:before {
  bottom: -15px;
  border-left: solid 18px transparent;
  border-right: solid 18px transparent;
}

.sigma_tab-content .connect-us {
  display: flex;
  align-items: center;
  border-top: 1px solid var(--thm-border);
  margin-top: 40px;
  padding-top: 40px;
}

.sigma_tab-content .connect-us i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  background-color: var(--thm-base);
  color: #fff;
  font-size: 32px;
  margin-right: 20px;
}

.sigma_tab-content .connect-us span {
  display: block;
  color: var(--thm-base);
  font-weight: 600;
  font-size: 28px;
  line-height: 1.2;
}

/*-------------- Contact Form --------------------------- */
/* style 1 */
.sigma_form.has-shadow {
  position: relative;
  padding: 50px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
}

.sigma_form .sigma_form-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sigma_form button.btn-block + button.btn-block {
  margin-top: 15px;
}

.sigma_form-seperator {
  margin: 20px 0;
  text-align: center;
  width: 100%;
}

.sigma_form-seperator span {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.sigma_form-seperator span:before {
  content: "";
  flex: 1;
  background-color: var(--thm-border);
  height: 1px;
  margin-right: 10px;
}

.sigma_form-seperator span:after {
  content: "";
  flex: 1;
  background-color: var(--thm-border);
  height: 1px;
  margin-left: 10px;
}

.sigma_contact-map {
  width: 100%;
  height: 100%;
  position: relative;
}

.sigma_contact-map iframe {
  border: 0;
  width: 100%;
}

.sigma_contact-block + .sigma_contact-block {
  margin-top: 20px;
}

.sigma_contact-block p {
  margin-bottom: 5px;
}

/* style 2 */
.sigma_form.style-2 .sigma_buttons button + button {
  margin-left: 15px;
}

/* style 3 */
.sigma_form.style-3 {
  max-width: 400px;
  margin: 0 auto;
}

.sigma_form-image {
  height: 100vh;
}

.sigma_form-image img {
  width: 100%;
  height: 100%;
}

.sigma_contact-map.has-full-height iframe {
  height: 100%;
}

/* style 4 */
.sigma_form.style-4 {
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.sigma_contact-with-bg {
  position: relative;
}

.sigma_contact-with-bg:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("http://via.placeholder.com/634x785");
  background-position: center;
  background-size: cover;
  width: 33%;
  height: 100%;
  z-index: -1;
}

.sigma_contact.extra-padding {
  padding-bottom: 150px;
}

.sigma_contact-map.is-absolute {
  position: relative;
  margin-top: -150px;
  z-index: 1;
}

.sigma_contact-map.is-absolute iframe {
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.03);
}

/*-------------- Clients --------------------------- */
/* style 1 */
.sigma_clients {
  position: relative;
  margin-bottom: 30px;
  transition: 0.3s;
  display: block;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
}

.sigma_clients img {
  width: 100%;
  transition: 0.3s;
}

/*-------------- CTA --------------------------- */
/* style 1 */
.sigma_cta {
  position: relative;
  text-align: center;
  overflow: hidden;
}

.sigma_cta-buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.sigma_cta-buttons a + a,
.sigma_cta-buttons button + button {
  margin-left: 20px;
}

.sigma_cta-buttons .play-btn {
  display: flex;
  align-items: center;
  padding: 10px 25px;
  color: #fff;
  background-color: var(--thm-secondary);
  font-weight: 700;
  transition: 0.3s;
}

.sigma_cta-buttons .play-btn p,
.sigma_cta.style-3 .sigma_cta-title p {
  margin: 0;
}

.sigma_cta-buttons .play-btn:hover {
  background-color: #070606;
}

.sigma_cta-buttons .play-btn i {
  display: inline-block;
  font-size: 30px;
  margin-right: 10px;
}

.sigma_cta-buttons .play-btn span {
  font-weight: 400;
  font-size: 14px;
  display: block;
  line-height: 1;
}

.sigma_cta .sigma_cta-icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 6px;
  background-color: var(--thm-base-hue);
  color: var(--thm-base);
  font-size: 30px;
  margin: 0 auto 30px;
}

/* style 2 */
.sigma_cta.style-2 {
  background-color: var(--thm-base);
  padding: 50px 50px 55px;
  z-index: 1;
}

.sigma_cta.style-2 .shape-2,
.sigma_cta.style-2 .shape-1 {
  content: "";
  position: absolute;
  top: -150px;
  left: -150px;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.1;
  z-index: -1;
}

.sigma_cta.style-2 .shape-2 {
  top: 200px;
  left: -100px;
  width: 200px;
  height: 200px;
  opacity: 0.2;
}

/* style 3 */
.sigma_cta.style-3 {
  padding: 0 30px;
  border-right: 1px solid var(--thm-border);
  margin-bottom: 30px;
}

.sigma_cta.style-3 .sigma_cta-icon-box,
.sigma_cta.style-4 .sigma_cta-icon-box {
  font-size: 26px;
}

.sigma_cta.style-3 .sigma_cta-title,
.sigma_cta.style-4 .sigma_cta-title {
  margin-bottom: 40px;
}

.sigma_cta.style-3 .sigma_cta-title h5, .sigma_cta.style-3 .sigma_cta-title .h5,
.sigma_cta.style-4 .sigma_cta-title h5,
.sigma_cta.style-4 .sigma_cta-title .h5 {
  margin-bottom: 15px;
}

/* style 4 */
.sigma_cta.style-4 {
  text-align: left;
  padding: 30px;
  border: 1px solid var(--thm-border);
  margin-bottom: 30px;
  border-radius: 6px;
  z-index: 1;
  transition: 0.3s;
}

.sigma_cta.style-4 .sigma_cta-icon-box {
  background-color: var(--thm-secondary);
  color: #fff;
  margin: 0 0 30px;
}

.sigma_cta.style-4 .shape-1 {
  content: "";
  position: absolute;
  top: -150px;
  right: -150px;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.1;
  z-index: -1;
}

.sigma_cta.style-4:not(.bg-primary-1) .shape-1 {
  background-color: var(--thm-base);
}

.sigma_cta.style-4:hover {
  border-color: transparent;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
}

/* style 5 */
.sigma_cta.style-5 {
  border-radius: 6px;
}

.sigma_cta.style-5 .sigma_cta-image {
  width: 100%;
  height: 100%;
}

.sigma_cta.style-5 .sigma_cta-content {
  padding: 60px 60px 70px;
}

/* style 6 */
.sigma_cta.style-7,
.sigma_cta.style-6,
.sigma_cta.style-9 {
  text-align: left;
  padding: 50px;
  border-radius: 25px;
  background-color: #fff;
  box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.04);
  overflow: visible;
}

/* style 7 */
.sigma_cta.style-7 {
  padding: 30px;
}

.sigma_cta.style-7 h5, .sigma_cta.style-7 .h5,
.sigma_cta.style-9 h5,
.sigma_cta.style-9 .h5 {
  margin: 0;
  font-size: 24px;
  padding-left: 170px;
}

.sigma_cta.style-7 img {
  position: absolute;
  bottom: 0;
  left: 40px;
}

/* Style 8 */
.sigma_cta.style-8 {
  position: relative;
  margin-bottom: 30px;
  z-index: 1;
  border-radius: 8px;
  box-shadow: 0 3px 24px rgba(0, 0, 0, 0.04);
  overflow: hidden;
}

.sigma_cta.style-8::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  transition: 0.3s;
}

.sigma_cta.style-8 img {
  border-radius: 8px;
  width: 100%;
}

.sigma_cta.style-8 .sigma_cta-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  justify-content: flex-end;
  padding: 20px;
}

.sigma_cta.style-8 .sigma_cta-content h4, .sigma_cta.style-8 .sigma_cta-content .h4 {
  margin-bottom: 10px;
}

.sigma_cta.style-8 .sigma_cta-content p {
  max-width: 350px;
  text-align: left;
  margin-bottom: 30px;
}

/* Style 9 */
.sigma_cta.style-9 {
  background-color: var(--thm-base);
  border-radius: 6px;
  box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.04);
  padding: 40px;
}

.sigma_cta.style-9 .sigma_cta-content i {
  color: #fff;
  font-size: 50px;
  display: inline-block;
  line-height: 1;
  margin-right: 30px;
}

.btn-rounded .sigma_cta.style-9 .input-group-append button {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.btn-pill .sigma_cta.style-9 .input-group-append button {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.sigma_cta.style-9 .sigma_cta-content h5, .sigma_cta.style-9 .sigma_cta-content .h5 {
  padding-left: 0;
  color: #fff;
  flex: 1;
  margin-bottom: 0;
}

.sigma_cta.style-9 form .input-group {
  border: 2px solid #fff;
  border-radius: 6px;
}

.sigma_cta.style-9 form input {
  background-color: transparent;
  border: 0;
  color: #fff;
  height: 70px;
}

.sigma_cta.style-9 form input::placeholder {
  color: #fff;
}

.sigma_cta.style-9 form .input-group-append {
  padding: 6px;
}

/*-------------- Banner (Hero Sections) --------------------------- */
.sigma_banner {
  position: relative;
  z-index: 1;
}

.sigma_banner .banner-slider-inner {
  padding: 180px 0;
  position: relative;
}

.spacer_banner {
  min-height: 395px;
}

.sigma_banner .title {
  font-size: 68px;
  line-height: 1;
}

.sigma_banner h5, .sigma_banner .h5 {
  font-size: 18px;
  font-weight: 600;
}

.sigma_header-absolute + .sigma_banner .banner-slider-inner {
  padding: 270px 0 200px;
}

.sigma_banner .banner-links {
  margin-top: 50px;
}

.sigma_banner .sigma_banner-social {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40px;
}

.sigma_banner .sigma_banner-image {
  position: relative;
}

.sigma_banner .sigma_video-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.sigma_banner-slider .slick-prev,
.sigma_banner-slider .slick-next,
.sigma_banner-slider-2 .slick-prev,
.sigma_banner-slider-2 .slick-next,
.sigma_banner-slider-4 .slick-prev,
.sigma_banner-slider-4 .slick-next {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_banner-slider .slick-prev:hover,
.sigma_banner-slider .slick-next:hover,
.sigma_banner-slider-2 .slick-prev:hover,
.sigma_banner-slider-2 .slick-next:hover,
.sigma_banner-slider-4 .slick-prev:hover,
.sigma_banner-slider-4 .slick-next:hover {
  background-color: var(--thm-base-hover);
}

.sigma_banner-slider .slick-next,
.sigma_banner-slider-2 .slick-next {
  right: 10px;
  left: auto;
}

.sigma_banner-slider:hover .slick-prev,
.sigma_banner-slider-2:hover .slick-prev {
  left: 20px;
  opacity: 1;
  visibility: visible;
}

.sigma_banner-slider:hover .slick-next,
.sigma_banner-slider-2:hover .slick-next {
  right: 20px;
  opacity: 1;
  visibility: visible;
}

.sigma_banner-slider .slick-dots,
.sigma_banner-slider-2 .slick-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.sigma_banner-slider .slick-dots li button,
.sigma_banner-slider-2 .slick-dots li button {
  background-color: var(--thm-border);
}

.sigma_banner-slider .slick-dots .slick-active button,
.sigma_banner-slider-2 .slick-dots .slick-active button {
  background-color: var(--thm-base);
}

/* style 2 */
.sigma_banner.style-2 .sigma_banner-slider-2 .slick-dots {
  bottom: 140px;
  left: 0;
  transform: none;
}

.sigma_banner.style-2 .sigma_banner-slider-2 .slick-prev {
  left: -85px;
}

.sigma_banner.style-2 .sigma_banner-slider-2 .slick-next {
  right: -85px;
}

.sigma_banner.style-2 .sigma_banner-slider-2:hover .slick-prev {
  left: -80px;
}

.sigma_banner.style-2 .sigma_banner-slider-2:hover .slick-next {
  right: -80px;
}

/* style 3 */
.sigma_banner.style-3.light-overlay:before,
.sigma_banner.style-4.light-overlay:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0;
}

.sigma_banner.style-3 .sigma_banner-image {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: -1;
}

/* style 4 */
.sigma_banner.style-4 .sigma_banner-image {
  left: 0;
  right: auto;
}

.sigma_banner.style-4 .sigma_clients {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid var(--thm-border);
}

.banner-image-slider {
  bottom: -132px;
}

.sigma_banner-slider-4 .slick-prev,
.sigma_banner-slider-4 .slick-next {
  bottom: 15%;
  top: auto;
  transform: none;
  opacity: 1;
  visibility: visible;
}

.sigma_banner-slider-4 .slick-next {
  left: 80px;
}

.sigma_banner-slider-5.slick-slider {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  z-index: -2;
}

.sigma_banner-slider-5 div {
  height: 100%;
}

.sigma_banner-thumbnails .slick-slide {
  cursor: pointer;
  opacity: 0.4;
  transition: 0.3s;
}

.sigma_banner-thumbnails .slick-slide:hover {
  opacity: 0.8;
}

.sigma_banner-thumbnails .slick-slide.slick-current {
  opacity: 1;
}

/* style 5 */
.sigma_banner.style-5 .banner-links span {
  font-weight: 500;
}

.sigma_banner.style-5 .banner-slider-inner {
  padding: 140px 0;
}

.sigma_header-absolute + .sigma_banner.style-5 .banner-slider-inner {
  padding: 240px 0 130px;
}

.sigma_banner.style-5 .sigma_video-btn {
  width: 60px;
  height: 60px;
  background-color: var(--thm-base);
  color: #fff;
  margin-right: 15px;
  position: relative;
  left: 0;
  top: 0;
  transform: none;
}

.sigma_banner.style-5 .sigma_video-btn:hover {
  background-color: var(--thm-base-hover);
}

/* style 6 */
.sigma_banner.style-6 {
  overflow: hidden;
}

.sigma_banner-slider-3 {
  position: absolute;
  top: -150px;
}

.sigma_banner-slider-3.slick-vertical .slick-slide {
  padding: 15px 0;
}

.sigma_banner-slider-3 .slick-dots {
  position: absolute;
  top: 15px;
  right: 0;
  flex-direction: column;
}

.sigma_banner-slider-3 .slick-dots li {
  line-height: 1;
}

.sigma_banner-slider-3 .slick-dots li + li {
  margin-left: 0;
  margin-top: 10px;
}

/* Style 7 */
.sigma_banner.style-7 .title {
  margin: 0 0 60px;
  font-size: 128px;
}

.sigma_banner.style-7 p {
  text-transform: uppercase;
  font-weight: 500;
}

.sigma_banner.style-7 .banner-slider-inner {
  padding: 130px 0 110px;
}

/* Style 8 */
.sigma_banner.style-8 .title {
  font-size: 52px;
}

.sigma_banner-info {
  position: relative;
  margin-top: -100px;
  z-index: 1;
  margin-bottom: 80px;
}

.sigma_banner.style-8 .banner-slider-inner {
  padding: 150px 0 250px;
}

.banner-slider-slick {
  background-repeat: no-repeat;
  background-size: cover;
  -o-object-fit: cover;
  object-fit: cover;
}

.banners-social-icons {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: space-between;
  align-items: center;
}

.banners-social-icons li {
  line-height: 50px;
  width: 50px;
  text-align: center;
}

.banners-social-icons li i {
  color: var(--thm-base);
  font-size: 25px;
  align-self: center;
}

.banners-social-icons li i:hover {
  color: var(--white);
  font-size: 25px;
  align-self: center;
}

/*-------------- Footer Sections --------------------------- */
.sigma_footer {
  background-color: #f7f7f7;
  padding: 50px 0 0;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}

/* style 1 */
.sigma_footer .sigma_footer-links {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0;
}

.sigma_footer .sigma_footer-links li {
  position: relative;
}

.sigma_footer .sigma_footer-links li + li {
  margin-left: 20px;
}

.sigma_footer .sigma_footer-links li a {
  position: relative;
  color: var(--thm-b-text);
  font-weight: 400;
}

.sigma_footer .sigma_footer-links li a:hover,
.sigma_footer .sigma_footer-copyright p a:hover {
  color: var(--thm-base);
}

.sigma_footer .sigma_footer-copyright {
  margin-top: 25px;
}

.sigma_footer .sigma_footer-copyright p a {
  color: var(--thm-secondary);
  font-weight: 600;
}

.sigma_footer .sigma_footer-bottom {
  border-top: 1px solid var(--thm-border);
  padding: 30px 0;
  margin-top: 30px;
  text-align: center;
}

.sigma_footer form span {
  opacity: 0.5;
  font-size: 14px;
  display: block;
  margin-top: 8px;
}

.sigma_footer .sigma_footer-widget .widget-title {
  margin-bottom: 20px;
}

.sigma_footer .sigma_footer-widget {
  padding-bottom: 50px;
  height: 100%;
}

/* style 2 */
.sigma_footer .sigma_footer-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sigma_footer.style-2 .sigma_footer-links,
.sigma_footer.style-2 .sigma_footer-copyright {
  margin: 0;
}

.sigma_footer.style-2 select {
  width: 180px;
}

/* style 3 */
.sigma_footer.style-3 .sigma_footer-links,
.sigma_footer.style-5 .sigma_footer-links {
  flex-direction: column;
  align-items: start;
  margin: 0;
}

.sigma_footer.style-3 .sigma_footer-links li + li,
.sigma_footer.style-5 .sigma_footer-links li + li {
  margin-left: 0;
  margin-top: 14px;
}

/* style 4 */
.sigma_footer.style-4 .sigma_footer-top {
  border-bottom: 1px solid var(--thm-border);
  padding-bottom: 50px;
  margin-bottom: 50px;
}

/* style 5 */
.sigma_footer.style-5 .sigma_footer-bottom {
  margin-top: 50px;
}

.sigma_footer.style-5.has-newsletter {
  padding-top: 160px;
}

.sigma_footer.style-5 .sigma_footer-bottom .sigma_footer-links {
  flex-direction: row;
}

.sigma_footer.style-5 .sigma_footer-bottom .sigma_footer-links li + li {
  margin-left: 15px;
  padding-left: 15px;
  margin-top: 0;
}

.sigma_footer.style-5 .sigma_footer-bottom .sigma_footer-links li + li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 16px;
  background-color: #999b9f;
}

.sigma_footer.style-5 .sigma_info-wrapper {
  background-color: rgba(255, 255, 255, 0.1);
}

.sigma_footer.style-5 .sigma_footer-image {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

/* footer newsletter */
.sigma_footer-newsletter {
  position: relative;
  margin-bottom: -80px;
  z-index: 2;
}

.sigma_footer-newsletter:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background-color: var(--thm-base);
}

.sigma_footer-newsletter .sigma_cta.style-9 {
  box-shadow: none;
  padding-left: 0;
}

/* Dark Footer */
.sigma_footer.sigma_footer-dark {
  background-color: var(--thm-secondary);
}

.sigma_footer.sigma_footer-dark .sigma_social-icons li a {
  background-color: rgba(255, 255, 255, 0.1);
  color: #999b9f;
}

.sigma_footer.sigma_footer-dark .sigma_social-icons li a:hover {
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_footer.sigma_footer-dark,
.sigma_footer.sigma_footer-dark p,
.sigma_footer.sigma_footer-dark .sigma_footer-links li a,
.sigma_footer.sigma_footer-dark .sigma_footer-copyright p a,
.sigma_footer.sigma_footer-dark .widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr h6 a,
.sigma_footer.sigma_footer-dark .widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr .h6 a,
.sigma_footer.sigma_footer-dark .widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr .date {
  color: #999b9f;
}

.sigma_footer.sigma_footer-dark .sigma_footer-widget .widget-title,
.sigma_footer.sigma_footer-dark .sigma_footer-links li a:hover,
.sigma_footer.sigma_footer-dark .sigma_footer-copyright p a:hover,
.sigma_footer.sigma_footer-dark .widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr h6 a:hover,
.sigma_footer.sigma_footer-dark .widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr .h6 a:hover,
.sigma_footer.sigma_footer-dark .widget.widget-sigma-recent-posts .sigma_recent-post .recent-post-descr .date:hover {
  color: #fff;
}

.sigma_footer.sigma_footer-dark.style-4 .sigma_footer-top,
.sigma_footer.sigma_footer-dark .sigma_footer-bottom {
  border-color: rgba(255, 255, 255, 0.1);
}

.sigma_footer.sigma_footer-dark .form-control,
.sigma_footer.sigma_footer-dark select,
.sigma_footer.sigma_footer-dark input[type=email],
.sigma_footer.sigma_footer-dark input[type=text] {
  border-color: rgba(255, 255, 255, 0.1);
  background-color: #1c1e22;
}

/*-------------- About Sections --------------------------- */
/* style 1 */
.sigma_about {
  position: relative;
}

.sigma_about .sigma_about-content .important-text {
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}

.sigma_about .sigma_about-content .important-text::before {
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  position: absolute;
  font-size: 80px;
  top: -5px;
  right: -5px;
  opacity: 0.2;
  color: var(--thm-base);
}

.sigma_about .sigma_general-list {
  padding: 0;
  background-color: #fff;
  margin: 20px 0;
}

.sigma_about .sigma_about-image-1,
.sigma_about .sigma_about-image-2 {
  border: 8px solid var(--thm-base);
  background-color: #fff;
  padding: 5px;
  border-radius: 50%;
}

.sigma_about .sigma_about-image-1 img,
.sigma_about .sigma_about-image-2 img {
  border-radius: 50%;
  width: 100%;
}

.sigma_about .sigma_about-image-2 {
  position: absolute;
  bottom: 30px;
  left: 5px;
}

/* style 2 */
.sigma_about.style-2 .sigma_about-image-1,
.sigma_about.style-3 .sigma_about-image-1,
.sigma_about.style-3 .sigma_about-image-2,
.sigma_about.style-4 .sigma_about-image-1 {
  border: 0;
  padding: 0;
  border-radius: 0;
  overflow: hidden;
}

.sigma_about.style-2 .sigma_about-image-1 img,
.sigma_about.style-3 .sigma_about-image-1 img,
.sigma_about.style-3 .sigma_about-image-2 img,
.sigma_about.style-4 .sigma_about-image-1 img {
  border-radius: 0;
}

.sigma_about.style-2 .sigma_about-image-1 .sigma_video-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* style 3 */
.sigma_about.style-3 .sigma_about-image-1,
.sigma_about.style-3 .sigma_about-image-2 {
  border-radius: 0;
}

.sigma_about.style-3 .sigma_about-image-2 {
  right: 0;
  left: auto;
  bottom: -125px;
  z-index: 1;
}

.sigma_about.style-3 .sigma_about-image-1 img,
.sigma_about.style-3 .sigma_about-image-2 img,
.sigma_about.style-4 .sigma_about-image-1 img {
  width: auto;
}

.sigma_about.style-3 .sigma_about-exp {
  color: #000;
  display: block;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.4;
  position: absolute;
  top: 75px;
  right: -36px;
  transform: rotate(270deg);
  background-color: #fff;
  padding: 10px;
}

.sigma_about.style-3 .sigma_video-btn {
  position: absolute;
  left: 10px;
  bottom: -104px;
  width: 80px;
  height: 80px;
  background-color: var(--thm-base);
  color: #fff;
  z-index: 1;
}

.sigma_about.style-3 .sigma_video-btn:hover,
.sigma_about.style-4 .sigma_video-btn:hover {
  background-color: var(--thm-base-hover);
}

.sigma_about.style-3 .sigma_general-list ul,
.sigma_about.style-4 .sigma_about-list {
  column-count: 2;
}

.sigma_about.style-3 .sigma_general-list ul li i {
  width: 30px;
  height: 30px;
  font-size: 14px;
}

/* style 4 */
.sigma_about.style-4 .sigma_about-image-1 img {
  border: 20px solid var(--thm-base);
  border-radius: 0;
}

.sigma_about.style-4 .sigma_video-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -20px;
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_about.style-4 .sigma_about-list {
  margin: 20px 0;
}

.sigma_about.style-4 .sigma_about-list li span {
  color: var(--thm-secondary);
  font-family: var(--thm-font);
  font-weight: 400;
  text-transform: uppercase;
  font-size: 22px;
  display: block;
  margin-bottom: 5px;
}

.sigma_about.style-4 .sigma_about-list li + li {
  margin-top: 20px;
}

/* Style 5 */
.sigma_about.style-8 .sigma_about-image-1,
.sigma_about.style-8 .sigma_about-image-2,
.sigma_about.style-7 .sigma_about-image-1,
.sigma_about.style-7 .sigma_about-image-2,
.sigma_about.style-9 .sigma_about-image-1,
.sigma_about.style-5 .sigma_about-image-1,
.sigma_about.style-5 .sigma_about-image-2,
.sigma_about.style-10 .sigma_about-image-1,
.sigma_about.style-10 .sigma_about-image-2 {
  border-radius: 0;
  border: 0;
  padding: 0;
  background-color: transparent;
}

.sigma_about.style-8 .sigma_about-image-1 img,
.sigma_about.style-8 .sigma_about-image-2 img,
.sigma_about.style-7 .sigma_about-image-1 img,
.sigma_about.style-7 .sigma_about-image-2 img,
.sigma_about.style-9 .sigma_about-image-1 img,
.sigma_about.style-5 .sigma_about-image-1 img,
.sigma_about.style-5 .sigma_about-image-2 img,
.sigma_about.style-10 .sigma_about-image-1 img,
.sigma_about.style-10 .sigma_about-image-2 img {
  border-radius: 0;
  width: auto;
}

.sigma_about.style-5 [class*=sigma_about-image-],
.sigma_about.style-10 [class*=sigma_about-image-] {
  position: absolute;
}

.sigma_about.style-5 .sigma_about-image-1 {
  top: 0;
  left: 0;
  max-width: 400px;
  max-height: 450px;
  z-index: 1;
}

.sigma_about.style-5 .sigma_about-image-2 {
  right: 20px;
  bottom: 0;
  left: auto;
  max-width: 400px;
  max-height: 260px;
  z-index: 2;
}

.sigma_about.style-5 .sigma_about-image-3 {
  right: 20px;
  top: 20px;
  max-width: 160px;
  z-index: 2;
}

.sigma_about.style-5 .sigma_about-image-4 {
  top: 140px;
  right: 95px;
  z-index: 3;
}

.sigma_about.style-5 .sigma_about-image-5 {
  z-index: 4;
  left: -45px;
  bottom: 20px;
}

/* Style 7 */
.sigma_about.style-7 .sigma_about-image-1 img,
.sigma_about.style-9 .sigma_about-image-1 img {
  width: 100%;
}

.sigma_about.style-7 .sigma_about-image-2 {
  position: absolute;
  left: -20px;
  bottom: 0;
  border-right: 20px solid #fff;
  border-top: 20px solid #fff;
  z-index: 1;
}

.sigma_about.style-7 .sigma_media span,
.sigma_about.style-9 .sigma_media span {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  font-family: var(--thm-font);
  background-color: var(--thm-base);
  padding: 20px;
  font-size: 20px;
  line-height: 1;
  color: #fff;
  min-width: 300px;
  margin-top: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.sigma_about.style-7 .sigma_media span b,
.sigma_about.style-9 .sigma_media span b {
  font-size: 78px;
  margin-right: 20px;
  font-weight: 700;
  min-width: 100px;
}

.sigma_about.style-7 .sigma_media-content img {
  position: absolute;
}

/* Style 8 */
.sigma_about.style-8 {
  position: relative;
}

.sigma_about.style-8 .sigma_about-image-1 {
  position: absolute;
  left: 0;
  bottom: 0;
  max-width: 575px;
}

.sigma_about.style-8 .shape {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 100px;
  background-color: var(--thm-base);
  height: 465px;
  width: 465px;
  border-radius: 50%;
  z-index: -1;
}

/* Style 9 */
.sigma_about.style-9 .sigma_about-image-1 {
  position: relative;
}

.sigma_about.style-9 .sigma_about-image-1:before {
  content: "";
  position: absolute;
  bottom: -15px;
  left: -15px;
  border-left: 120px solid var(--thm-base);
  border-top: 120px solid transparent;
  border-right: 100px solid transparent;
  z-index: -1;
}

.sigma_about.style-9 .sigma_media span {
  top: 60px;
  right: -80px;
}

.sigma_about.style-9 .sigma_info.style-15 {
  padding: 0;
}

.sigma_about.style-9 .sigma_info.style-15 .sigma_info-description h5, .sigma_about.style-9 .sigma_info.style-15 .sigma_info-description .h5 {
  font-size: 14px;
}

.sigma_about.style-9 .video-box {
  display: flex;
  align-items: center;
  /* margin-left: 50px; */
}

.sigma_about.style-9 .video-box .sigma_video-btn {
  background-color: var(--thm-secondary);
  color: #fff;
  width: 60px;
  height: 60px;
}

.sigma_about.style-9 .video-box .sigma_video-btn:before,
.sigma_about.style-9 .video-box .sigma_video-btn:after {
  content: none;
}

.sigma_about.style-9 .video-box span {
  color: var(--thm-secondary);
  font-weight: 500;
  line-height: 1.2;
  margin-left: 15px;
}

/* Style 10 */
.sigma_about.style-10 .sigma_about-image-1 {
  top: 0;
  left: 0;
  position: relative;
  max-width: 300px;
}

.sigma_about.style-10 .sigma_about-image-1:before {
  content: "";
  position: absolute;
  right: -10px;
  bottom: -26px;
  background-color: var(--thm-base);
  width: 258px;
  height: 200px;
  z-index: -1;
}

.sigma_about.style-10 .sigma_about-image-2 {
  top: 50%;
  transform: translateY(-50%);
  bottom: auto;
  right: 0;
  left: auto;
  z-index: -2;
}

.sigma_about.style-10 .sigma_about-image-3 {
  left: 50px;
  bottom: 0;
  z-index: -1;
  max-width: 300px;
  max-height: 260px;
  z-index: -3;
}

.sigma_about.style-10 .sigma_media {
  flex-direction: column;
  align-items: flex-start;
  margin: 16px 0 30px;
}

.sigma_about.style-10 .sigma_media span {
  display: inline-block;
  font-size: 36px;
  font-weight: 600;
  color: var(--thm-base);
}

.sigma_about.style-10 .sigma_media-content {
  margin-left: 0;
}

/*-------------- Progress & Media Sections --------------------------- */
.sigma_media {
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.sigma_media-content {
  flex: 1;
  margin-left: 30px;
}

.sigma_media-content h5, .sigma_media-content .h5 {
  font-weight: 400;
  text-transform: uppercase;
}

.sigma_media-content p {
  margin: 0;
}

.sigma_progress {
  position: relative;
}

.sigma_progress .progress {
  background-color: #f2f6fa;
  height: 5px;
  border-radius: 0;
}

.sigma_progress .progress-bar {
  background-color: var(--thm-base);
}

.sigma_progress .sigma_progress-count {
  position: absolute;
  top: -35px;
  font-size: 12px;
  font-family: var(--thm-font);
  left: 0;
  opacity: 0;
  color: #fff;
  background-color: var(--thm-base);
  padding: 3px 4px 2px 4px;
  line-height: 1;
}

.sigma_progress .sigma_progress-count::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0;
  height: 0;
  border-top: 5px solid var(--thm-base);
  border-right: 5px solid transparent;
}

.sigma_progress h6, .sigma_progress .h6 {
  position: absolute;
  bottom: 100%;
  left: 0;
  margin: 0 0 5px;
  font-size: 14px;
  font-weight: 400;
}

.sigma_progress.round {
  display: inline-block;
}

.sigma_progress.round > span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  font-size: 28px;
  line-height: 1;
  color: var(--thm-secondary);
}

.sigma_progress.round.sm > span {
  font-size: 22px;
}

.sigma_progress.round.sm > span span {
  font-size: 12px;
}

.sigma_progress.round > svg {
  width: 120px;
  height: 120px;
  overflow: visible;
}

.sigma_progress.round.sm svg {
  width: 100px;
  height: 100px;
}

.sigma_progress.round .sigma_progress-stroke {
  fill: none;
  stroke: var(--thm-border);
  stroke-width: 10;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  position: relative;
  z-index: -1;
  color: var(--thm-border);
}

.sigma_progress.round .sigma_progress-round {
  fill: none;
  stroke: var(--thm-base);
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  stroke-dashoffset: 700;
  stroke-dasharray: 700;
  stroke-width: 20px;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-linecap: butt;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  transition: stroke-dashoffset 1s;
  color: var(--thm-base);
  stroke-linecap: round;
}

/*-------------- Pricing Sections --------------------------- */
.sigma_pricing {
  padding: 30px;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
  margin-bottom: 30px;
  text-align: center;
  border-radius: 0;
  transition: 0.3s;
}

.sigma_pricing:hover {
  transform: translateY(-3px);
}

.sigma_pricing .sigma_pricing-top span {
  margin-bottom: 10px;
}

.sigma_pricing .sigma_pricing-icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--thm-base);
  background-color: var(--thm-secondary);
  font-size: 32px;
  margin: 0 auto;
}

.sigma_pricing .sigma_pricing-title {
  display: block;
  color: var(--thm-base);
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 500;
}

.sigma_pricing .sigma_pricing-rate {
  display: block;
  color: var(--thm-secondary);
  font-weight: 700;
  font-size: 30px;
  line-height: 1.2;
}

.sigma_pricing .sigma_general-list {
  background-color: transparent;
  padding: 0;
  margin: 20px 0 25px;
}

.sigma_pricing .sigma_general-list ul li span {
  flex: none;
  padding-left: 10px;
}

.sigma_pricing .sigma_general-list ul li {
  justify-content: center;
}

.sigma_pricing .sigma_general-list ul li + li {
  margin-top: 10px;
}

/* Style 2 */
.sigma_pricing.style-2 {
  padding: 0 0 30px;
}

.sigma_pricing.style-2 .sigma_general-list {
  margin: 0;
}

.sigma_pricing.style-2 .sigma_general-list ul {
  padding: 80px 40px 30px;
}

.sigma_pricing.style-2 .sigma_general-list ul li {
  justify-content: space-between;
  padding: 0;
}

.sigma_pricing.style-2 .sigma_general-list ul li span {
  padding: 0;
  font-weight: 400;
}

.sigma_pricing.style-2 .sigma_pricing-thumb {
  position: relative;
}

.sigma_pricing.style-2 .sigma_pricing-thumb img {
  width: 100%;
}

.sigma_pricing.style-2 .sigma_pricing-top {
  position: absolute;
  bottom: -60px;
  left: 40px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 80px);
}

.sigma_pricing.style-2 .sigma_pricing-price {
  background-color: #fff;
  border-radius: 25px;
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
}

.sigma_pricing.style-2 .sigma_pricing-price p {
  margin: 0;
}

.sigma_pricing.style-2 .sigma_pricing-price .sigma_pricing-rate {
  margin: 0;
  line-height: 1;
  display: flex;
  align-items: center;
}

.sigma_pricing.style-2 .sigma_pricing-price .sigma_pricing-rate .sigma_pricing-rate-currency {
  margin: 0 3px 0 0;
  color: var(--thm-base);
  font-size: 18px;
}

.sigma_pricing.style-2 .sigma_pricing-price .sigma_pricing-rate .sigma_pricing-rate-price {
  margin: 0;
  font-size: 38px;
}

.sigma_pricing.style-2 .sigma_pricing-title {
  background-color: var(--thm-base);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 20px;
  border-radius: 35px;
}

/* Style 3 */
.sigma_pricing.style-3,
.sigma_pricing.style-4 {
  box-shadow: none;
  border: 1px solid var(--thm-border);
  border-radius: 10px;
  overflow: hidden;
  border-top: 0;
  position: relative;
}

.sigma_pricing.style-3:hover,
.sigma_pricing.style-4:hover {
  border-color: transparent;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
}

.sigma_pricing.style-3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 7px;
  background-color: var(--thm-base);
}

.sigma_pricing.style-3 .sigma_general-list ul li {
  justify-content: flex-start;
}

/* Style 4 */
.sigma_pricing.style-4 {
  border-top: 1px solid var(--thm-border);
  padding: 0;
}

.sigma_pricing.style-4 .sigma_pricing-top {
  padding: 30px 30px 80px;
  position: relative;
}

.sigma_pricing.style-4 .sigma_pricing-top .top-wave {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

.sigma_pricing.style-4 .sigma_pricing-top .bottom-wave {
  position: absolute;
  left: 50px;
  bottom: 9px;
  width: 100%;
}

.sigma_pricing.style-4 .sigma_pricing-bottom {
  padding: 0 30px 40px;
}

.sigma_pricing.style-4 .sigma_pricing-title {
  background-color: rgba(255, 255, 255, 0.2);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 32px;
  color: #fff;
  border-radius: 30px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 18px;
}

.sigma_pricing.style-4 .sigma_pricing-rate {
  font-size: 38px;
}

.sigma_pricing.style-4 .sigma_pricing-rate,
.sigma_pricing.style-4 .sigma_pricing-top p {
  color: #fff;
}

.sigma_pricing.style-4 .sigma_general-list ul {
  display: inline-block;
}

.sigma_pricing.style-4 .sigma_general-list ul li {
  justify-content: flex-start;
}

/*-------------- Counter Sections --------------------------- */
.sigma_counter {
  margin-bottom: 30px;
  position: relative;
}

.sigma_counter span {
  position: relative;
  display: flex;
  color: var(--thm-base);
  font-size: 40px;
  margin-bottom: 10px;
  line-height: 1;
}

.sigma_counter span.plus {
  font-size: 22px;
  margin: 0;
}

.sigma_counter p {
  color: var(--thm-secondary);
  font-weight: 500;
  line-height: 1.3;
}

.sigma_counter i {
  font-size: 40px;
  color: var(--thm-secondary);
  display: block;
  margin-bottom: 20px;
}

/* Style 2 */
.sigma_counter.style-2,
.sigma_counter.style-3 {
  padding: 30px;
  margin-bottom: 0;
  background-color: var(--thm-base);
  border-left: 2px solid rgba(255, 255, 255, 0.4);
}

/* Style 3 */
.sigma_counter-wrapper {
  background-color: var(--thm-base);
  padding: 40px;
}

.sigma_counter.style-3 {
  border-left: 0;
  border-top: 2px solid rgba(255, 255, 255, 0.4);
  margin-top: 20px;
  padding: 20px 0 0;
  display: flex;
  align-items: center;
}

.sigma_counter.style-3 i {
  margin-bottom: 0;
}

/*-------------- To Top Sections --------------------------- */
.sigma_top {
  position: fixed;
  bottom: 40px;
  right: 30px;
  background-color: #fff;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}

.sigma_top i {
  transition: 0.3s;
}

.sigma_top:hover {
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_top:hover i {
  transform: translateY(-3px);
}

.sigma_top.active {
  bottom: 30px;
  opacity: 1;
  visibility: visible;
}

/* Style 2 */
.sigma_top.style-2 {
  border-radius: 0;
}

/* Style 3 */
.sigma_top.style-3 {
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_top.style-3:hover {
  box-shadow: 0 0 40px 5px rgba(var(--thm-base-rgb), 0.3);
}

/*-------------- Instagram Sections --------------------------- */
.sigma_instagram.style-1 .instagram_gallery a {
  flex: 0 0 33.33%;
  overflow: hidden;
  max-height: 300px;
}

.sigma_instagram.style-5 .instagram_gallery a img,
.sigma_instagram.style-2 .instagram_gallery a img,
.sigma_instagram.style-3 .instagram_gallery a img,
.sigma_instagram.style-1 .instagram_gallery a img {
  transition: 0.3s;
}

.sigma_instagram.style-1 .instagram_gallery a:hover img {
  transform: scale(1.1);
}

.sigma_instagram.style-1 .instagram_gallery {
  position: relative;
}

.sigma_instagram.style-5 a:nth-child(1)::before,
.sigma_instagram.style-5 a:nth-child(2)::before,
.sigma_instagram.style-1 .instagram_gallery::before {
  content: "";
  font-family: "Font Awesome 5 Brands";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-size: 20px;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  background-color: #fff;
  color: var(--thm-secondary);
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
  transition: 0.3s;
  z-index: 2;
  border-radius: 50%;
}

.sigma_instagram-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* Style 2 */
.sigma_instagram.style-4 a,
.sigma_instagram.style-2 a {
  flex: 0 0 16.667%;
}

.sigma_instagram.style-4 .instagram_gallery,
.sigma_instagram.style-2 .instagram_gallery {
  margin: 0 -10px;
}

.sigma_instagram.style-4 a img,
.sigma_instagram.style-2 a img {
  padding: 10px;
}

.sigma_instagram.style-2 .instagram_gallery a:hover img {
  transform: scale(1.1) rotate(5deg);
}

/* Style 3 */
.sigma_instagram.style-3 a {
  flex: 0 0 25%;
}

.sigma_instagram.style-3 .instagram_gallery a:hover img {
  transform: scale(1.05);
}

.sigma_instagram.style-3 + a {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Style 4 */
.sigma_instagram.style-4 .instagram_gallery {
  border: 2px solid var(--thm-border);
}

/* Style 5 */
.sigma_instagram.style-5 a {
  position: relative;
  flex: 0 0 25%;
  overflow: hidden;
}

.sigma_instagram.style-5 a:nth-child(1),
.sigma_instagram.style-5 a:nth-child(2) {
  flex: 0 0 50%;
}

.sigma_instagram.style-5 a:nth-child(1)::before,
.sigma_instagram.style-5 a:nth-child(2)::before {
  opacity: 0;
  visibility: hidden;
  top: 53%;
}

.sigma_instagram.style-5 a:nth-child(1):hover::before,
.sigma_instagram.style-5 a:nth-child(2):hover::before {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

.sigma_instagram.style-5 a:nth-child(n+3):hover img {
  transform: scale(1.1);
}

/*-------------- NEwsletter popup & modals Sections --------------------------- */
.modal-header,
.modal-content {
  border: 0;
  border-radius: 0;
}

.modal-body {
  padding: 40px;
}

.modal-lg {
  max-width: 1000px;
}

.sigma_newsletter-popup-modal.modal-dialog {
  max-width: 600px;
  margin: 0.5rem auto;
}

.sigma_newsletter-popup-modal .modal-header {
  padding: 0;
  height: 300px;
  overflow: hidden;
}

.sigma_newsletter-popup-modal .sigma_close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.sigma_newsletter-popup-modal .modal-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 3rem;
  background-size: cover;
}

.sigma_newsletter-popup-modal .modal-body h3, .sigma_newsletter-popup-modal .modal-body .h3 {
  font-size: 30px;
  margin: 0 0 10px;
}

/* .sigma_newsletter-popup-modal .modal-body p {
} */
.sigma_newsletter-popup-text-wrapper {
  text-align: center;
}

.sigma_newsletter-popup-modal .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.sigma_newsletter-popup-modal .form-control {
  width: 300px;
  margin-bottom: 10px;
}

.sigma_newsletter-popup-modal .mc4wp-form-fields .btn-custom {
  width: 100%;
}

.sigma_newsletter-popup-modal .sigma_newsletter-popup-dismiss {
  display: block;
  cursor: pointer;
  margin-top: 15px;
}

.sigma_newsletter-popup-modal .sigma_newsletter-popup-dismiss:hover {
  text-decoration: underline;
}

/* Style 3 */
.sigma_newsletter-popup-modal.style-3 form {
  display: flex;
  align-items: center;
  margin: 0;
}

.sigma_newsletter-popup-modal.style-3 form .form-group {
  margin: 0 15px 0 0;
}

.sigma_newsletter-popup-modal.style-3 form .form-control {
  margin: 0;
}

/* Style 4 */
.sigma_newsletter-popup-modal.style-4 .sigma_newsletter-popup-dismiss,
.sigma_newsletter-popup-modal.style-4 .sigma_newsletter-popup-text-wrapper p {
  color: #999b9f;
}

/* Style 5 */
.sigma_popup {
  padding: 30px;
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.03);
  position: fixed;
  bottom: 20px;
  left: -600px;
  background-color: #fff;
  max-width: 450px;
  text-align: center;
  transition: 1s cubic-bezier(0, 0, 0.2, 1);
  z-index: 994;
}

.sigma_popup.show {
  left: 20px;
}

.sigma_popup .sigma_close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.sigma_popup .sigma_popup-dismiss {
  display: block;
  cursor: pointer;
  margin-top: 15px;
}

.sigma_popup .sigma_popup-dismiss:hover {
  text-decoration: underline;
}

.sigma_popup .sigma_popup-text {
  font-size: 14px;
}

.sigma_popup .sigma_popup-text h3, .sigma_popup .sigma_popup-text .h3 {
  margin-bottom: 15px;
}

.sigma_popup .sigma_close span {
  width: 14px;
}

/*-------------- Preloader Sections --------------------------- */
.sigma_preloader {
  background-color: var(--thm-base);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.sigma_preloader.hidden {
  opacity: 0;
  visibility: hidden;
}

/* Preloader: Dual */
.preloader-dual-inner div {
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  top: 60px;
  left: 20px;
  background: #fff;
}

.preloader-dual-inner div:nth-child(1) {
  animation: preloader-dual-inner 1s linear infinite;
  animation-delay: -0.5s;
}

.preloader-dual-inner div:nth-child(2) {
  animation: preloader-dual-inner 1s linear infinite;
  animation-delay: 0s;
}

.preloader-dual-inner div:nth-child(3) {
  animation: preloader-dual-inner-o 1s linear infinite;
  animation-delay: -0.5s;
}

.preloader-dual {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}

.preloader-dual-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

/* Preloader: Default */
.sigma_preloader-default .sigma_preloader-inner {
  display: block;
  width: 97px;
}

.sigma_preloader-default .sigma_preloader-inner i {
  width: 19px;
  height: 19px;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
}

.sigma_preloader-default .sigma_preloader-inner i:first-child {
  opacity: 0;
  -webkit-animation: cssload-loading-ani2 0.58s linear infinite;
  animation: cssload-loading-ani2 0.58s linear infinite;
  -webkit-transform: translate(-19px);
  transform: translate(-19px);
}

.sigma_preloader-default .sigma_preloader-inner i:nth-child(2),
.sigma_preloader-default .sigma_preloader-inner i:nth-child(3) {
  -webkit-animation: cssload-loading-ani3 0.58s linear infinite;
  animation: cssload-loading-ani3 0.58s linear infinite;
}

.sigma_preloader-default .sigma_preloader-inner i:last-child {
  -webkit-animation: cssload-loading-ani1 0.58s linear infinite;
  animation: cssload-loading-ani1 0.58s linear infinite;
}

/* Preloader: Eclipse */
.preloader-eclipse-inner div {
  position: absolute;
  animation: preloader-eclipse 1s linear infinite;
  width: 160px;
  height: 160px;
  top: 20px;
  left: 20px;
  border-radius: 50%;
  box-shadow: 0 4px 0 0 #fff;
  transform-origin: 80px 82px;
}

.preloader-eclipse {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}

.preloader-eclipse-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

/* Preloader: Spinner */
.preloader-spinner-inner div {
  left: 94px;
  top: 48px;
  position: absolute;
  animation: preloader-spinner-inner linear 1s infinite;
  background: #fff;
  width: 12px;
  height: 24px;
  border-radius: 6px/12px;
  transform-origin: 6px 52px;
}

.preloader-spinner-inner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666667s;
}

.preloader-spinner-inner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333s;
}

.preloader-spinner-inner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
}

.preloader-spinner-inner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666667s;
}

.preloader-spinner-inner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333s;
}

.preloader-spinner-inner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
}

.preloader-spinner-inner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666667s;
}

.preloader-spinner-inner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333s;
}

.preloader-spinner-inner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
}

.preloader-spinner-inner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.1666666667s;
}

.preloader-spinner-inner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.0833333333s;
}

.preloader-spinner-inner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

.preloader-spinner {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}

.preloader-spinner-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

/* Preloader: Ripple */
.preloader-ripple-inner div {
  position: absolute;
  border-width: 4px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  animation: preloader-ripple-inner 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  border-color: #fff;
}

.preloader-ripple-inner div:nth-child(2) {
  animation-delay: -0.5s;
}

.preloader-ripple {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}

.preloader-ripple-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

/* Preloader: Gear */
.preloader-gear-inner > div {
  transform-origin: 100px 100px;
  animation: preloader-gear-inner 0.2s infinite linear;
}

.preloader-gear-inner > div div {
  position: absolute;
  width: 22px;
  height: 152px;
  background: #fff;
  left: 100px;
  top: 100px;
  transform: translate(-50%, -50%);
}

.preloader-gear-inner > div div:nth-child(1) {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.preloader-gear-inner > div div:nth-child(6) {
  width: 80px;
  height: 80px;
  background: var(--thm-base);
  border-radius: 50%;
}

.preloader-gear-inner > div div:nth-child(3) {
  transform: translate(-50%, -50%) rotate(45deg);
}

.preloader-gear-inner > div div:nth-child(4) {
  transform: translate(-50%, -50%) rotate(90deg);
}

.preloader-gear-inner > div div:nth-child(5) {
  transform: translate(-50%, -50%) rotate(135deg);
}

.preloader-gear {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}

.preloader-gear-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

/* Preloader: Pulse */
.preloader-pulse-inner div {
  position: absolute;
  width: 30px;
  background-color: #fff;
}

.preloader-pulse-inner div:nth-child(1) {
  left: 35px;
  animation: preloader-pulse-inner-1 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation-delay: -0.2s;
}

.preloader-pulse-inner div:nth-child(2) {
  left: 85px;
  animation: preloader-pulse-inner-2 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation-delay: -0.1s;
}

.preloader-pulse-inner div:nth-child(3) {
  left: 135px;
  animation: preloader-pulse-inner-3 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation-delay: undefineds;
}

.preloader-pulse {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}

.preloader-pulse-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

/* Preloader: Squares */
.preloader-squares-inner div {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 13.3333333333px;
  left: 13.3333333333px;
  background: #fff;
  animation: preloader-squares-inner 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  animation-delay: -0.3s;
}

.preloader-squares-inner div:nth-child(2) {
  top: 13.3333333333px;
  left: 106.6666666667px;
  animation-delay: -0.2s;
}

.preloader-squares-inner div:nth-child(3) {
  top: 106.6666666667px;
  left: 13.3333333333px;
  animation-delay: 0s;
}

.preloader-squares-inner div:nth-child(4) {
  top: 106.6666666667px;
  left: 106.6666666667px;
  animation-delay: -0.1s;
}

.preloader-squares {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}

.preloader-squares-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

.sigma_portfolio:hover .sigma_portfolio-content,
.sigma_portfolio-content.style4 {
  background-color: #c2fca1 !important;
}

.sigma_portfolio:hover .sigma_portfolio-content .sigma_portfolio-categories {
  display: none;
}

.sigma_portfolio:hover .sigma_portfolio-content a {
  color: #02373a;
}

.sigma_portfolio .sigma_portfolio-content .sigma_portfolio-categories {
  display: block;
}

.sigma_portfolio .sigma_portfolio-content .sigma_address_info {
  display: none;
}

.sigma_portfolio:hover .sigma_address_info {
  display: block;
  position: static;
  color: #02373a;
  font-weight: bold;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  transform: translateY(150px);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  z-index: 999999;
}

.partners-section {
  padding: 4rem 0;
}

.partners-section .partners-section-title {
  font-size: 40px;
}

.partners-section.slick-dots {
  padding: 0;
  margin-bottom: 0;
}

.partners-section .slick-dots li button {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  height: 8px;
  width: 8px;
}

.partners-section .slick-dots li:not(.slick-active) button {
  background-color: #ccc;
}

.content-subheader {
  font-size: 36px !important;
  font-weight: bold !important;
}

.grid {
  display: grid;
  gap: 0.5rem;
}

.grid.grid-col-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid.grid-col-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

/* Responsive */
@media (max-width: 1199px) and (min-width: 991px) {
  .spacer_banner {
    height: 395px;
  }

  .sigma_portfolio.style-3 .sigma_portfolio-content {
    max-width: 350px;
  }

  .col-lg-5 .sigma_post.sigma_post-list h5, .col-lg-5 .sigma_post.sigma_post-list .h5 {
    font-size: 20px;
  }

  .col-lg-5 .sigma_post.sigma_post-list .sigma_post-thumb {
    margin-right: 20px;
    width: 180px;
  }
}
@media (max-width: 1199px) {
  .widget.widget-follow .sigma_btn {
    padding: 17px 20px;
  }

  .spacer_banner {
    height: 395px;
  }
}
@media (max-width: 991px) {
  .spacer_banner {
    height: 497px;
  }

  /* utility classes */
  h1, .h1 {
    font-size: 40px;
  }

  .mb-lg-30 {
    margin-bottom: 30px;
  }

  .col-lg-reverse {
    flex-direction: column-reverse;
  }

  body {
    font-size: 14px;
  }

  .section-title {
    margin-bottom: 30px;
  }

  .section-title.centered {
    margin: 0 auto 30px;
  }

  /* header */
  .sigma_header .container-fluid, .sigma_header .container-xxl, .sigma_header .container-xl, .sigma_header .container-lg, .sigma_header .container-md, .sigma_header .container-sm {
    padding-left: 15px;
    padding-right: 15px;
  }

  .sigma_header .aside-toggle {
    display: flex;
  }

  .sigma_header .navbar-nav,
.sigma_header-bottom,
.sigma_header .aside-toggle.desktop-toggler {
    display: none;
  }

  .sigma_aside-overlay.aside-trigger,
.sigma_aside:not(.sigma_aside-desktop) {
    display: block;
  }

  .sigma_aside-overlay.aside-trigger-right,
.sigma_aside.sigma_aside-desktop {
    display: none;
  }

  .aside-open .sigma_aside-desktop:not(.sigma_aside) + .sigma_aside-overlay,
.aside-right-open .sigma_aside-desktop + .sigma_aside-overlay {
    opacity: 0;
    visibility: hidden;
  }

  .aside-open .sigma_aside-desktop:not(.sigma_aside) + .sigma_aside-overlay.style-7,
.aside-right-open .sigma_aside-desktop + .sigma_aside-overlay.style-7 {
    opacity: 1;
    visibility: visible;
  }

  .sigma_banner .title,
.sigma_banner.style-8 .title {
    font-size: 42px;
  }

  .navbar {
    padding: 10px 0;
  }

  /* banner style 3 */
  .sigma_banner.style-3 .sigma_banner-image,
.sigma_banner-slider-5.slick-slider {
    width: 100%;
  }

  .sigma_banner.style-3.light-overlay:before,
.sigma_banner.style-4.light-overlay:before {
    opacity: 0.4;
  }

  /* banner style 4 */
  .sigma_banner.style-4 .sigma_clients {
    border-color: #fff;
  }

  .banner-image-slider {
    bottom: 0;
  }

  .sigma_banner-slider-4 .slick-prev,
.sigma_banner-slider-4 .slick-next {
    bottom: 6%;
  }

  /* banner style 6 */
  .sigma_banner-slider-3 {
    position: relative;
    top: 0;
    margin-top: 50px;
  }

  /* About style 3 */
  .sigma_about.style-3 .sigma_about-image-2 {
    position: relative;
    bottom: 0;
    text-align: right;
    margin-top: -125px;
  }

  .sigma_about.style-3 .sigma_video-btn {
    left: 60px;
    bottom: 60px;
  }

  /* About style 3 */
  .sigma_about.style-4 .sigma_about-image-1 img {
    width: 100%;
  }

  /* Portfolio Style 2 */
  .sigma_portfolio.style-2 .sigma_portfolio-thumb.has-max-width {
    max-width: 100%;
    margin: 0;
  }

  /* Portfolio Style 3 */
  .sigma_portfolio-slider .slick-dots {
    position: relative;
    left: 0;
    top: 0;
    transform: none;
    flex-direction: row;
    margin-top: 20px;
  }

  .sigma_portfolio-slider .slick-dots li + li {
    margin-left: 10px;
  }

  /* Team Style 10 */
  .sigma_team.style-10 {
    margin-bottom: 50px;
  }

  /* Accordion Style 1 */
  .sigma_accordion.has-margin {
    margin-top: 25px;
  }

  /* Accordion Style 5 */
  .sigma_accordion.style-5 .accordion {
    margin-top: 30px;
  }

  /* info box style 13 */
  .sigma_info-image.has-width {
    width: auto;
  }

  .info-space {
    padding: 60px 0 0;
  }

  .sigma_info-image.style-16 {
    margin: 0 0 50px;
  }

  /* sigma_form */
  .sigma_form.style-3 {
    padding: 0 30px;
  }

  /* sigma_contact-map*/
  .sigma_contact-map.has-full-height iframe {
    height: 600px;
  }

  /* Cta style 3 */
  .sigma_cta.style-3 {
    border: none;
  }

  /* Cta style 5 */
  .sigma_cta.style-5 .sigma_cta-image {
    height: 400px;
  }

  .sigma_about.style-4 .sigma_video-btn {
    transform: translate(50%, -50%);
    right: 50%;
  }

  /* infobox style 18 */
  .sigma_info.style-18 {
    flex: 0 0 100%;
  }

  .sigma_info.style-18 + .sigma_info.style-18 {
    padding-left: 0;
    border-left: 0;
    border-top: 2px solid rgba(255, 255, 255, 0.3);
    padding-top: 20px;
    margin-top: 20px;
  }

  .pagination {
    margin-bottom: 40px;
  }

  /* Instagram */
  .sigma_instagram-content {
    padding: 40px 0;
  }

  .sigma_instagram.style-4 a,
.sigma_instagram.style-2 a {
    flex: 0 0 33.33%;
  }

  /* contact */
  .sigma_contact-with-bg:after {
    content: none;
  }

  .grid.grid-col-sm-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 991px) {
  .sigma_about.style-2 .sigma_about-image-1 {
    transform: translateX(-120px);
  }

  .spacer_banner {
    height: 497px;
  }
}
@media (max-width: 767px) {
  .spacer_banner {
    height: 250px;
  }

  .section {
    padding: 60px 0;
  }

  .section.section-padding {
    padding: 60px 0 30px;
  }

  /* utility classes */
  h4, .h4 {
    font-size: 22px;
  }

  .section-title .title {
    font-size: 40px;
  }

  .col-md-reverse {
    flex-direction: column-reverse;
  }

  .sigma_responsive-table thead {
    display: none;
  }

  .sigma_responsive-table td.remove,
.sigma_responsive-table td {
    position: relative;
    display: block;
    width: 100%;
    text-align: right;
    padding: 10px;
    border-bottom: 0;
  }

  .sigma_responsive-table tr.total td::before {
    display: none;
  }

  .sigma_responsive-table tr.total td:first-child {
    border-top: 0;
    border-right: 0;
  }

  .sigma_responsive-table tr.total td:last-child {
    border-left: 0;
    border-top: 0;
  }

  .sigma_responsive-table tr.total td:nth-child(2) {
    display: none;
  }

  .sigma_responsive-table tr.total td {
    width: auto;
    text-align: left;
  }

  .sigma_responsive-table tr.total {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .sigma_responsive-table td::before {
    content: attr(data-title) ": ";
    font-weight: 700;
    float: left;
  }

  .sigma_responsive-table td.remove::before {
    display: none;
  }

  .sigma_responsive-table tr {
    border-bottom: 1px solid #f0f2f3;
  }

  .sigma_responsive-table .cart-product-wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    padding: 0;
  }

  .sigma_responsive-table .cart-product-wrapper img {
    margin-right: 0;
    margin-left: 10px;
  }

  /* Subheader */
  .sigma_subheader {
    padding: 100px 0;
  }

  .sigma_subheader .sigma_subheader-inner h1, .sigma_subheader .sigma_subheader-inner .h1 {
    font-size: 48px;
    margin-bottom: 10px;
  }

  .sigma_subheader .breadcrumb li {
    font-size: 14px;
  }

  .sigma_subheader.style-4 .sigma_subheader-shape svg,
.sigma_subheader.style-4 .sigma_subheader-shape {
    height: 85px;
  }

  .sigma_subheader.style-6 .container {
    flex-direction: column;
    align-items: flex-start;
  }

  /* Banners */
  .sigma_banner .banner-slider-inner,
.sigma_banner.style-5 .banner-slider-inner {
    padding: 60px 0 100px;
  }

  .sigma_banner.style-8 .banner-slider-inner {
    padding: 100px 0 200px;
  }

  .sigma_header-absolute + .sigma_banner .banner-slider-inner {
    padding: 200px 0 130px;
  }

  .sigma_banner.style-2 .sigma_banner-slider-2 .slick-dots {
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  .sigma_header-absolute + .sigma_banner.style-5 .banner-slider-inner {
    padding: 200px 0 100px;
  }

  .sigma_banner.style-7 .title {
    margin: 0;
    font-size: 78px;
  }

  .sigma_banner.style-7 .banner-slider-inner {
    padding: 80px 0 90px;
  }

  /* About style 3 */
  .sigma_about.style-3 .sigma_about-exp {
    top: 34px;
    right: -36px;
  }

  /* About style 9 */
  .sigma_about.style-9 .sigma_about-image-1:before,
.sigma_about.style-9 .sigma_media span {
    display: none;
  }

  /* Blog Post */
  .sigma_post h5, .sigma_post .h5 {
    font-size: 22px;
  }

  .sigma_post.post-format-video .sigma_video-btn {
    width: 80px;
    height: 80px;
  }

  .sigma_post.sigma_post-list {
    display: block;
  }

  .sigma_post.sigma_post-list .sigma_post-thumb {
    margin-right: 0;
    margin-bottom: 30px;
  }

  /* Portfolio Style 3 */
  .sigma_portfolio.style-3 {
    display: block;
  }

  .sigma_portfolio.style-3 .sigma_portfolio-content {
    margin-left: 0;
    margin-top: 40px;
    max-width: 100%;
  }

  /* Team Style 3 */
  .sigma_team.style-3 {
    display: block;
  }

  .sigma_team.style-3 .sigma_team-thumb {
    margin-right: 0;
  }

  .sigma_team.style-3 .sigma_team-body {
    padding: 30px;
  }

  /* Testimonial Style 6 */
  .sigma_testimonial.style-6 {
    display: block;
  }

  .sigma_testimonial.style-6 .sigma_testimonial-body {
    margin-left: 0;
    margin-top: 50px;
  }

  /* Testimonial Style 7 */
  .sigma_testimonial.style-7 .sigma_testimonial-body {
    padding: 30px 0;
  }

  /* sigma_form */
  .sigma_form.style-4 {
    margin: 0 20px;
    left: 0;
  }

  /* footer style 2 */
  .sigma_footer .sigma_footer-top {
    flex-direction: column;
  }

  .sigma_footer .sigma_footer-top > div:not(:first-of-type):not(:last-of-type) {
    margin: 25px 0;
  }

  .sigma_portfolio.style-8 .sigma_portfolio-content {
    opacity: 1;
    visibility: visible;
    bottom: 10px;
  }
}
@media (max-width: 576px) {
  .spacer_banner {
    height: 150px;
  }

  /* utility classes */
  h1, .h1 {
    font-size: 30px;
  }

  h2, .h2 {
    font-size: 28px;
  }

  .section-title.flex-title {
    display: block;
  }

  .search-form-wrapper form {
    width: 100%;
  }

  /* Buttons & inputs */
  button,
input[type=reset],
input[type=button],
input[type=submit],
.sigma_btn {
    padding: 12px 18px;
  }

  input[type=number],
input[type=text],
input[type=date],
input[type=month],
input[type=email],
input[type=password],
input[type=tel],
input[type=url],
input[type=search],
select,
textarea,
.form-control {
    height: 50px;
    padding: 14px 20px;
  }

  /* Subheader */
  .sigma_subheader.style-10 .sigma_subheader-inner h1, .sigma_subheader.style-10 .sigma_subheader-inner .h1 {
    font-size: 32px;
  }

  .sigma_subheader.style-10 .sigma_subheader-inner h1::before, .sigma_subheader.style-10 .sigma_subheader-inner .h1::before,
.sigma_subheader.style-10 .sigma_subheader-inner h1::after,
.sigma_subheader.style-10 .sigma_subheader-inner .h1::after {
    height: 10px;
  }

  /* banner style 6 */
  .sigma_banner-slider-3 .slick-dots {
    position: relative;
    flex-direction: row;
    left: 50%;
    transform: translateX(-50%);
  }

  .sigma_banner-slider-3 .slick-dots li + li {
    margin-top: 0;
    margin-left: 10px;
  }

  /* About style 3 */
  .sigma_about.style-3 .sigma_general-list ul,
.sigma_about.style-4 .sigma_about-list {
    column-count: 1;
  }

  /* About style 9 */
  .sigma_about.style-9 .video-box {
    margin-left: 0;
    margin-top: 20px;
  }

  /* Blog Post */
  .sigma_post.post-format-quote blockquote {
    padding: 60px 20px;
  }

  .sigma_post.post-format-video .sigma_video-btn {
    width: 60px;
    height: 60px;
  }

  .sigma_single-pagination a span {
    font-size: 13px;
  }

  .sigma_single-pagination a h6, .sigma_single-pagination a .h6 {
    font-size: 14px;
  }

  /* Isotope Filter */
  .sigma_isotope-filter-items {
    display: block;
  }

  .sigma_isotope-filter-items .sigma_isotope-trigger + .sigma_isotope-trigger {
    margin-left: 0;
    margin-top: 20px;
  }

  /* Team Style 5 */
  .sigma_team.style-5 {
    display: block;
  }

  .sigma_team.style-5 .sigma_team-thumb {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .sigma_team-slider .slick-list {
    overflow: visible;
  }

  /* Tab items style */
  .sigma_tab-item .nav-tabs {
    flex-direction: column;
  }

  .sigma_tab-item .nav-tabs li + li {
    margin-left: 0;
  }

  /* Tab items style 3 */
  .sigma_tab-item.style-3 .nav-tabs .nav-item:first-of-type .nav-link,
.sigma_tab-item.style-3 .nav-tabs .nav-item:nth-child(2) .nav-link,
.sigma_tab-item.style-3 .nav-tabs .nav-item:last-of-type .nav-link {
    border-radius: 6px;
  }

  /* Tab items style 7 & 13 */
  .sigma_tab-item.style-7 .nav-tabs .nav-link,
.sigma_tab-item.style-13 .nav-tabs .nav-link {
    width: 100%;
  }

  /* Tab items style 10 */
  .sigma_tab-item.style-10 .nav-tabs:before {
    transform: translateY(-50%) rotate(90deg);
  }

  .sigma_tab-item.style-10 .nav-tabs .nav-item {
    margin-bottom: 30px;
  }

  /* sigma_form */
  .sigma_form.has-shadow {
    padding: 25px;
  }

  .sigma_form.style-2 .sigma_buttons button {
    display: block;
    width: 100%;
  }

  .sigma_form.style-2 .sigma_buttons button + button {
    margin-left: 0;
    margin-top: 15px;
  }

  /* Cta */
  .sigma_cta-buttons {
    flex-direction: column;
    align-items: flex-start;
  }

  .sigma_cta-buttons a + a,
.sigma_cta-buttons button + button {
    margin-left: 0;
    margin-top: 20px;
  }

  /* Cta style 2 */
  .sigma_cta.style-2 {
    padding: 50px 30px 60px;
  }

  /* Cta style 5 */
  .sigma_cta.style-5 .sigma_cta-content {
    padding: 60px 30px 70px;
  }

  /* Cta style 9 */
  .sigma_cta.style-9 {
    padding: 20px;
  }

  /* infobox style 17  & 18*/
  .sigma_info.style-17 {
    display: block;
  }

  .sigma_info.style-17 .sigma_info-title .sigma_info-icon,
.sigma_info.style-18 .sigma_info-title .sigma_info-icon {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .sigma_info.style-18 {
    flex: 0 0 100%;
    display: block;
  }

  /* Footer style 3 */
  .sigma_footer.style-3 .sigma_footer-widget {
    text-align: center;
  }

  .sigma_footer.style-3 .sigma_footer-links {
    align-items: center;
  }

  /* Pricing */
  .sigma_pricing.style-2 .sigma_general-list ul {
    padding: 80px 20px 20px;
  }

  .sigma_pricing.style-2 .sigma_pricing-top {
    left: 20px;
    width: calc(100% - 40px);
  }

  /* Counter */
  .sigma_counter.style-2 {
    border-left: 0;
    border-top: 2px solid rgba(255, 255, 255, 0.4);
  }

  /* portfolio */
  .sigma_portfolio.style-9 .sigma_portfolio-content {
    width: calc(100% - 20px);
  }

  /* Testimonial */
  .sigma_testimonial.style-11 {
    padding: 30px 20px;
  }

  /* Popup */
  .sigma_newsletter-popup-modal.style-3 form {
    display: block;
  }

  .sigma_newsletter-popup-modal.style-3 form .form-group {
    margin: 0;
  }

  .sigma_newsletter-popup-modal.style-3 form button {
    display: block;
    width: 100%;
    margin-top: 15px;
  }

  .sigma_popup {
    max-width: 100%;
    width: calc(100% - 40px);
  }

  /* Post details */
  .sigma_post-details-meta {
    display: block;
  }

  .sigma_post-details-meta span {
    display: block;
    margin: 0 0 10px;
  }

  .sigma_post-details-meta-item + .sigma_post-details-meta-item {
    margin-top: 20px;
  }

  .sigma_author-about {
    display: block;
    text-align: center;
  }

  .sigma_author-about img {
    margin: 0 auto;
  }

  .sigma_author-about-content {
    margin: 20px 0 0;
  }

  .comments-list .comment-item img {
    width: 50px;
  }

  .comments-list .comment-item .btn-link {
    position: relative;
    top: auto;
    margin-top: 15px;
  }

  .sigma_post-details.style-2 .sigma_post-details-inner {
    border: 0;
    padding: 0;
  }

  /* Instagram */
  .sigma_instagram.style-3 a {
    flex: 0 0 33.33%;
  }

  /* Carousel Section */
  .partners-section {
    padding: 2rem 0;
  }

  .partners-section .partners-section-title {
    font-size: 30px;
  }

  .content-subheader {
    font-size: 24px !important;
    font-weight: bold !important;
  }
}
.flaticon-trash {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 12px;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px, inset -2px 0 0, inset 2px 0 0;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  margin-top: 4px;
}

.flaticon-trash::after,
.flaticon-trash::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
}

.flaticon-trash::after {
  background: currentColor;
  border-radius: 3px;
  width: 16px;
  height: 2px;
  top: -4px;
  left: -5px;
}

.flaticon-trash::before {
  width: 10px;
  height: 4px;
  border: 2px solid;
  border-bottom: transparent;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  top: -7px;
  left: -2px;
}

a {
  text-decoration: none !important;
}

.mat-dialog-container {
  overflow: visible !important;
  width: 700px !important;
}

.link {
  color: #0d6efd;
  cursor: pointer;
}

.link:hover {
  color: #0a58ca;
}

.sigma_post-category:hover {
  color: white;
}

/*!
 * Bootstrap v5.0.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-axp-theme: #de1e07;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0d6efd;
  text-decoration: underline;
}
a:hover {
  color: #0a58ca;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6666666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #cfe2ff;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bacbe6;
}

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce;
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1;
}

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  padding: 1rem 0.75rem;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0a58ca;
  border-color: #0a53be;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-dark:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1e21;
  border-color: #191c1f;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-axp-theme {
  color: #fff;
  background-color: #de1e07;
  border-color: #de1e07;
}
.btn-axp-theme:hover {
  color: #fff;
  background-color: #bd1a06;
  border-color: #b21806;
}
.btn-check:focus + .btn-axp-theme, .btn-axp-theme:focus {
  color: #fff;
  background-color: #bd1a06;
  border-color: #b21806;
  box-shadow: 0 0 0 0.25rem rgba(227, 64, 44, 0.5);
}
.btn-check:checked + .btn-axp-theme, .btn-check:active + .btn-axp-theme, .btn-axp-theme:active, .btn-axp-theme.active, .show > .btn-axp-theme.dropdown-toggle {
  color: #fff;
  background-color: #b21806;
  border-color: #a71705;
}
.btn-check:checked + .btn-axp-theme:focus, .btn-check:active + .btn-axp-theme:focus, .btn-axp-theme:active:focus, .btn-axp-theme.active:focus, .show > .btn-axp-theme.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(227, 64, 44, 0.5);
}
.btn-axp-theme:disabled, .btn-axp-theme.disabled {
  color: #fff;
  background-color: #de1e07;
  border-color: #de1e07;
}

.btn-outline-primary {
  color: #0d6efd;
  border-color: #0d6efd;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #0d6efd;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-success {
  color: #198754;
  border-color: #198754;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #198754;
  background-color: transparent;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: #212529;
  border-color: #212529;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #212529;
  background-color: transparent;
}

.btn-outline-axp-theme {
  color: #de1e07;
  border-color: #de1e07;
}
.btn-outline-axp-theme:hover {
  color: #fff;
  background-color: #de1e07;
  border-color: #de1e07;
}
.btn-check:focus + .btn-outline-axp-theme, .btn-outline-axp-theme:focus {
  box-shadow: 0 0 0 0.25rem rgba(222, 30, 7, 0.5);
}
.btn-check:checked + .btn-outline-axp-theme, .btn-check:active + .btn-outline-axp-theme, .btn-outline-axp-theme:active, .btn-outline-axp-theme.active, .btn-outline-axp-theme.dropdown-toggle.show {
  color: #fff;
  background-color: #de1e07;
  border-color: #de1e07;
}
.btn-check:checked + .btn-outline-axp-theme:focus, .btn-check:active + .btn-outline-axp-theme:focus, .btn-outline-axp-theme:active:focus, .btn-outline-axp-theme.active:focus, .btn-outline-axp-theme.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(222, 30, 7, 0.5);
}
.btn-outline-axp-theme:disabled, .btn-outline-axp-theme.disabled {
  color: #de1e07;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #0d6efd;
  text-decoration: underline;
}
.btn-link:hover {
  color: #0a58ca;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto /* rtl:ignore */;
  left: 0 /* rtl:ignore */;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0 /* rtl:ignore */;
  left: auto /* rtl:ignore */;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #0d6efd;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #0d6efd;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #0d6efd;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #0a58ca;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d6efd;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #0a58ca;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: #0a58ca;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}
.alert-primary .alert-link {
  color: #06357a;
}

.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}
.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}
.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}
.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  color: #141619;
  background-color: #d3d3d4;
  border-color: #bcbebf;
}
.alert-dark .alert-link {
  color: #101214;
}

.alert-axp-theme {
  color: #851204;
  background-color: #f8d2cd;
  border-color: #f5bcb5;
}
.alert-axp-theme .alert-link {
  color: #6a0e03;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #084298;
  background-color: #bacbe6;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.list-group-item-axp-theme {
  color: #851204;
  background-color: #f8d2cd;
}
.list-group-item-axp-theme.list-group-item-action:hover, .list-group-item-axp-theme.list-group-item-action:focus {
  color: #851204;
  background-color: #dfbdb9;
}
.list-group-item-axp-theme.list-group-item-action.active {
  color: #fff;
  background-color: #851204;
  border-color: #851204;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.toast:not(.showing):not(.show) {
  opacity: 0;
}
.toast.hide {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid #d8d8d8;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #0d6efd;
}
.link-primary:hover, .link-primary:focus {
  color: #0a58ca;
}

.link-secondary {
  color: #6c757d;
}
.link-secondary:hover, .link-secondary:focus {
  color: #565e64;
}

.link-success {
  color: #198754;
}
.link-success:hover, .link-success:focus {
  color: #146c43;
}

.link-info {
  color: #0dcaf0;
}
.link-info:hover, .link-info:focus {
  color: #3dd5f3;
}

.link-warning {
  color: #ffc107;
}
.link-warning:hover, .link-warning:focus {
  color: #ffcd39;
}

.link-danger {
  color: #dc3545;
}
.link-danger:hover, .link-danger:focus {
  color: #b02a37;
}

.link-light {
  color: #f8f9fa;
}
.link-light:hover, .link-light:focus {
  color: #f9fafb;
}

.link-dark {
  color: #212529;
}
.link-dark:hover, .link-dark:focus {
  color: #1a1e21;
}

.link-axp-theme {
  color: #de1e07;
}
.link-axp-theme:hover, .link-axp-theme:focus {
  color: #b21806;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0d6efd !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #198754 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-axp-theme {
  border-color: #de1e07 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  color: #0d6efd !important;
}

.text-secondary {
  color: #6c757d !important;
}

.text-success {
  color: #198754 !important;
}

.text-info {
  color: #0dcaf0 !important;
}

.text-warning {
  color: #ffc107 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-light {
  color: #f8f9fa !important;
}

.text-dark {
  color: #212529 !important;
}

.text-axp-theme {
  color: #de1e07 !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.bg-primary {
  background-color: #0d6efd !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

.bg-success {
  background-color: #198754 !important;
}

.bg-info {
  background-color: #0dcaf0 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.bg-dark {
  background-color: #212529 !important;
}

.bg-axp-theme {
  background-color: #de1e07 !important;
}

.bg-body {
  background-color: #fff !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
.title-banner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(assets/img/new-tarlac-warehouse.jpg);
}

.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: 0.8em 1em 0;
  color: inherit;
  font-size: 1.9em !important;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}

.swal2-html-container {
  z-index: 1;
  justify-content: center;
  margin-top: 0 !important;
  margin-left: 1em !important;
  margin-left: 1em !important;
  padding: 0;
  overflow: auto;
  color: inherit;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word;
}

.sigma_address_info a {
  color: #0e57dd !important;
  text-decoration: underline !important;
}

.navtab-box {
  border-bottom: 1px solid #e4e6ef;
  border-left: 1px solid #e4e6ef;
  border-right: 1px solid #e4e6ef;
  padding: 1rem;
}

.nav.theme-axp {
  border: 1px solid var(--thm-base);
  border-radius: 0.25rem;
  padding: 0.5rem;
}
.nav.theme-axp .nav-link {
  color: #000;
}
.nav.theme-axp .nav-link:hover {
  color: var(--thm-base);
}
.nav.theme-axp .nav-link.active {
  background-color: var(--thm-base);
  color: #fff;
}

.sigma_footer-links {
  padding: 0 !important;
}

@media (max-width: 991.98px) {
  .sigma_header .sigma_logo-wrapper .sigma_logo {
    max-width: 250px !important;
  }

  .sigma_footer-links {
    flex-direction: column !important;
    padding: 0 1rem !important;
  }
  .sigma_footer-links > li {
    text-align: left !important;
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
  }
  .sigma_footer-links > li::before {
    display: none;
  }
  .sigma_footer-links > li:not(:last-child)::after {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    content: "";
    display: inline-block;
    margin: 0.25rem 0;
    width: 100%;
  }
  .sigma_footer-links > li > a {
    text-align: left;
    width: 100%;
  }
}