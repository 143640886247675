	/*
  	Flaticon icon font: Flaticon
  	Creation date: 21/02/2021 17:04
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
font-style: normal;
}

.flaticon-truck:before { content: "\f100"; }
.flaticon-logistics:before { content: "\f101"; }
.flaticon-order:before { content: "\f102"; }
.flaticon-transportation:before { content: "\f103"; }
.flaticon-warehouse:before { content: "\f104"; }
.flaticon-boxes:before { content: "\f105"; }
.flaticon-location:before { content: "\f106"; }
.flaticon-logistics-1:before { content: "\f107"; }
.flaticon-logistics-2:before { content: "\f108"; }
.flaticon-tracking:before { content: "\f109"; }
.flaticon-delivery-time:before { content: "\f10a"; }
.flaticon-logistics-3:before { content: "\f10b"; }
.flaticon-export:before { content: "\f10c"; }
.flaticon-logistics-4:before { content: "\f10d"; }
.flaticon-shipped:before { content: "\f10e"; }
.flaticon-shipping:before { content: "\f10f"; }
.flaticon-box:before { content: "\f110"; }
.flaticon-delivery-box:before { content: "\f111"; }
.flaticon-protected:before { content: "\f112"; }
.flaticon-cargo:before { content: "\f113"; }
.flaticon-box-1:before { content: "\f114"; }
.flaticon-airplane:before { content: "\f115"; }
.flaticon-airplane-1:before { content: "\f116"; }
.flaticon-airplane-2:before { content: "\f117"; }
.flaticon-air-freight:before { content: "\f118"; }
.flaticon-cloud:before { content: "\f119"; }
.flaticon-mail:before { content: "\f11a"; }
.flaticon-location-1:before { content: "\f11b"; }
.flaticon-address:before { content: "\f11c"; }
.flaticon-map-pointer:before { content: "\f11d"; }
.flaticon-phone-call:before { content: "\f11e"; }
.flaticon-phone:before { content: "\f11f"; }
.flaticon-call:before { content: "\f120"; }
.flaticon-heart:before { content: "\f121"; }
.flaticon-like:before { content: "\f122"; }
.flaticon-heart-1:before { content: "\f123"; }
.flaticon-shopping-cart:before { content: "\f124"; }
.flaticon-shopping-cart-1:before { content: "\f125"; }
.flaticon-shopping-cart-2:before { content: "\f126"; }
.flaticon-mine:before { content: "\f127"; }
.flaticon-delivery-truck:before { content: "\f128"; }
